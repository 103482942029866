import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CentrosService {
  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
  constructor(
    private httpClient: HttpClient, private auth: AuthService
  ) { }

  searchCompanyByUser(id_sociedad:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarEmpresaUsuario.php`, {
      id_sociedad: id_sociedad,
    },  options);
  }

    //SEARCH CENTERS BY USER
    searchCenterByUser(id_sociedad:string) {
      const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
      let options = { headers: headers };
  
      return this.httpClient.post(`${this.PHP_API_SERVER}/api/Centros/buscarCentroUsuario.php`, {
        id_sociedad: id_sociedad,
      },  options);
    }

    countComplaintByIdCenter(id_sociedad:string,id_centro:string) {
      const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
      let options = { headers: headers };
  
      return this.httpClient.post(`${this.PHP_API_SERVER}/api/Centros/contarDenunciasPorCentros.php`, {
        id_sociedad: id_sociedad,
        id_centro: id_centro
      }, options);
    }

      /**
   * MÉTODO ELIMINAR CENTRO POR USUARIO
   * @param id_usuario
   * @param id_sociedad
   * @param id_empresa
   * @returns
   */
  deleteCenterByUser(id_sociedad:string, id_centro:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/Centros/eliminarCentro.php`, {
      id_sociedad: id_sociedad,
      id_centro: id_centro,
    },  options);
  }

    //UPDATE CENTER BY USER
    updateCenterByUser(id_sociedad:string, id_centro:string, n_centro:string, id_empresa: string, comentarios:string){
      const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
      let options = { headers: headers };
  
      return this.httpClient.post(`${this.PHP_API_SERVER}/api/Centros/modificarCentro.php`, {
        id_sociedad: id_sociedad,
        id_centro: id_centro,
        n_centro: n_centro,
        id_empresa: id_empresa,
        comentarios: comentarios
      },  options);
    }

      /**
   * MÉTODO PARA AGREGAR CENTRO
   * @param id_sociedad
   * @param id_centro
   * @param n_centro
   * @param comentario
   * @param id_empresa
   * @param id_usuario
   * @param comentarios
   * @returns
   */
  addCenter(id_sociedad:string, id_centro:string, n_centro:string, id_empresa:string, comentarios:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/Centros/crearCentro.php`, {
      id_sociedad: id_sociedad,
      id_centro: id_centro,
      n_centro: n_centro,
      id_empresa: id_empresa,
      comentarios: comentarios
    },  options);
  }
}

