<button style="background-image: url(/../../assets/img/botones/Boton_Subir\ Archivo.png);"
  class="btn btn-finish btn-wd btn-cabecera" matTooltip="{{'texto_99' | translate }}" type="button" mdbBtn
  rounded="true" data-toggle="modal" data-target="#basicExample" (click)="frame.show()" mdbWavesEffect
  [attr.aria-label]="'texto_104' | translate">
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="max-width: 100% !important;">
    <!--Content-->
    <div class="modal-content">

      <div class="modal-body mx-3" style="padding-left: 1rem; padding-right: 1rem;">
        <div class="card-header card-header-info">
          <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_68' | translate }}</h5>
        </div>
        <button type="button" style="z-index:100;float: right;margin-top: -2.8rem;" class="btn btn-dark"
          data-dismiss="modal" (click)="frame.hide()" aria-label="Cerrar modal">X</button>

        <div class="mb-3">
          <label class="control-label padding" for="addDocument">{{ 'texto_457' | translate }}</label>
          <div style="margin-top: 2px;">
            <input type="text" id="addDocument" class="form-control" [formControl]="contactFormModalMessage"
              (keyup)="onKey($event)" [attr.aria-invalid]="contactFormModalMessage.invalid ? 'true' : null"
              aria-describedby="addDocumentHelp">
          </div>
          <small id="addDocumentHelp" class="form-text text-muted">
            {{ 'texto_70' | translate }}
          </small>
        </div>

        <div class="mb-3" style="margin-top: 2rem;">
          <form [formGroup]="myForm" style="width:100%; display:contents; color:white; margin-top: 27px;">
            <label for="fileInput" class="control-label padding">{{ 'texto_719' | translate }}</label>
            <div style="margin-top: 0.2rem;" class="uploadfilecontainer" tabindex="0" role="button"
            [attr.aria-label]="'texto_719' | translate" (click)="fileInput.click()" (keydown.enter)="fileInput.click()"
              (keydown.space)="fileInput.click()" appDragDrop (onFileDropped)="dragFile($event)">
              <input
                accept=".eml,.msg,.zip,.pptx,.pptx,.pptm,.potx,.potm,.ppam,.ppsx,.ppsm,.xlsx,.xlsm,.xltx,.xltm,.xlam,image/gif,image/jpeg,image/jpg,image/png,video/*,audio/*,.pdf,.txt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                id="fileInput" hidden type="file" #fileInput (change)="uploadFile($event)">
            </div>
            <div class="files-list" *ngFor="let file of files; let i = index">
              <p>{{ file }}</p>
              <button class="delete-file" (click)="deleteAttachment(i)" aria-label="Eliminar archivo">
                <img src="../../assets/img/borrar-fichero.png" alt="{{ 'Eliminar archivo' | translate }}">
              </button>
            </div>
          </form>
        </div>
      </div>

      <div class="text-center mt-3">
        <button (click)="submit()" [disabled]="isSubmitDisabled()" mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{
          'texto_60' | translate }}</button>
      </div>
    </div>
  </div>
</div>
