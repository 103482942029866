
  <!-- End Navbar -->
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header card-header-primary" style="height: 3.75rem">
                  <h4 class="card-title ">{{sociedad}}</h4>
¡                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <th>ID. {{ 'texto_123' | translate }}</th>
                            <th>N. {{ 'texto_123' | translate }}</th>
                            <th>{{ 'texto_566' | translate }}</th>
                            <th>{{ 'texto_95' | translate }}</th>
                            <th></th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of sociedades">
                            <td>{{ item.id_sociedad }}</td>
                            <td>{{ item.n_sociedad }}</td>
                            <td>{{ item.web }}</td>
                            <td>{{ item.comentario }}</td>
                            <td class="td-actions text-right" style="width:90px;">
                              <button (click)="showSociedad(item)" type="button" rel="tooltip" title="Edit Company" class="" style="background: none;color:black;border:none;">
                                <i class="material-icons">edit</i>
                              </button>

                              <button (click)="deleteSociedad(item.id_sociedad)" type="button" rel="tooltip" title="Remove" class="" style="background: none;color:red;border:none;">
                                <i class="material-icons">clear</i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br><br>
                      <div [style.display]="IsHiddenForm ? 'block' : 'none'"  style="width: 100% !important;margin: 0 auto;" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                        <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document">
                          <!--Content-->
                          
                          <div class="modal-content" style="width: 100%  !important;">

                              <div class="modal-body mx-3" style="width: 100% !important;">
                                  <div class="card-header card-header-info">
                                    <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_568 ' | translate }}</h5>
                                </div>
                                <button type="button" style="z-index:100;float: right;margin-top: -2.8rem;" class="btn btn-dark" data-dismiss="modal" (click) = "hideModal()"aria-label="Cerrar modal">X</button> 

                                  <div class="md-form mb-3">
                                    <input type="text"  class="form-control" [formControl]="signupFormModalId"
                                           mdbInput mdbValidate value={{id_sociedad}} readonly>
                                    <label for="orangeForm-email">ID. {{ 'texto_123' | translate }}</label>
                                    <mdb-error
                                      *ngIf="signupFormModalId.invalid && (signupFormModalId.dirty || signupFormModalId.touched)">{{ 'texto_61' | translate }}
                                    </mdb-error>
                                    <mdb-success
                                      *ngIf="signupFormModalId.valid && (signupFormModalId.dirty || signupFormModalId.touched)">{{ 'texto_62' | translate }}
                                    </mdb-success>
                                  </div>
                          
                                  <div class="md-form mb-3">
                                    <input type="text"  class="form-control" [formControl]="signupFormModalName"
                                           mdbInput maxlength="255" mdbValidate value={{n_sociedad}}>
                                    <label for="orangeForm-name">{{ 'texto_567' | translate }}</label>
                                    <mdb-error *ngIf="signupFormModalName.invalid && (signupFormModalName.dirty || signupFormModalName.touched)">
                                      {{ 'texto_61' | translate }}
                                    </mdb-error>
                                    <mdb-success *ngIf="signupFormModalName.valid && (signupFormModalName.dirty || signupFormModalName.touched)">
                                      {{ 'texto_62' | translate }}
                                    </mdb-success>
                                  </div>
                                  <div class="md-form mb-3">
                                    <input type="text"  class="form-control" [formControl]="signupFormModalWeb"
                                           mdbInput maxlength="255" mdbValidate value={{web}}>
                                    <label for="orangeForm-name">{{ 'texto_566' | translate }}</label>
                                    <mdb-error *ngIf="signupFormModalWeb.invalid && (signupFormModalWeb.dirty || signupFormModalWeb.touched)">
                                      {{ 'texto_61' | translate }}
                                    </mdb-error>
                                    <mdb-success *ngIf="signupFormModalWeb.valid && (signupFormModalWeb.dirty || signupFormModalWeb.touched)">
                                      {{ 'texto_62' | translate }}
                                    </mdb-success>
                                  </div>                      
                                  <div class="md-form">
                                      <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
                                      <textarea maxlength="4000" type="text" id="form107Modal" class="md-textarea form-control" rows="5" mdbInput [formControl]="contactFormModalMessage" value={{comentario}}></textarea>
                                      <label for="form107Modal">{{ 'texto_46' | translate }}</label>
                                    </div>
                          
                                </div>
                      
                              <div class="text-center mt-3">
                                <button (click)="updateSociedad(id_sociedad)"  mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_59' | translate }}
                                </button>
                              </div>
                      
                          </div>
                          
                       </div>
                       
                    
                  </div>
                  <app-add-sociedad></app-add-sociedad>

                </div>
              </div>
            </div>
      </div>
    </div>
  </div>
