<!-- Saltar al contenido principal -->
<p>
  <a href="#mainContent" id="skip-link" class='admin-bar skip-link' href="javascript:void(0);"
    (click)="navigateToButton()">{{ 'texto_743'
    |translate }}</a>
</p>
<!-- End Saltar al contenido principal -->

<!-- <app-spinner></app-spinner> -->

<div class="container">
  <header>
    <!-- Barra de navegación -->
    <nav>
      <!-- Contenido principal del encabezado -->
      <div class="logo-container" style="margin-top: 20px;">
        <div class="row justify-content-between">
          <!-- Logo con enlace -->
          <div class="col-10">
            <a [routerLink]="'/'" class="logo" aria-label="Logo" tabindex="0">
              <img src="../../assets/img/logos/logo.png" width="auto" height="40" alt="Canal denuncias"
                style="height: 70px; margin-top: -30px;">
            </a>
          </div>
          <!-- Enlace de cierre de sesión -->
          <div class="col-2">
            <a (click)="logout()" [routerLink]="'/'" style="color: #7B4009; font-size: 1.5rem;"
              aria-label="Cerrar sesión" tabindex="0">
              <i class="fas fa-power-off"></i> {{ 'texto_75' | translate }}
            </a>
          </div>
        </div>
      </div>
    </nav>
  </header>

  <section id="mainContent" role="main" tabindex="-1">
    <div class="col-md-12 container-fluid" style="margin-top: 20px;">
      <div class="panel">
        <div class="panel-body">
          <div class="col-md-4 container-fluid" id="nombreexp" div *ngIf="denuncia && denuncia.length > 0">
            <span class="resalta2">{{ 'texto_76' | translate }}: </span><span class="resalta2">
              {{denuncia[0].id_denuncia}}</span>
          </div>
          <div class="col-md-7 container-fluid">
            <app-add-witness [denuncia]="denuncia" [hidden]="!show"></app-add-witness>
            <app-add-document [denuncia]="denuncia" [hidden]="!show"></app-add-document>
          </div>
        </div>
      </div>
    </div>

    <div id="txtfase" class="col-md-12" style="width: 96%;margin-left: 16px;margin-top: 16px;height: 37px;">
      <div id="txtfase" class="col-md-12">
        <span class="resalta2">{{ 'texto_77' | translate }}: </span><span id="txtfase"
          *ngIf="denuncia && denuncia.length > 0" class="resalta2">{{denuncia[0]?.n_fase | translate }}</span>
      </div>
    </div>

    <!-- <div class="col-md-12 progress" style="width: 96%;padding: 0px;height: 37px;">
      <div id="progressbar" class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
      [ngStyle]="{ 'width.%': progressBar, 'background-color': backgroundColor }"
      aria-valuenow="{{ progressBar }}" aria-valuemin="0" aria-valuemax="100">
    </div>git
  </div> -->
    <div class="col-md-12 progress" style="width: 96%; padding: 0px; height: 37px;">
      <div id="progressbar" class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
        [ngStyle]="{ 'width.%': progressBar, 'background-color': backgroundColor }" [attr.aria-valuenow]="progressBar"
        aria-valuemin="0" aria-valuemax="100">
      </div>
    </div>
  </section>



  <!-- <div class="col-md-12 progress" style="width: 96%;padding: 0px;height: 37px;">
    <div id="progressbar" class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
        style="width: {{progressBar}}%;background-color:{{backgroundColor}}" aria-valuenow="{{progressBar}}"
        aria-valuemin="0" aria-valuemax="100"></div>
</div> -->

  <section>
    <div class="container row" id="content" style=" margin-top: 20px; width: 100%;">
      <article id="main" role="main" class="col-md-12">
        <div class="col-md-6">
          <div *ngIf="denuncia && denuncia.length > 0" class="panel panel-primary"
            style="min-height:250px;border-color: #3d065a;">
            <div class="panel-heading" style="background-color:#3d065a">
              <h2 class="panel-title">{{ 'texto_78' | translate }} </h2>
            </div>
            <div class="panel-body"><span class="resalta">{{ 'texto_79' | translate }}:</span><span>
                {{denuncia[0].descripcion_relacion}}</span></div>
            <div [hidden]="denuncia[0].denunciante_nombre ==''" class="panel-body"><span class="resalta">{{
                'texto_80' | translate }}:</span><span> {{denuncia[0].denunciante_nombre}}</span></div>
            <div [hidden]="denuncia[0].denunciante_apellidos ==''" class="panel-body"><span class="resalta">{{
                'texto_81' | translate }}:</span><span> {{denuncia[0].denunciante_apellidos}}</span></div>
            <div [hidden]="denuncia[0].denunciante_correo ==''" class="panel-body"><span class="resalta">{{
                'texto_82' | translate }}:</span><span> {{denuncia[0].denunciante_correo}}</span></div>
            <!-- <div [hidden]="denuncia[0].denunciante_dni ==''" class="panel-body"><span class="resalta">{{
                'texto_83' | translate }}:</span><span> {{denuncia[0].denunciante_dni}}</span></div> -->
            <div [hidden]="denuncia[0].denunciante_telefono ==''" class="panel-body"><span class="resalta">{{
                'texto_66' | translate }}:</span><span> {{denuncia[0].denunciante_telefono}}</span></div>
          </div>
        </div>
        <div class="col-md-6">
          <div *ngIf="denuncia && denuncia.length > 0" class="panel panel-success" style="border-color:#b51a62">
            <div class="panel-heading" style="background-color:#b51a62;color:white;">
              <h2 class="panel-title">{{ 'texto_109' | translate }}</h2>
            </div>
            <div class="panel-body"><span class="resalta">{{ 'texto_67' | translate }}:</span><span>
                {{denuncia[0].nombre_empresa}}</span></div>
            <!-- <div class="panel-body"><span class="resalta">{{ 'texto_67' | translate }}:</span><span> {{denuncia[0].nombre_centro}}</span></div> -->
            <div *ngIf="showCenter" class="panel-body"><span class="resalta">{{ 'texto_675' | translate
                }}:</span><span> {{denuncia[0].nombre_centro}}</span></div>
            <div class="panel-body"><span class="resalta">{{ 'texto_71' | translate }}:</span><span>
                {{denuncia[0].descripcion_tipo_denuncia}}</span></div>
            <div class="panel-body"><span class="resalta">{{ 'texto_84' | translate }}:</span><span>
                {{denuncia[0].fecha_incidente | date: 'dd/MM/yyyy'}}</span></div>
            <div class="panel-body"><span class="resalta">{{ 'texto_85' | translate }}:</span><span>
                {{denuncia[0].fec_inicio | date: 'dd/MM/yyyy'}}</span></div>
            <div class="panel-body"><span class="resalta">{{ 'texto_86' | translate }}:</span><span>
                {{denuncia[0].fec_fin | date: 'dd/MM/yyyy'}}</span></div>

          </div>
        </div>
        <div class="col-md-12">
          <div *ngIf="denuncia && denuncia.length > 0" class="panel panel-success" style="border-color:#70d4b4">
            <div class="panel-heading" style="background-color:#70d4b4;color:white;">
              <h2 class="panel-title">{{ 'texto_87' | translate }}</h2>
            </div>
            <!--descripcion-->
            <div class="panel-body"><span class="resalta">{{ 'texto_63' | translate }}:</span>
              <br>
              <p style="word-wrap: break-word" [innerHTML]=denuncia[0].denuncia_desc> </p>
              <br>
            </div>
            <div class="panel-body">
              <span class="resalta">{{ 'texto_88' | translate }}:</span>
              <p style="word-wrap: break-word"> {{denuncia[0].comentario}}</p>
            </div>
          </div>
        </div>
        <div class="col-md-12">

          <div class="panel panel-warning" style="border-color: #5f64c0;">
            <div class="panel-heading" style="background-color: #5f64c0; color: white;">
              <h2 class="panel-title">{{ 'texto_89' | translate }}</h2>
            </div>
            <div [style.display]="bExisteDocumento ? 'block' : 'none'"
              style="border: 1px solid #fcf8e3; overflow-x: auto;">
              <table class="table table-striped">
                <!-- Table head -->
                <thead style="font-size: 11px; font-weight: bold;">
                  <tr>
                    <th>#</th>
                    <th>{{ 'texto_90' | translate }}</th>
                    <th>{{ 'texto_46' | translate }}</th>
                    <th>{{ 'texto_91' | translate }}</th>
                    <th>{{ 'texto_92' | translate }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of documento; let i = index" class="table-warning" style="font-size: 0.93rem;">
                    <th scope="row">{{ i + 1 }}</th>
                    <td>{{ item.n_archivo }}</td>
                    <td>
                      <span
                        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: block; max-width: 300px;">
                        <div [innerHtml]="item.comentario | safe: 'html'"></div>
                      </span>
                    </td>
                    <td>{{ item.id_usuario }}</td>
                    <td>{{ item.fec_ult_actualizac | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
                    <td>
                      <button (click)="verArchivo(item.id_archivo, item.n_archivo, item.tipo_archivo)" type="button"
                        rel="tooltip" title="Ver Archivo" class=""
                        style="background: none; color: black; border: none;">
                        <i class="material-icons">search</i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="panel-body" [style.display]="!bExisteDocumento ? 'block' : 'none'">
              <div class="col-md-12">
                <span class="resalta">{{ 'texto_110' | translate }}.</span>
              </div>
            </div>
          </div>




        </div>

        <div class="col-md-12">
          <div class="panel panel-info" style="border-color:#f8aa27">
            <div class="panel-heading" style="background-color:#f8aa27;color:white;">
              <h2 class="panel-title">{{ 'texto_93' | translate }}</h2>
            </div>
            <div class="table-responsive" [style.display]="bExisteTestigo ? 'block' : 'none'"
              style="border: 1px solid #d9edf7;">
              <table class="table ">
                <!--Table head-->
                <thead style="font-size:11px; font-weight:bold;">
                  <tr>
                    <th>#</th>
                    <th>{{ 'texto_35' | translate }}</th>
                    <th>{{ 'texto_82' | translate }}</th>
                    <th>{{ 'texto_66' | translate }}</th>
                    <th>{{ 'texto_95' | translate }}</th>
                    <th>{{ 'texto_91' | translate }}</th>
                    <th>{{ 'texto_92' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <!--Table head-->

                  <!--Table body-->

                  <tr *ngFor="let item of testigo; let i = index" class="table-info" style="font-size: 0.93rem;">
                    <th scope="row">{{i+1}}</th>
                    <td>{{item.n_testigo}}</td>
                    <td>{{item.correo_testigo}}</td>
                    <td>{{item.tlf_testigo}}</td>
                    <td><span>
                        <div [innerHtml]="item.comentario | safe: 'html'"></div>
                      </span></td>
                    <td>{{item.id_usuario }}</td>
                    <td>{{item.fec_ult_actualizac | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                  </tr>
                </tbody>
              </table>

            </div>

            <div class="panel-body" [style.display]="!bExisteTestigo ? 'block' : 'none'">
              <div class="col-md-12">
                <span class="resalta">{{ 'texto_96' | translate }}.</span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="denuncia && denuncia.length > 0" class="col-md-12"
          [style.display]="denuncia[0].informe_denunciante!='' ? 'block' : 'none'">
          <div class="panel panel-warning">
            <div class="panel-heading">
              <h2 class="panel-title">{{ 'texto_208' | translate }}</h2>
            </div>

            <div class="panel-body">
              <div class="col-md-12">
                <span [innerHtml]="denuncia[0].informe_denunciante | safe: 'html'"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="panel panel-danger" style="border-color:#20655f">
            <div class="panel-heading" style="background-color:#20655f;color:white;">
              <h2 class="panel-title">{{ 'texto_725' | translate }}</h2>
            </div>
            <div class="panel-body">
              <div class="panel-body-chat body-panel" [hidden]="!showChat">
                <ul class="chat">
                  <li *ngFor="let item of comentarios; let i = index"
                    [ngClass]="item.id_usuario == 'Denunciante' ? 'left clearfix' : 'right clearfix'">
                    <span [ngClass]="item.id_usuario == 'Denunciante' ? 'chat-img float-left' : 'chat-img float-right'">
                      <img width="40px" src="{{avatar}}" alt="User Avatar" class="img-circle" />
                    </span>
                    <div class="chat-body clearfix">
                      <div *ngIf="item.id_usuario == 'Denunciante'; else elseBlock">
                        <strong class="primary-font">{{ 'texto_97' | translate }}</strong>
                        <small class="float-right text-muted">
                          <span class="glyphicon glyphicon-time"></span>{{item.fecha_comentario | date: 'dd/MM/yyyy
                          HH:mm:ss'}}
                        </small>
                      </div>
                      <ng-template #elseBlock>
                        <div>
                          <strong class="float-right primary-font">{{ 'texto_726' | translate }}</strong>
                          <small class="text-muted">
                            <span class="glyphicon glyphicon-time"></span>{{item.fecha_comentario | date: 'dd/MM/yyyy
                            HH:mm:ss'}}
                          </small>
                          <br>
                        </div>
                      </ng-template>
                      <div class="comment-container">
                        <p *ngIf="item.id_usuario == 'Denunciante'; else elseBlock3">
                          {{item.comentario}}
                        </p>
                      </div>
                      <div class="comment-container">
                        <ng-template #elseBlock3>
                          <p style="text-align: right; width: 100%;">
                            {{item.comentario}}
                          </p>
                        </ng-template>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="clearfix" [hidden]="!sendChat">
                <div class="col-md-12" style="margin-top: 10px;">
                  <input type="hidden" name="usuario_coment" id="usuario_coment" value="denunciante">
                  <textarea maxlength="4000" [formControl]="comentarioDenuncia" class="form-control" rows="3"
                    name="comentarioDenuncia"></textarea>
                  <div class="pull-right">
                    <button (click)="enviarMensaje(denuncia[0].id_denuncia);" class="btn btn-finish  btn-wd"
                      id="btn-chat" name="anyadir_comentario">{{
                      'texto_41' | translate }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article><!-- end #main -->
    </div>
  </section>
</div>
<!--  Footer-->
<footer class="da-section">
  <div class="container text-white">
    <div class="row">
      <div class="col-md-4 col-lg-4">
        <p class="p-footer">&copy; 2024 Becompliance. {{ 'texto_42' | translate }}</p>
      </div>
      <div class="col-md-4 col-lg-4">
        <p class="p-footer"><a routerLink="/declaracion-de-accesibilidad" style="color: white">{{ 'texto_744' |
            translate }}</a></p>
      </div>
      <div class="col-md-4 col-lg-4">
        <p class="p-footer">CanalDenuncia.app v 3.3.6</p>
      </div>
    </div>
  </div>
</footer>
<!-- End  Footer -->
