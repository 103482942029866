import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TipoDenunciaService {

  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
  // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";
   constructor(private router: Router, private httpClient: HttpClient, private auth: AuthService) {}


  searchComplaintByUserSociety(id_sociedad:string,idioma:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTipoDenuncia.php`, {
      id_sociedad: id_sociedad,
      idioma: idioma,
    }, options);
  }
  searchLayerByUserSociety(id_sociedad:string,) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarAbogado.php`, {
      id_sociedad: id_sociedad,
    }, options);
  }
  searchReceptorObligatoryByUserSociety(id_usuario:string, id_sociedad:string,) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarReceptorObligatorio.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad,
    }, options);
  }
  searchComplaintBySocietyType(id_sociedad:string,id_tp_denuncia:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasPorTipoDenunciaSociedad.php`, {
      id_sociedad: id_sociedad,
      id_tp_denuncia: id_tp_denuncia
    }, options);
  }

  deleteComplaintByUser(id_sociedad:string, id_tp_denuncia:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarTipoDenuncia.php`, {
      id_sociedad: id_sociedad,
      id_tp_denuncia: id_tp_denuncia
    }, options);
  }
  updateComplaintByUser(id_sociedad:string, id_tp_denuncia:string, nombre:string, descripcion:string, id_usuario:string,tipo_receptor:string,id:string,idioma: string, id_gestor_conflicto: string, orden: string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarTipoDenuncia.php`, {
      id_sociedad: id_sociedad,
      id_tp_denuncia: id_tp_denuncia,
      nombre: nombre,
      descripcion: descripcion,
      id_usuario: id_usuario,
      tipo_receptor: tipo_receptor,
      id: id,
      idioma: idioma,
      id_gestor_conflicto: id_gestor_conflicto,
      orden
    }, options);
  }

  // METODO PARA AGGREGAR DENUNCIA
  addTypeComplaint(id_sociedad:string, id_tp_denuncia:string, nombre:string, descripcion:string, tipo_receptor:string,id:string,idioma: string, id_gestor_conflicto: string, orden: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearTipoDenuncia.php`, {
      id_sociedad: id_sociedad,
      id_tp_denuncia: id_tp_denuncia,
      nombre: nombre,
      descripcion: descripcion,
      tipo_receptor: tipo_receptor,
      id: id,
      idioma: idioma,
      id_gestor_conflicto,
      orden
    }, options);
  }
}
