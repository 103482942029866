import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css'],
})
export class SpinnerComponent implements OnInit {
  isLoadding$ = this.spinnerSvc.isLoadding$;

  constructor(private spinnerSvc: SpinnerService) { }

  ngOnInit(): void {
  }

}
