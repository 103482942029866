import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from '../model/datosDenuncia';
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-archivar',
  templateUrl: './archivar.component.html',
  styleUrls: ['./archivar.component.css']
})
export class ArchivarComponent implements OnInit {
  contador = 0
  validatingForm: FormGroup;
  IsHiddenForm: boolean =  false;
  @Input() denuncia: DatosDenuncia;
  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingForm = new FormGroup({
      textoNormativa: new FormControl('', [Validators.required])
    });
  }

  get textoNormativa() {
    return this.validatingForm.get('textoNormativa');
  }

  archivar(){
    if(this.contador>4000){
      this.toastr.error(this.translate.instant('texto_266'), this.translate.instant('texto_243'));
    }else{
      this.apiService.countActionsByIdComplaint(this.auth.getSociedad(), this.denuncia[0].id_denuncia).subscribe(
        res => {
          if(res == 200){
            this.apiService.updateComplaintById(this.denuncia[0].id_denuncia, "6", this.auth.getSociedad(),"","").subscribe(
              res => {
                if(res == 200){
                  this.apiService.sendReportByIdDenuncia(this.denuncia[0].id_denuncia,this.textoNormativa.value,'COMPLIANCE', this.auth.getIdUser()).subscribe(
                    res => {
                      if(res == 200){
                        this.apiService.updateParamComplaintById(this.denuncia[0].id_denuncia).subscribe(
                          res => {
                            if(res == 200){
                              if(this.denuncia[0].denunciante_correo != ""){
                                let $clau = false;
                                let clausulas_correo = localStorage.getItem("clausulas_correo");
                                if(clausulas_correo == '1'){
                                  $clau = true;
                                }

                                let url = environment.base + "/#/ver-denuncia/consultar-denuncia";
                                let message = this.translate.instant('texto_576') + " "  +  this.denuncia[0].denunciante_nombre + " " + this.denuncia[0].denunciante_apellidos + this.translate.instant('texto_586') + " "  + this.denuncia[0].id_denuncia + " "   + this.translate.instant('texto_587');
                                this.apiService.sendEmail(this.denuncia[0].denunciante_correo,this.translate.instant('texto_588') +this.denuncia[0].id_denuncia + this.translate.instant('texto_590'), message, url, $clau, this.translate.instant('texto_589')).subscribe(
                                  res => {
                                    if(res == 200){
                                      this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                                    }else{
                                      this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                                    }
                                });
                              }
                              this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                                this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
                              });
                            }else{
                              this.toastr.error(this.translate.instant('texto_196'), this.translate.instant('texto_192'));
                            }
                          });
                        }
                        else{
                          this.toastr.error(this.translate.instant('texto_196'), this.translate.instant('texto_192'));
                        }
                      });
                }else{
                  this.toastr.error(this.translate.instant('texto_196'), this.translate.instant('texto_192'));
                }
                /*this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                  this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
                }); */
            });
          }else{
              this.toastr.error(this.translate.instant(this.translate.instant('texto_591')));
          }
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
          });
        });
    }
  }

  onKey(event){
    var datos = document.getElementById("textoNormativa") ;
    for(var i=0; i< 1; i++){
      var div = datos.getElementsByClassName('nw-editor__res')[i];
      this.contador = div.textContent.length
    }
   }
}
