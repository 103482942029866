import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  FinalizarDenuncia,
  Personal,
} from 'src/app/enviar-denuncia/models/formData.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Pin } from 'src/app/enviar-denuncia/models/pin';
import { environment } from 'src/environments/environment';
import { Denuncia } from 'src/app/enviar-denuncia/models/denuncia';
import { Abogado } from 'src/app/enviar-denuncia/models/abogado';
import { DatosDenuncia } from 'src/app/enviar-denuncia/models/datosDenuncia';
import { Receptor } from 'src/app/enviar-denuncia/models/receptor';
import { Relacion } from 'src/app/enviar-denuncia/models/relacion';
import { Empresa } from 'src/app/enviar-denuncia/models//empresa';
import { EnviarDenunciaService } from 'src/app/enviar-denuncia/services/enviar-denuncia.service';
import { FormDataService } from 'src/app/enviar-denuncia/services/formData.service';
import { FinalizarDenunciaService } from 'src/app/enviar-denuncia/services/finalizar-denuncia.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-finalizar-denuncia',
  templateUrl: './finalizar-denuncia.component.html',
  styleUrls: ['./finalizar-denuncia.component.css'],
})
export class FinalizarDenunciaComponent implements OnInit {
  title = 'texto_359';
  finalizar: FinalizarDenuncia;
  pin: Pin;
  pin_code: string;
  form: any;
  @Input() formData;
  denuncias: Denuncia[];
  isExterna: boolean = false;
  abogados: Abogado[];
  denuncia: DatosDenuncia[];
  receptor: Receptor[];
  relaciones: Relacion[];
  empresas: Empresa[];
  finalizada: boolean = false;// Indicates whether a process or action is finalized (true) or not (false)
  terminos1: boolean = false;// Represents the status of terms or conditions 1. True if agreed, false if not agreed.
  politicas: boolean = false;// Represents the status of privacy policy. True if agreed, false if not agreed.
  documentosAbiertos: { [key: string]: boolean } = { 'url': false, 'documento': false };
  // An object that tracks the open status of documents.
  // 'url': True if the URL document is open, false otherwise.
  // 'documento': True if the document is open, false otherwise.
  canFinalize: boolean = false;
// Indicates whether the finalization process can proceed based on specified conditions.
// True if all necessary terms are agreed upon and required documents are open, otherwise false.

  testigos: Personal[];

  terminos2: string;
  url: string;
  documento: string;

  politicaEnabled: string;
  urlpolitica: string;
  docpolitica: string;

  fase_inicial: string;
  triaje: string = '';
  finaliza: any = {
    terminos: false,
    proteccionDatos: false
  };
  terminosDisabled: boolean = false;
  proteccionDatosDisabled: boolean = true;





  PHP_API_SERVER = environment.baseUrl;

  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

  constructor(
    private router: Router,
    private auth: AuthService,
    private formDataService: FormDataService,
    private enviarDenunciaService: EnviarDenunciaService,
    private finalizarDenunciaService: FinalizarDenunciaService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.finalizar = this.formDataService.getFinalizar();
    this.terminos2 = localStorage.getItem('terminos');
    this.url = localStorage.getItem('url');
    this.documento = localStorage.getItem('documento');
    this.politicaEnabled = localStorage.getItem('politicaEnabled');
    this.urlpolitica = localStorage.getItem('urlpolitica');
    this.docpolitica = localStorage.getItem('docpolitica');


    //console.log(this.terminos2);
    //console.log(this.url);
    //console.log(this.documento);
  }

  /**
   * Function to navigate to path or url of private privacy.
   */
  onPoliticasChange() {
    if (this.finalizar.politicas) {
      window.open(this.politicaEnabled == '1' ? this.urlpolitica : this.docpolitica, '_blank');
    }
    if (this.finaliza.politicas) {
      this.proteccionDatosDisabled = false;
    } else {
      this.finaliza.proteccionDatos = false;
      this.proteccionDatosDisabled = true;
    }
  }

  onTerminosChange() {
    if (this.finalizar.terminos) {
      window.open(this.terminos2 == '1' ? this.url : this.documento, '_blank');
    }
    if (this.finaliza.terminos) {
      this.proteccionDatosDisabled = false;
    } else {
      this.finaliza.proteccionDatos = false;
      this.proteccionDatosDisabled = true;
    }
  }

  onProteccionDatosChange() {
    // You can add any additional logic here if necessary
  }

  finalizarDenuncia() {
    if (this.finalizar.terminos && !this.proteccionDatosDisabled && this.finalizar.proteccionDatos) {
      // Process to finalize the complaint
      console.log('Denuncia finalizada');
    }
  }

  documentOpened(doc: string) {
    // Mark the document as opened
    this.documentosAbiertos[doc] = true;
    // Mark the document as opened
    //this.checkTerms();
    // Log the status of the opened document to the console
    console.log(this.documentosAbiertos[doc]);
  }



  save(form: any): boolean {
    if (!form.valid) {
      return false;
    }

    this.formDataService.setFinalizar(this.finalizar);
    return true;
  }

  goToPrevious(form: any) {
    //if (this.save(form)) {
    // Navigate to the personal page
    this.formDataService.setFinalizar(this.finalizar);

    this.router.navigate(['/enviar-denuncia/archivos']);
    // }
  }
  goToNext(form: any) {
    if (this.save(form) && this.finalizada === false) {
      this.finalizada = true;
      for (
        var i = 0;
        i < document.getElementsByClassName('grecaptcha-badge').length;
        i++
      ) {
        var div = document.getElementsByClassName('grecaptcha-badge')[i];
        div.setAttribute('style', 'display:none');
      }
      // Navigate to the work page
      this.formData = this.formDataService.getFormData();
      let nombreArchivo = this.formData.nombreArchivo;
      /*if(this.formData.nombreArchivo != ""){
          let nombrePartes = this.formData.nombreArchivo;
          let nPartes = nombrePartes.split("\\",3);
          nombreArchivo = nPartes[2];
        }*/

      /* let idEmpresa = ""
        let idSociedad = ""
        this.apiService.searchCompanyById(this.formData.empresa).subscribe((empresas: Empresa[])=>{
          this.empresas = empresas;
          idEmpresa = this.empresas[0].id_empresa;
          idEmpresa = this.empresas[0].id_empresa;
        });*/

      let language = this.auth.getLanguage();
      if (language == '' || language == null) {
        language = 'es';
      }
      this.translate.setDefaultLang(language);
      let generatepin = '';
      this.enviarDenunciaService
        .searchComplaintByUserSocietyID(
          this.formData.empresa,
          this.formData.tipoDenuncia,
          false,
          language
        )
        .subscribe((denuncia: Denuncia[]) => {
          this.denuncias = denuncia;

          if (this.denuncias[0].tipo_gestion == '2') {
            this.isExterna = true;
          }
          let finalizadaDenuncia = (<HTMLInputElement>(
            document.getElementById('finaliced')
          )).value;

          let $clau = false;
          let clausulas_correo = localStorage.getItem('clausulas_correo');
          if (clausulas_correo == '1') {
            $clau = true;
          }
          // Antes de la llamada al servicio createComplaint()
          const testigos = this.formData.testigos.map((testigo) => ({
            nombre_testigo: testigo.firstName,
            apellido_testigo: testigo.lastName,
            email_testigo: testigo.email,
            phone_testigo: testigo.phone,
            descripcion_testigo: testigo.description,
          }));
          if (this.isExterna) {
            this.finalizarDenunciaService
              .createComplaint(
                this.formData.empresa,
                this.formData.idCentro,
                this.formData.tipoDenuncia,
                this.formData.denuncianteFirstName,
                this.formData.denuncianteLastName,
                this.formData.denuncianteDNI,
                this.formData.denuncianteEmail,
                this.formData.denunciantePhone,
                this.formData.fechaDatosDenuncia,
                this.formData.denuncianteRelacion,
                this.formData.descripcionDatosDenuncia,
                '6',
                this.formData.identificadorEmpresa,
                this.formData.comentario,
                nombreArchivo,
                this.formData.receptor,
                this.formData.motivo,
                this.formData.receptorInicial,
                finalizadaDenuncia,
                testigos
                //this.formData.testigos
                //this.testigos,
                //this.testigos
              )

              .subscribe(
                (pin: Pin) => {
                  this.pin = pin;
                  if (this.pin.pin_code != '0') {
                    this.toastr.success(
                      this.translate.instant('texto_262'),
                      this.translate.instant('texto_263')
                    );
                    this.pin_code = this.pin.pin_code;
                    generatepin =
                      this.makeid(5) + this.pin_code + this.makeid(5);
                    this.finalizarDenunciaService
                      .updateParamComplaintById(this.pin.id_denuncia)
                      .subscribe((res) => {
                        if (res == 200) {
                          //save files in list
                          this.submitFiles(this.pin.id_denuncia);
                          if (this.formData.denuncianteEmail != '') {
                            let url =
                              environment.base +
                              '/#/ver-denuncia/consultar-denuncia';
                            let message =
                              this.translate.instant('texto_576') +
                              ' ' +
                              this.formData.denuncianteFirstName +
                              ' ' +
                              this.formData.denuncianteLastName +
                              this.translate.instant('texto_604') + " " +
                              this.pin.id_denuncia +
                              ' y pin ' + " " +
                              this.pin.pin_code + " " +
                              this.translate.instant('texto_625');
                            this.finalizarDenunciaService
                              .sendEmail(
                                this.formData.denuncianteEmail,
                                this.translate.instant('texto_623'),
                                message,
                                url,
                                $clau,
                                this.translate.instant('texto_624')
                              )
                              .subscribe((res) => {
                                if (res == 200) {
                                  //this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                                } else {
                                  this.toastr.error(
                                    this.translate.instant('texto_193'),
                                    this.translate.instant('texto_195')
                                  );
                                }
                              });
                          }
                        }
                      });

                    this.finalizarDenunciaService
                      .searchLayerByIdSociety(
                        this.denuncias[0].id_gestor,
                        this.formData.empresa
                      )
                      .subscribe((abogado: Abogado[]) => {
                        this.abogados = abogado;
                        let message =
                          this.translate.instant('texto_576') +
                          ' ' +
                          this.abogados[0].n_abogado +
                          this.translate.instant('texto_626');
                        let message2 = this.translate.instant('texto_644');
                        if (this.formData.denuncianteEmail != '') {
                          message2 =
                            message2 +
                            this.translate.instant('texto_627') +
                            this.formData.denuncianteFirstName +
                            ' ' +
                            this.formData.denuncianteLastName +
                            '<br>' +
                            this.translate.instant('texto_628') +
                            this.formData.denuncianteDNI +
                            '<br>' +
                            this.translate.instant('texto_629') +
                            this.formData.denuncianteEmail +
                            '<br>' +
                            this.translate.instant('texto_630') +
                            this.formData.denunciantePhone +
                            '<br>';
                        }
                        let language = this.auth.getLanguage();
                        if (language == '' || language == null) {
                          language = 'es';
                        }
                        this.translate.setDefaultLang(language);
                        let tipoDenuncia = '';
                        this.enviarDenunciaService
                          .searchComplaintByUserSocietyID(
                            this.formData.empresa,
                            this.formData.tipoDenuncia,
                            false,
                            language
                          )
                          .subscribe((denuncia: Denuncia[]) => {
                            this.denuncias = denuncia;
                            tipoDenuncia = denuncia[0].nombre;
                            let tipoRelacion = '';
                            this.finalizarDenunciaService
                              .searchRelationTypeById(
                                this.formData.denuncianteRelacion,
                                this.formData.empresa,
                                language
                              )
                              .subscribe((relacion: Relacion[]) => {
                                this.relaciones = relacion;
                                tipoRelacion = relacion[0].nombre;
                                let message3 =
                                  this.translate.instant('texto_632') +
                                  this.formData.fechaDatosDenuncia.format(
                                    'DD/MM/YYYY'
                                  ) +
                                  '<br>' +
                                  this.translate.instant('texto_633') +
                                  tipoRelacion +
                                  '<br>' +
                                  this.translate.instant('texto_634') +
                                  tipoDenuncia +
                                  '<br>' +
                                  this.translate.instant('texto_635') +
                                  this.formData.descripcionDatosDenuncia +
                                  '<br>' +
                                  this.translate.instant('texto_636') +
                                  this.formData.comentario +
                                  '<br>';

                                this.finalizarDenunciaService
                                  .sendEmail(
                                    this.abogados[0].email,
                                    this.translate.instant('texto_631'),
                                    message +
                                    '<br>' +
                                    message2 +
                                    '<br>' +
                                    message3,
                                    '',
                                    $clau,
                                    this.translate.instant('texto_631')
                                  )
                                  .subscribe((res) => {
                                    if (res == 200) {
                                      //this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                                    } else {
                                      this.toastr.error(
                                        this.translate.instant('texto_193'),
                                        this.translate.instant('texto_195')
                                      );
                                    }
                                  });
                              });
                          });
                      });

                    this.router
                      .navigateByUrl('/RefreshComponent', {
                        skipLocationChange: true,
                      })
                      .then(() => {
                        this.router.navigate([
                          '/enviar-denuncia/finalizada/',
                          generatepin,
                        ]);
                      });
                  } else {
                    this.toastr.error(
                      this.translate.instant('texto_264'),
                      this.translate.instant('texto_263')
                    );
                    this.auth.logoutSimple();
                    this.router
                      .navigateByUrl('/RefreshComponent', {
                        skipLocationChange: true,
                      })
                      .then(() => {
                        this.router.navigate(['login']);
                      });
                  }
                },
                (err: any) => {
                  this.toastr.error(
                    this.translate.instant('texto_264'),
                    this.translate.instant('texto_263')
                  );
                }
              );
          } else {
            this.fase_inicial = '0';
            this.triaje = localStorage.getItem('triaje');
            if (this.triaje == '1') {
              this.fase_inicial = '11';
            }

            this.finalizarDenunciaService
              .createComplaint(
                this.formData.empresa,
                this.formData.identificadorCentro,
                this.formData.tipoDenuncia,
                this.formData.denuncianteFirstName,
                this.formData.denuncianteLastName,
                this.formData.denuncianteDNI,
                this.formData.denuncianteEmail,
                this.formData.denunciantePhone,
                this.formData.fechaDatosDenuncia,
                this.formData.denuncianteRelacion,
                this.formData.descripcionDatosDenuncia,
                this.fase_inicial,
                this.formData.identificadorEmpresa,
                this.formData.comentario,
                nombreArchivo,
                this.formData.receptor,
                this.formData.motivo,
                this.formData.receptorInicial,
                finalizadaDenuncia,
                testigos
                //this.testigos
              )
              .subscribe(
                (pin: Pin) => {
                  this.pin = pin;
                  if (this.pin.pin_code != '0') {
                    this.toastr.success(
                      this.translate.instant('texto_262'),
                      this.translate.instant('texto_263')
                    );
                    this.pin_code = this.pin.pin_code;
                    generatepin =
                      this.makeid(5) + this.pin_code + this.makeid(5);
                    //save files in list
                    this.submitFiles(this.pin.id_denuncia);
                    if (this.formData.denuncianteEmail != '') {
                      let url =
                        environment.base + '/#/ver-denuncia/consultar-denuncia';
                      let message =
                        this.translate.instant('texto_576') +
                        ' ' +
                        this.formData.denuncianteFirstName +
                        ' ' +
                        this.formData.denuncianteLastName +
                        this.translate.instant('texto_637') + " " +
                        this.pin.pin_code + " " +
                        this.translate.instant('texto_645') + " " +
                        this.pin.id_denuncia + " " +
                        this.translate.instant('texto_638');
                      this.finalizarDenunciaService
                        .sendEmail(
                          this.formData.denuncianteEmail,
                          this.translate.instant('texto_623'),
                          message,
                          url,
                          $clau,
                          this.translate.instant('texto_624')
                        )
                        .subscribe((res) => {
                          if (res == 200) {
                            this.finalizarDenunciaService
                              .searchComplaintByPin(this.pin_code, language)
                              .subscribe((denuncia: DatosDenuncia[]) => {
                                this.denuncia = denuncia;
                                let url2 =
                                  environment.base +
                                  '/#/editar-denuncia/' +
                                  this.denuncia[0].id_denuncia;
                                this.finalizarDenunciaService
                                  .searchReceptorByIdComplaint(
                                    this.denuncia[0].id_denuncia,
                                    true
                                  )
                                  .subscribe((receptor: Receptor[]) => {
                                    this.receptor = receptor;
                                    let messageReceptor =
                                      this.translate.instant('texto_576') +
                                      ' ' +
                                      this.receptor[0].id_user +
                                      this.translate.instant('texto_639') + " " +
                                      this.denuncia[0].id_denuncia + " " +
                                      this.translate.instant('texto_640');
                                    let asunto =
                                      this.translate.instant('texto_641');
                                    if (this.triaje == '1') {
                                      asunto =
                                        this.translate.instant('texto_642');
                                    }
                                    this.finalizarDenunciaService
                                      .sendEmail(
                                        this.receptor[0].mail_user,
                                        asunto,
                                        messageReceptor,
                                        url2,
                                        $clau,
                                        asunto
                                      )
                                      .subscribe((res) => {
                                        if (res == 200) {
                                          //this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                                        } else {
                                          this.toastr.error(
                                            this.translate.instant('texto_193'),
                                            this.translate.instant('texto_195')
                                          );
                                        }
                                      });
                                  });
                              });
                          } else {
                            this.toastr.error(
                              this.translate.instant('texto_193'),
                              this.translate.instant('texto_195')
                            );
                          }
                        });
                    } else {
                      let language = this.auth.getLanguage();
                      if (language == '' || language == null) {
                        language = 'es';
                      }
                      this.finalizarDenunciaService

                        .searchComplaintByPin(this.pin_code, language)
                        .subscribe((denuncia: DatosDenuncia[]) => {
                          this.denuncia = denuncia;
                          this.finalizarDenunciaService
                            .searchReceptorByIdComplaint(
                              this.denuncia[0].id_denuncia,
                              true
                            )
                            .subscribe((receptor: Receptor[]) => {
                              this.receptor = receptor;
                              let url2 =
                                environment.base +
                                '/#/editar-denuncia/' +
                                this.denuncia[0].id_denuncia;
                              let asunto = this.translate.instant('texto_641');
                              if (this.triaje == '1') {
                                asunto = this.translate.instant('texto_642');
                              }
                              let messageReceptor =
                                this.translate.instant('texto_576') +
                                ' ' +
                                this.receptor[0].id_user +
                                this.translate.instant('texto_646') + " " +
                                this.denuncia[0].id_denuncia + " " +
                                this.translate.instant('texto_643');
                              this.finalizarDenunciaService
                                .sendEmail(
                                  this.receptor[0].mail_user,
                                  asunto,
                                  messageReceptor,
                                  url2,
                                  $clau,
                                  asunto
                                )
                                .subscribe((res) => {
                                  if (res == 200) {
                                    //this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                                  } else {
                                    this.toastr.error(
                                      this.translate.instant('texto_193'),
                                      this.translate.instant('texto_195')
                                    );
                                  }
                                });
                            });
                        });
                    }

                    this.router
                      .navigateByUrl('/RefreshComponent', {
                        skipLocationChange: true,
                      })
                      .then(() => {
                        this.router.navigate([
                          '/enviar-denuncia/finalizada/',
                          generatepin,
                        ]);
                      });
                  } else {
                    this.toastr.error(
                      this.translate.instant('texto_264'),
                      this.translate.instant('texto_263')
                    );
                    this.auth.logoutSimple();
                    this.router
                      .navigateByUrl('/RefreshComponent', {
                        skipLocationChange: true,
                      })
                      .then(() => {
                        this.router.navigate(['login']);
                      });
                  }
                },
                (err: any) => {
                  this.toastr.error(
                    this.translate.instant('texto_264'),
                    this.translate.instant('texto_263')
                  );
                }
              );
          }

          /* this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
              this.router.navigate(['finalizada/', generatepin]);
            });*/
        });
    }
  }

  submitFiles(id_denuncia) {
    //get files list
    let archivos = this.formDataService.getArchivos();
    //console.log(archivos);
    if (archivos.length > 0) {
      archivos.forEach(archivo => {
        const formData = new FormData();
        //set data to formData
        formData.append('file', archivo.nombreArchivo);
        formData.append('id_sociedad', this.formDataService.getTipoHecho().empresa);
        formData.append('id_denuncia', id_denuncia);
        formData.append('comentario', archivo.descripcionArchivo);
        formData.append('id_usuario', 'Denunciante');
        //send to the UploadFile service the parameter fomData
        this.finalizarDenunciaService.uploadFile(formData).subscribe(
          (response) => {
            //console.log('Respuesta del servicio:', response);
          },
          (error) => {
            //console.error('Error al subir el archivo:', error);
          }
        );
      });
    }

  }

  hide() {
    this.auth.logoutSimple();
    // //window.location.reload();
    this.router
      .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/inicio']);
      });
  }

  private makeid(length) {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  navigateToNewPage() {
    //Replace the current history state with a new entry for the root URL ('/')
    this.auth.logoutSimple();
    window.history.replaceState(null, '', '/');
  }
}
