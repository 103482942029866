<!-- Botón para abrir el modal -->
<button style="background-image: url(/../../assets/img/botones/Boton_EditarPlazos.png);" class="btn btn-rounded btn-cabecera" matTooltip="{{'texto_747' | translate }}"  type="button" mdbBtn rounded="true" data-toggle="modal" data-target="#basicExample"
    (click)="frame.show()" mdbWavesEffect>
</button>
 
<!-- Modal -->
<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="max-width: 100% !important;">
    
    <div class="modal-content">

        
        <div class="modal-body mx-3" style="padding-right: 1rem; padding-left: 1rem;">
           
            <div class="card-header card-header-info">
              <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_747' | translate }}</h5>
          </div>
          <!-- Botón para cerrar el modal -->
          <button type="button" style="z-index:100;float: right;margin-top: -2.8rem;" class="btn btn-dark" data-dismiss="modal" (click)="frame.hide()" aria-label="Cerrar modal">X</button> 
          <!-- Formulario dentro del modal -->
          <form #validatingForm="ngForm" class="editForm" novalidate>
            <br>
            <div class="row">
                <!-- Campo para el plazo de investigación -->
                <div class="col-xs-12 col-md-12">
                  <label for="form-name">{{ 'texto_72' | translate }}*</label>
                  <input type="number" id="plazoFaseInvestigacion" class="form-control" [formControl]="plazoFaseInvestigacion" required [(ngModel)]="plazoFaseInvestigacionF" value="{{plazoFaseInvestigacionF}}">
                </div>
                <!-- Campo para el plazo del decisor -->
                <div class="col-xs-12 col-md-12">
                  <label for="form-name">{{ 'texto_73' | translate }}*</label>
                  <input type="number" id="plazoFaseDecisor" class="form-control" [formControl]="plazoFaseDecisor" required [(ngModel)]="plazoFaseDecisorF" value="{{plazoFaseDecisorF}}">
                </div>
                <!-- Campo para el plazo de cumplimiento -->
                <div class="col-xs-12 col-md-12">
                  <label for="form-name">{{ 'texto_74' | translate }}*</label>
                  <input type="number" id="plazoFaseCompliance" class="form-control" [formControl]="plazoFaseCompliance" required [(ngModel)]="plazoFaseComplianceF" value="{{plazoFaseComplianceF}}">
                </div>
            </div>
            <!-- Texto pequeño debajo de los campos de entrada -->
            <small style="font-weight: bold;font-size: 0.62rem;">{{ 'texto_400' | translate }}</small>

            <!-- Botón para actualizar los plazos -->
            <div class="text-center mt-3">
              <button (click)="editPlazos()" mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_59' | translate }}</button>
            </div>
          </form>
        </div>
    </div>
  </div>
</div>
