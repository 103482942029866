<!-- Header after login  -->
<div *ngIf="IsHiddenLanding">
  <app-nav-fixed [IsHiddenLanding]="IsHiddenLanding"></app-nav-fixed>
</div>
<!-- End Header after login  -->

<!-- Saltar al contenido principal -->
<p *ngIf="!IsHiddenLanding">
  <a id="skip-link" class='admin-bar skip-link' href="javascript:void(0);" (click)="navigateToButton()">{{ 'texto_743'
    |translate }}</a>
</p>
<!-- End Saltar al contenido principal -->

<!-- Header Homepage -->
<header *ngIf="!IsHiddenLanding" class="header-homepage">
  <div class="header-collapse">
    <!-- Logo -->
    <div>
      <img class="logo" src="assets/img/logos/logo.png" alt="{{ 'texto_294' | translate }} {{ 'texto_6' | translate }}">
    </div>
    <!-- End Logo -->

    <!-- Menu Collapse Button -->
    <div class="button-container">
      <button class="menuButton" mat-icon-button aria-label="menu" (click)="toggleCollapse()">
        <mdb-icon fas icon="bars" class="prefix menuButton"></mdb-icon>
      </button>
    </div>
    <!-- End Menu Collapse Button -->
  </div>
  <!-- Menu  -->
  <nav class="navbar navbar-expand-lg navbar-dark bg-transparent px-0" *ngIf="collapse">
    <div class="navbar-collapse text-uppercase menu">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item"><a class="nav-link smooth-scroll" routerLink="/inicio">{{ 'texto_1' | translate }}</a></li>
        <li class="nav-item"><a class="nav-link smooth-scroll" href="javascript:void(0);"
            (click)="navigateToSection('services')">{{ 'texto_2' | translate }}</a></li>
        <li class="nav-item"><a class="nav-link smooth-scroll" href="javascript:void(0);"
            (click)="navigateToSection('denuncia')">{{ 'texto_3' | translate }}</a></li>
        <li class="nav-item"><a class="nav-link smooth-scroll" href="javascript:void(0);"
            (click)="navigateToSection('support')">{{ 'texto_4' | translate }}</a></li>
        <li class="nav-item">
          <a class="nav-link smooth-scroll"><app-translation></app-translation></a>
        </li>
      </ul>
    </div>
    <!-- Botón login-->
    <div class="div-login">
      <a class="smooth-scroll btn mt-4 menu btn-login" (click)="isOn(false)" routerLink="/login"><mdb-icon fas
          icon="user" class="prefix"></mdb-icon>
        {{ 'texto_252' | translate }}
      </a>
    </div>
  </nav>
  <!-- End Menu  -->
</header>
<!-- End Header Homepage -->

<main *ngIf="!IsHiddenLanding">
  <!-- Image and welcome message -->
  <section class="welcome-section">
    <div id="inicio" class="da-home-page-text" data-aos="fade-right" data-aos-duration="1000">
      <!-- Fondo negro fijo -->
      <!-- <div class="fondo-negro"> -->
        <!-- Fondo dinámico -->
        <div class="fondo-negro color-txt-fondo">
        <div class="col-md-10 col-sm-12 px-0 mx-0">
          <!-- welcome message -->
          <h1>
            <div>
              <span class="texto_5 display-2">{{ 'texto_5' | translate }}</span>
            </div>
            <div>
              <span class="texto_6 display-3">{{ 'texto_6' | translate }}</span>
            </div>
          </h1>
          <!-- End welcome message -->
        </div>
        <div class="col-md-10 col-sm-12 px-0 mx-0">
          <!-- Buttons Poner denuncia and ver denuncia -->
          <a class="smooth-scroll btn mt-3 btn-denuncia" (click)="isOn(false)"
            routerLink="/enviar-denuncia/datos-empresa">{{ 'texto_7' | translate }}
          </a>
          <a class="smooth-scroll btn mt-3 btn-ver-denuncia" (click)="isOn(false)"
            routerLink="/ver-denuncia/consultar-denuncia">{{ 'texto_8' | translate }}
          </a>
          <!-- End Buttons Poner denuncia and ver denuncia -->
        </div>
      </div>
      <!-- Fin fondo negro fijo -->
    </div>
  </section>
  <!-- End Image and welcome message -->

  <!-- información principal section -->
  <section>
    <app-informacion-principal></app-informacion-principal>
  </section>
  <!-- Endinformación principal section -->
</main>

<!--  Footer-->
<footer class="da-section" *ngIf="!IsHiddenLanding">
  <div class="container text-white">
    <div class="row">
      <div class="col-md-4 col-lg-4">
        <p class="p-footer">&copy; {{dateYear}} Becompliance. {{ 'texto_42' | translate }}</p>
      </div>
      <div class="col-md-4 col-lg-4">
        <p class="p-footer"><a (click)="isOn(false)" routerLink="/declaracion-de-accesibilidad" style="color: white">
          {{ 'texto_744' | translate }}</a></p>
      </div>
      <div class="col-md-4 col-lg-4">
        <p class="p-footer">CanalDenuncia.app v {{version}}</p>
      </div>
    </div>
  </div>
</footer>
<!-- End  Footer -->
