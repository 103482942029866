import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CentrosService } from 'src/app/dashboard/services/centros.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';
import { Centro } from 'src/app/dashboard/models/centro';
import { Empresa } from 'src/app/dashboard/models/empresa';
//import { SpinnerService } from '../services/spinner.service';

@Injectable()
@Component({
  selector: 'app-centros',
  templateUrl: './centros.component.html',
  styleUrls: ['./centros.component.css']
})
export class CentrosComponent implements OnInit {

  sociedad: string;
  centros: Centro[];
  allCentros: Centro[] = []; // Mantener una copia de todos los centros
  empresas: Empresa[]
  validatingForm: FormGroup;
  currentPage = 1;
  pageSize = 10; // Tamaño de página deseado, puedes ajustarlo según tus necesidades.
  totalItems = 0;

  constructor(private centersService: CentrosService,private router: Router, private auth: AuthService, private toastr: ToastrService, private changeDetectorRefs: ChangeDetectorRef,private translate: TranslateService) { }
  IsHiddenForm: boolean =  false;

  id_centro: string;
  n_centro: string;
  id_empresa: string;
  n_empresa: string;
  comentarios: string;
  selectedEmpresa: string //id empresa seleccionada

ngOnInit() {
  let language = this.auth.getLanguage();
  if(language == "" || language == null){
    language = 'es';
  }
  this.translate.setDefaultLang(language);

    this.sociedad = this.auth.getNameSociedad();
    //Select a QSC_CENTROS
    this.loadCentros();

    //Select a QSC_EMPRESAS
    this.centersService.searchCompanyByUser(this.auth.getSociedad()).subscribe((empresas: Empresa[]) => {
      this.empresas = empresas;
    });

  //FORM VALIDATE
  this.validatingForm = new FormGroup({
    signupFormModalName: new FormControl(''),
    signupFormModalId: new FormControl(''),
    signupFormModalidEmpresa: new FormControl('', [Validators.required]),
    contactFormModalMessage: new FormControl('', [])
  });
  }

  loadCentros() {
    this.centersService.searchCenterByUser(this.auth.getSociedad()).subscribe((centros: Centro[]) => {
      this.centros = centros;
      this.allCentros = centros;
      this.totalItems = centros.length;
      this.updateCurrentPageCentros(); // Actualizar los centros de la página actual
    });
  }

  getCurrentPageCentros(): Centro[] {
    return this.centros;
  }

  updateCurrentPageCentros() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    this.centros = this.allCentros.slice(startIndex, startIndex + this.pageSize);
  }

  onPageChange(event: any) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadCentros(); // Vuelve a cargar los datos cuando cambia la página
  }
  //NOMBRE CENTRO
  get signupFormModalName() {
    return this.validatingForm.get('signupFormModalName');
  }

  //ID CENTRO
  get signupFormModalId() {
    return this.validatingForm.get('signupFormModalId');
  }

  //COMENTARIOS
   get contactFormModalMessage() {
     return this.validatingForm.get('contactFormModalMessage');
   }

   //ID EMPRESA
  get signupFormModalidEmpresa() {
    return this.validatingForm.get('signupFormModalidEmpresa');
  }

  /**
   * MÉTODO ELIMINAR CENTRO
   * @param id_empresa
   */
  deleteCenter(id_centro:string){
    this.centersService.countComplaintByIdCenter(this.auth.getSociedad(), id_centro).subscribe(
      res => {
        if(res == 200){
          this.centersService.deleteCenterByUser(this.auth.getSociedad(),id_centro).subscribe(
            res => {
              if(res == 200){
                this.toastr.success(this.translate.instant('texto_676'), this.translate.instant('texto_679'));
              }else{
                this.toastr.error(this.translate.instant('texto_677'), this.translate.instant('texto_679'));
              }
              this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/dashboard/layout/centros']);
            });
          });
        }else{
          this.toastr.error(this.translate.instant('texto_678'), this.translate.instant('texto_679'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/dashboard/layout/centros']);
        });
    });
  }

  /**
   * MOSTRAR DATOS
   * @param centro
   */
  showCenter(centro:Centro){
    this.IsHiddenForm = true;
    this.id_centro = centro.id_centro;
    this.n_centro = centro.n_centro;
    this.id_empresa = centro.id_empresa;
    this.n_empresa = centro.n_empresa;
    this.comentarios = centro.comentarios;
  }

  /**
   * OCULTAR MODAL
   */
  hideModal(){
    this.IsHiddenForm = false;
  }

  /**
   * ACTUALIZAR CENTRO
   * @param id_centro
   */
  updateCenter(id_centro:string){
    this.centersService.updateCenterByUser(this.auth.getSociedad(), id_centro, this.signupFormModalName.value,this.signupFormModalidEmpresa.value, this.contactFormModalMessage.value).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_680'), this.translate.instant('texto_682'));
        }else{
          this.toastr.error(this.translate.instant('teato_681'), this.translate.instant('texto_682'));
        }
       this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/dashboard/layout/centros']);
        });
    });
  }



}
