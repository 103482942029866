import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ConsultasService {

    PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
    // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";
    constructor(private router: Router, private httpClient: HttpClient, private auth: AuthService) { }

    searchAtributos(n_tabla: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };
        return this.httpClient.post(`${this.PHP_API_SERVER}/api/getatributos.php`, {
            n_tabla: n_tabla
        }, options);
    }

    searchTablas() {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };
        return this.httpClient.post(`${this.PHP_API_SERVER}/api/gettablas.php`, {

        }, options);
    }

    ejecutarQuery(query:string, campo_1: string) {
        const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
        let options = { headers: headers };
    
        return this.httpClient.post(`${this.PHP_API_SERVER}/api/ejecutarQuery.php`, {
          query: query,
          campo_1: campo_1
        }, options); 
      }

}
