import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConsultarDenunciaComponent } from 'src/app/ver-denuncias/components/consultar-denuncia/consultar-denuncia.component';
import { VerDenunciaComponent } from 'src/app/ver-denuncias/components/ver-denuncia/ver-denuncia.component';
import { ConsultarDenunciaGuard } from './guards/consultar-denuncia.guard';

const routes: Routes = [
  {
    path: 'consultar-denuncia',
    component: ConsultarDenunciaComponent
  },
  { path: ':id',
    component: VerDenunciaComponent, canActivate: [ConsultarDenunciaGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DenunciasRoutingModule {}
