import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionAvanzadaService {
  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
  constructor(private httpClient: HttpClient, private auth: AuthService) { }

  searchTodosIdiomas() {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTodosIdiomas.php`, {
    }, options);
  }

  searchAdvancedParam(id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarConfiguracionParametros.php`, {
      id_sociedad: id_sociedad,
    }, options);
  }

  searchSSOdParam(id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarSSOParametros.php`, {
      id_sociedad: id_sociedad,
    }, options);
  }

  searchTextos(id_texto: string, idioma: string, id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTextos.php`, {
      id_texto: id_texto,
      idioma: idioma,
      id_sociedad: id_sociedad,
    }, options);
  }

  searchClausulas(id_clausula: string, id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarClausulas.php`, {
      id_clausula: id_clausula,
      id_sociedad: id_sociedad,
    }, options);
  }

  searchTextos2(id_texto: string, seguro: boolean, idioma: string, id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getTextos.php`, {
      id_texto: id_texto,
      seguro: seguro,
      idioma: idioma,
      id_sociedad: id_sociedad,
    }, options);
  }

  searchClausulas2(id_clausula: string, seguro: boolean, id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getClausulas.php`, {
      id_clausula: id_clausula,
      seguro: seguro,
      id_sociedad: id_sociedad,
    }, options);
  }

  updateAdvancedParam(id_sociedad: string, anonimo: string, dominio: string, consultas: string, plazo: string, clausulas: string, conflicto: string, diasinves: string, diasdecisor: string, diascompliance: string, terminos: string, doc_terminos: string, ruta: string, diasverdenuncia: string, boton1: string, boton2: string, boton3: string, triaje: string, centros: string, inputAutocomplete: string, popUp: string, politica: string, doc_politica: string, rutapolitica: string/*, docboton1: string, docboton2: string, docboton3: string*/) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearConfiguracionParametros.php`, {
      id_sociedad: id_sociedad,
      anonimo: anonimo,
      dominio: dominio,
      consultas: consultas,
      plazo: plazo,
      clausulas: clausulas,
      conflicto: conflicto,
      diasinves: diasinves,
      diasdecisor: diasdecisor,
      diascompliance: diascompliance,
      terminos: terminos,
      doc_terminos: doc_terminos,
      ruta: ruta,
      diasverdenuncia: diasverdenuncia,
      boton1: boton1,
      boton2: boton2,
      boton3: boton3,
      popUp: popUp,
      triaje: triaje,
      centros: centros,
      inputAutocomplete: inputAutocomplete,
      politica: politica,
      doc_politica: doc_politica,
      rutapolitica: rutapolitica

      /*,
      docboton1: boton1,
      docboton2: boton2,
      docboton3: boton3 */
    }, options);
  }

  updateAdvancedParam3(id_sociedad: string, colorprincipal: string, colorsecundario: string, colorbgseccion2: string, colorbgseccion3: string, colortxtbtn: string, colortxtseccion1: string, colortxtseccion2: string, colortxtseccion3: string, colortxttitulo: string, colorbgseccion1: string, colormenulateral: string, colortxtcabeceras: string, colortxtbotones: string, colortxtFondo: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearConfiguracionParametros3.php`, {
      id_sociedad: id_sociedad,
      colorprincipal: colorprincipal,
      colorsecundario: colorsecundario,
      colorbgseccion2: colorbgseccion2,
      colorbgseccion3: colorbgseccion3,
      colortxtbtn: colortxtbtn,
      colortxtseccion1: colortxtseccion1,
      colortxtseccion2: colortxtseccion2,
      colortxtseccion3: colortxtseccion3,
      colortxttitulo: colortxttitulo,
      colorbgseccion1: colorbgseccion1,
      colormenulateral: colormenulateral,
      colortxtcabeceras: colortxtcabeceras,
      colortxtbotones: colortxtbotones,
      colortxtFondo: colortxtFondo
    }, options);
  }

  updateAdvancedParam2(id_sociedad: string, nremitente: string, remitente: string, srv_mail: string, puerto: string, usr_mail: string, pass_mail: string, ssl: string, auth_mail: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearConfiguracionParametros2.php`, {
      id_sociedad: id_sociedad,
      nremitente: nremitente,
      remitente: remitente,
      srv_mail: srv_mail,
      puerto: puerto,
      usr_mail: usr_mail,
      pass_mail: pass_mail,
      ssl: ssl,
      auth_mail: auth_mail,
    }, options);
  }

  updateAdvancedParam4(id_sociedad: string, multi: string, tieneSSO: string, archivardenuncia: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearConfiguracionParametros4.php`, {
      id_sociedad: id_sociedad,
      multi: multi,
      tieneSSO: tieneSSO,
      archivardenuncia
    }, options);
  }
  ///METHOD TO UPDATE saml enabled
  updateSamlEnabled(id_sociedad: string, saml_enabled: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() };
    let options = { headers: headers };


    return this.httpClient.post(`${this.PHP_API_SERVER}/api/updateSamlEnabled.php`, {
      id_sociedad: id_sociedad,
      saml_enabled: saml_enabled,
    }, options);
  }


  updateTextos(id_texto: string, texto: string, idioma: string, id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearTextos.php`, {
      id_texto: id_texto,
      texto: texto,
      idioma: idioma,
      id_sociedad: id_sociedad,
    }, options);
  }

  generarFicheroTextos(idioma: string, id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/generarFicheroTextos.php`, {
      idioma: idioma,
      id_sociedad: id_sociedad,
    }, options);
  }

  updateClausulas(id_clausula: string, clausula: string, id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearClausulas.php`, {
      id_clausula: id_clausula,
      clausula: clausula,
      id_sociedad: id_sociedad,
    }, options);
  }

  updateSSOParam(id_sociedad: string, saml_enabled: string, saml_idp_entity_id: string, saml_idp_sso_url: string, saml_idp_slo: string, saml_idp_cert: string, saml_idp_cert2: string, saml_mapping_cn: string, saml_mapping_sn: string, saml_mapping_email: string, saml_mapping_phone: string, saml_mapping_national_id: string, saml_jit: string, saml_slo: string, saml_force_login: string, saml_debug: string, saml_sp_entity_id: string, saml_sp_pk: string, saml_sp_cert: string, saml_sp_nameid_format: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearConfiguracionSSO.php`, {
      id_sociedad: id_sociedad,
      saml_enabled: saml_enabled,
      saml_idp_entity_id: saml_idp_entity_id,
      saml_idp_sso_url: saml_idp_sso_url,
      saml_idp_slo: saml_idp_slo,
      saml_idp_cert: saml_idp_cert,
      saml_idp_cert2: saml_idp_cert2,
      saml_mapping_cn: saml_mapping_cn,
      saml_mapping_sn: saml_mapping_sn,
      saml_mapping_email: saml_mapping_email,
      saml_mapping_phone: saml_mapping_phone,
      saml_mapping_national_id: saml_mapping_national_id,
      saml_jit: saml_jit,
      saml_slo: saml_slo,
      saml_force_login: saml_force_login,
      saml_debug: saml_debug,
      saml_sp_entity_id: saml_sp_entity_id,
      saml_sp_pk: saml_sp_pk,
      saml_sp_cert: saml_sp_cert,
      saml_sp_nameid_format: saml_sp_nameid_format,
    }, options)
  }

  updateIdioma(id_idioma: string, n_idioma: string, comentario: string, Habilitado: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarIdioma.php`, {
      id_idioma: id_idioma,
      n_idioma: n_idioma,
      comentario: comentario,
      Habilitado: Habilitado
    }, options);
  }


}
