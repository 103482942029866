import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnviarDenunciaService {

  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
  // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";
  constructor(private router: Router, private httpClient: HttpClient, private auth: AuthService) { }

  searchCompanies(idsociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getCompanies.php`, {
      idsociedad: idsociedad,
    }, options);
  }

  searchComplaintByUserSocietyID(id_sociedad: string, id_tp_denuncia: string, seguro: boolean, idioma: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTipoDenunciabyId.php`, {
      id_sociedad: id_sociedad,
      id_tp_denuncia: id_tp_denuncia,
      seguro: seguro,
      idioma: idioma,
    }, options);
  }

  searchCentersbyidCompany(idempresa: string, id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/Centros/getCentersbyidCompany.php`, {
      idempresa: idempresa,
      id_sociedad: id_sociedad
    }, options);
  }

  searchTypeComplaint(id_sociedad: string, seguro: boolean, idioma: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getTypeComplaint.php`, {
      id_sociedad: id_sociedad,
      seguro: seguro,
      idioma: idioma,

    }, options);
  }

  searchCenters(idsociedad: string) {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/Centros/getCenters.php`, {
      idsociedad: idsociedad,
    }, options);
  }

  searchCanal() {
    const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarCanal.php`, {
    }, options);
  }

}
