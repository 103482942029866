import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from '../model/datosDenuncia';
import { environment } from "../../environments/environment"
import { Historico } from '../model/historico';
import { Usuarios } from '../dashboard/models/usuarios';
import { DatePipe } from '@angular/common';
import { PlazosDenuncia } from '../model/plazosDenuncia';
import { Rol } from '../model/rol';

@Component({
  selector: 'app-enviar-informe-denunciante',
  templateUrl: './enviar-informe-denunciante.component.html',
  styleUrls: ['./enviar-informe-denunciante.component.css'],
  providers:[DatePipe]
})
export class EnviarinformeDenuncianteComponent implements OnInit {

  myForm: FormGroup;
  @Input() denuncia: DatosDenuncia;
  textoInforme: string;
  historico: Historico[];
  compliance: Usuarios[];
  contador = 0
  plazos: PlazosDenuncia[];
  roles: Rol[];

  constructor(private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService, private http: HttpClient, private datepipe: DatePipe) { }

  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.myForm = new FormGroup({
      textoInforme: new FormControl('', []),
    });
    this.textoInforme = this.denuncia?.[0].informe_denunciante;
  }

  get texto() {
    return this.myForm.get('textoInforme');
  }

  onKey(event){
    var datos = document.getElementById("textoInformeDenunciante") ;
    for(var i=0; i< 1; i++){
      var div = datos.getElementsByClassName('nw-editor__res')[i];
      this.contador = div.textContent.length
    }
  }

  enviarInforme(){
    if(this.textoInforme != ""){
      if(this.contador>4000){
        this.toastr.error(this.translate.instant('texto_266'), this.translate.instant('texto_243'));
      }else{
        this.apiService.sendReportByIdDenuncia(this.denuncia[0].id_denuncia,this.textoInforme,"COMPLIANCE_2", this.auth.getIdUser()).subscribe(
          res => {
            if(res == 200){
                this.toastr.success(this.translate.instant('texto_267'), this.translate.instant('texto_268'));
                //let role = this.auth.getRole();
                let role = "";
                this.apiService.searchRoleByUserSociety('setUser',this.auth.getSociedad(),'1').subscribe((roles: Rol[])=>{
                  this.roles = roles;
                  for(let i=0;i<this.roles.length;i++){
                    role = role + "," +this.roles[i].n_rol;
                  }
                if((role.includes("COMPLIANCE"))){
                  this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                    this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
                  });
                }else{
                  this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                      this.router.navigate(['dashboard/complaint-management/mis-denuncias']);
                  });
                }
              });
            }else{
              this.toastr.error(this.translate.instant('texto_269'), this.translate.instant('texto_268'));
            }
          });
        }
    }else{
      this.toastr.error(this.translate.instant('texto_272'), this.translate.instant('texto_268'));
    }
  }

}
