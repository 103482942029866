import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MedidaCautelarService {

    PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
    // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";
    constructor(private router: Router, private httpClient: HttpClient, private auth: AuthService) { }

    searchCautionByUserSociety(id_sociedad: string, idioma: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarMedidaCautelar.php`, {
            id_sociedad: id_sociedad,
            idioma: idioma
        }, options);
    }

    counMedidaCautelarByIdCompany(id_sociedad: string, id_mcu: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/contarMedidaCautelarById.php`, {
            id_sociedad: id_sociedad,
            id_mcu: id_mcu
        }, options);
    }

    deleteCautionByUser(id_sociedad: string, id_mcu: string, idioma: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarMedidaCautelar.php`, {
            id_sociedad: id_sociedad,
            id_mcu: id_mcu,
            idioma: idioma
        }, options);
    }

    updateCautionByUser(id_sociedad: string, id_mcu: string, nombre: string, comentario: string, idioma: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarMedidaCautelar.php`, {
            id_sociedad: id_sociedad,
            id_mcu: id_mcu,
            nombre: nombre,
            comentario: comentario,
            idioma: idioma
        }, options);
    }

    addCaution(id_sociedad: string, id_mcu: string, nombre: string, comentario: string, idioma: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearMedidaCautelar.php`, {
            id_sociedad: id_sociedad,
            id_mcu: id_mcu,
            nombre: nombre,
            comentario: comentario,
            idioma: idioma
        }, options);
    }
}


