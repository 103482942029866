
  <!-- End Navbar -->
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8">
          <div class="card">
            <div class="card-header card-header-primary" style="height: 3.75rem">
              <h4 class="card-title">{{sociedad}}</h4>
            </div>
            <div class="card-body">
        

              <div class="col-md-6">
                <label class="bmd-label-floating" for="form-user">{{ 'texto_91' | translate }}</label>
                <input readonly type="text" id="form-user" class="form-control" [formControl]="signupFormModalId" value="{{username}}" mdbInput mdbValidate>
                <mdb-error
                  *ngIf="signupFormModalId.invalid && (signupFormModalId.dirty || signupFormModalId.touched)">{{ 'texto_61' | translate }}
                </mdb-error>
                <mdb-success
                  *ngIf="signupFormModalId.valid && (signupFormModalId.dirty || signupFormModalId.touched)">{{ 'texto_62' | translate }}
                </mdb-success>
              </div>
      
              <div class="col-md-6">
                <label class="bmd-label-floating" for="form-email">Email</label>
                <input type="text" id="form-email" class="form-control" [formControl]="signupFormModalEmail" value="{{email}}" 
                       mdbInput mdbValidate>
              
                <mdb-error *ngIf="signupFormModalEmail.invalid && (signupFormModalEmail.dirty || signupFormModalEmail.touched)">
                  {{ 'texto_61' | translate }}
                </mdb-error>
                <mdb-success *ngIf="signupFormModalEmail.valid && (signupFormModalEmail.dirty || signupFormModalEmail.touched)">
                  {{ 'texto_62' | translate }}
                </mdb-success>
              </div>
  
              <div class="col-md-6">
                  <label class="bmd-label-floating" for="form-name">{{ 'texto_35' | translate }}</label>
                  <input type="text" id="form-name" class="form-control" [formControl]="signupFormModalName" value="{{name}}"
                         mdbInput mdbValidate>
                  <mdb-error *ngIf="signupFormModalName.invalid && (signupFormModalName.dirty || signupFormModalName.touched)">
                    {{ 'texto_61' | translate }}
                  </mdb-error>
                  <mdb-success *ngIf="signupFormModalName.valid && (signupFormModalName.dirty || signupFormModalName.touched)">
                    {{ 'texto_62' | translate }}
                  </mdb-success>
                </div>
  
                <div class="col-md-6">
                  <label class="bmd-label-floating" for="form-surname">{{ 'texto_36' | translate }}</label>
                  <input type="text" id="form-surname" class="form-control" [formControl]="signupFormModalSurname" value="{{surname}}"
                         mdbInput mdbValidate>
                
                  <mdb-error *ngIf="signupFormModalSurname.invalid && (signupFormModalSurname.dirty || signupFormModalSurname.touched)">
                    {{ 'texto_61' | translate }}
                  </mdb-error>
                  <mdb-success *ngIf="signupFormModalSurname.valid && (signupFormModalSurname.dirty || signupFormModalSurname.touched)">
                    {{ 'texto_62' | translate }}
                  </mdb-success>
                </div>

                <button (click)="actualizarDatos()" type = "submit"  class="btn btn-primary pull-right">{{ 'texto_59' | translate }}</button>
                <div class="clearfix"></div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-profile">
            <div class="card-avatar">
              <span>
                <img class="img" src="{{urlPhoto}}" />
              </span>
              <!--<a (click)="updateImgProfile()" href="#">
                <img class="img" src="{{urlPhoto}}" />
              </a> --> 
            </div>
            <div class="card-body">
              <h6 class="card-category text-gray">{{email}}</h6>
              <h4 class="card-title">{{name}} {{surname}}</h4>           
              <button (click)="enviarEmail(email)" type = "submit"  class="btn btn-primary col-md-12">{{ 'texto_119' | translate }}</button>
                <div class="clearfix"></div>
                <div *ngIf="is_lock==false; then thenBlock else elseBlock"></div>
                    <ng-template #thenBlock><button (click)="bloquearUsuario(username)" type = "submit"  class="btn btn-primary col-md-12">{{ 'texto_120' | translate }}</button></ng-template>
                    <ng-template #elseBlock><button (click)="desbloquearUsuario(username)" type = "submit"  class="btn btn-primary col-md-12">{{ 'texto_121' | translate }}</button></ng-template>

                <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </div>

        <div class="row">
          <div class="col-md-8">
            <div class="card">
              <div class="card-header card-header-primary" style="height: 3.75rem">
                <h4 class="card-title">{{ 'texto_122' | translate }}</h4>
              </div>
              
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table">
                      <thead>			
                          <th>{{ 'texto_123' | translate }}</th>
                          <th>N. {{ 'texto_123' | translate }}</th>
                          <th>ID. {{ 'texto_103' | translate }}</th>
                          <th>N. {{ 'texto_103' | translate }}</th>
                          <th>{{ 'texto_107' | translate }}</th>
                          <th>{{ 'texto_108' | translate }}</th>
                          <th></th>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of roles">
                          <td>{{ item.id_sociedad }}</td>
                          <td>{{ item.n_sociedad }}</td>
                          <td>{{ item.id_rol }}</td>
                          <td>{{ item.n_rol }}</td>
                          <td>{{ item.fec_inicio | date: 'dd/MM/yyyy'}}</td>
                          <td *ngIf="item.fec_fin != '0000-00-00' && item.fec_fin != '2150-01-01' ; else elseBlock">{{ item.fec_fin | date: 'dd/MM/yyyy'}}</td>
                            <ng-template #elseBlock><td>{{ 'texto_124' |translate }}</td></ng-template>
                          <td class="td-actions text-right">
                            <button (click)="showRol(item)" type="button" rel="tooltip" title="Edit Rol" class="" style="background: none;color:black;border:none;">
                              <i class="material-icons">edit</i>
                            </button>                             
                            <button (click)="deleteRole(item.id_rol, item.fec_inicio)" type="button" rel="tooltip" title="Remove" class="" style="background: none;color:red;border:none;">
                              <i class="material-icons">clear</i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  
                  <div [style.display]="IsHiddenForm ? 'block' : 'none'"   class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                    <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document">
                      <!--Content-->                      
                      <div class="modal-content" >
                        <div class="modal-body mx-3" style="padding-right: 1rem; padding-left: 1rem;">
                          <div class="card-header card-header-info">
                            <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_111' | translate }}</h5>
                          </div>                        
                          <button type="button" style="z-index:100;float: right;margin-top: -2.8rem;" class="btn btn-dark" data-dismiss="modal" (click) = "hideModal()"aria-label="Cerrar modal">X</button> 
                          <br>
                          <div class="md-form mb-12">
                            <input type="text"  class="form-control" [formControl]="signupFormModalNrol"
                                   mdbInput mdbValidate value={{n_rol}} readonly>
                            <label for="orangeForm-email">N. {{ 'texto_103' | translate }}</label>
                          </div>

                          <div class="md-form col-md-12">
                            <label for="fechaInicio">{{ 'texto_107' | translate }}</label><br>
                            <input type="text" class="form-control" [formControl]="fechaInicio"  value={{fec_inicio}} readonly>
                          </div>            
                          <div class="md-form col-md-12">
                              <label for="picker2">{{ 'texto_108' | translate }}</label><br>
                              <mat-form-field>
                                  <input matInput [matDatepicker]="picker2" [formControl]="fechaFin" format="YYYY-MM-DD" value={{fec_fin}} >
                                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                  <mat-datepicker #picker2></mat-datepicker>
                              </mat-form-field>
                          </div>

                        </div>              
                      
                        <div class="text-center mt-3"> 
                          <button (click)="updateRol(id_rol)"  mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_59' | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  
                  
                </div>
                <app-add-user-role></app-add-user-role>

            </div>
          </div>
      </div>
    </div>
  </div>



  