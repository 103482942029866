import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
  // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";
   constructor(private router: Router, private httpClient: HttpClient, private auth: AuthService) {}


  addCompany(id_sociedad:string, id_empresa:string, n_empresa:string, comentario:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearEmpresa.php`, {
      id_sociedad: id_sociedad,
      id_empresa: id_empresa,
      n_empresa: n_empresa,
      comentario: comentario
    },  options);
  }

  searchCompanyByUser(id_sociedad:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarEmpresaUsuario.php`, {
      id_sociedad: id_sociedad,
    },  options);
  }
  countComplaintByIdCompany(id_sociedad:string,id_empresa:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/contarDenunciasPorEmpresas.php`, {
      id_sociedad: id_sociedad,
      id_empresa: id_empresa
    }, options);
  }

  deleteCompanyByUser(id_sociedad:string, id_empresa:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarEmpresa.php`, {
      id_sociedad: id_sociedad,
      id_empresa: id_empresa,
    },  options);
  }
  updateCompanyByUser(id_sociedad:string, id_empresa:string, n_empresa:string, comentario:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarEmpresa.php`, {
      id_sociedad: id_sociedad,
      id_empresa: id_empresa,
      n_empresa: n_empresa,
      comentario: comentario
    },  options);
  }
}
