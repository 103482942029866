import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private navigateToSectionSource = new Subject<string>();
  navigateToSection$ = this.navigateToSectionSource.asObservable();

  private focusButtonSource = new Subject<void>();
  focusButton$ = this.focusButtonSource.asObservable();

  private focusUrlSupportSource = new Subject<void>();
  focusUrlSupport$ = this.focusUrlSupportSource.asObservable();

  navigateToSection(sectionId: string) {
    this.navigateToSectionSource.next(sectionId);
  }

  navigateToButton() {
    this.focusButtonSource.next();
  }

  navigateToUrlSupport() {
    this.focusUrlSupportSource.next();
  }
}
