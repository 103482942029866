import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../shared/services/api.service';
import { Rol } from '../model/rol';
import { OptionSesion } from '../model/optionSesion';
import { ToastrService } from 'ngx-toastr';
import { DatosDenuncia } from '../model/datosDenuncia';

/**
 * SHOW USER OPTIONS (PROFILE, OPTION, LOGOUT)
 */

@Component({
  selector: 'app-nav-fixed',
  templateUrl: './nav-fixed.component.html',
  styleUrls: ['./nav-fixed.component.css']
})
export class NavFixedComponent implements OnInit {
  roles: Rol[];
  roleName: string = "";
  nombreUsuario: string = "";
  nombreUsuario2: string = "";
  sociedadUsuario: string = "";
  option: OptionSesion[];
  menuNavbar: boolean = false;
  @Input() IsHiddenLanding: boolean;
  IsAuth = false;
  denuncia: DatosDenuncia[];
  numberDenuncias:number = 0;
  urlPhoto: string;

  constructor(private router: Router, private auth: AuthService,private apiService: ApiService, private translate: TranslateService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.IsAuth = this.auth.isLoggedIn();
    this.urlPhoto = this.auth.getPhotoUser();
    if(this.IsAuth){
      this.IsHiddenLanding = false;
    }else{
      this.IsHiddenLanding = true;
    }

    this.nombreUsuario = this.auth.getIdUser();

    if(this.auth.getIdUser() != null){
      this.apiService.getSessionPropertiesUser().subscribe((option: OptionSesion[])=>{
        this.option = option;
        if(option[0].id_sociedad != ""){
          this.auth.setLanguage(option[0].idioma);
          this.auth.setSociedad(option[0].id_sociedad);
          this.auth.setNameSociedad(option[0].n_sociedad);
          if(this.auth.getLanguage()=="en"){
            this.translate.setDefaultLang('en');
          }

          this.sociedadUsuario = this.auth.getNameSociedad();
          this.nombreUsuario2 = this.auth.getNameUser() + " " + this.auth.getSurnameUser();


          this.apiService.searchRoleByUserSociety('setUser',this.auth.getSociedad(),'1').subscribe((roles: Rol[])=>{
            this.roles = roles;
            for(let i=0;i<this.roles.length;i++){
              this.roleName = this.roleName + ","+ this.roles[i].n_rol;
            }
            let language = this.auth.getLanguage();
            if(language == "" || language == null){
              language = 'es';
            }
            this.translate.setDefaultLang(language);
            this.apiService.searchComplaintAviso(this.auth.getSociedad(),this.auth.getIdUser(),language).subscribe((denuncia: DatosDenuncia[])=>{
              this.denuncia = denuncia;
              this.numberDenuncias = this.denuncia.length;
            });
          });
        }else{
          this.toastr.error(this.translate.instant('texto_242'), this.translate.instant('texto_243"'));
        }
      });
    }
  }

  logout(){
    //console.log(localStorage.getItem("forzarloSSO"));
    if (localStorage.getItem("forzarloSSO") == "1"){
      this.apiService.logoutSingleSignOn(localStorage.getItem("session_index"),localStorage.getItem("nameid_value"),localStorage.getItem("nameid_format"));
    }else{
      this.auth.logoutSimple();
      window.location.reload();
    }
  }

  toggleSidebar(){
    this.menuNavbar = !this.menuNavbar;
  }

  goProfile(){
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/dashboard/user-options/profile']);
    });
  }
  goOption(){
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/dashboard/user-options/options']);
    });
  }
  goAvisos(){
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/dashboard/notifications/avisos']);
    });
  }

}
