import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from '../model/datosDenuncia';
import { environment } from "../../environments/environment"
import { Archivos } from '../data/formData.model';

@Component({
  selector: 'app-desestimar-denuncia',
  templateUrl: './desestimar-denuncia.component.html',
  styleUrls: ['./desestimar-denuncia.component.css']
})
export class DesestimarDenunciaComponent implements OnInit {
  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
  validatingForm: FormGroup;
  IsHiddenForm: boolean = false;
  HayFile: string = "0";
  @Input() denuncia: DatosDenuncia;
  contador = 0
  archivos: Archivos;
  files: any = [];
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  myForm = new FormGroup({
    file: new FormControl('', []),
    fileSource: new FormControl('', []),
    contactFormModalMessage: new FormControl('', []),
  });

  //List of allowed extensions.
  allowedExtensions = ['.eml', '.msg', '.pptx', '.pptm', '.potx', '.potm', '.ppam', '.ppsx', '.ppsm', '.xlsx', '.xlsm', '.xltx', '.xltm', '.xlam', '.gif', '.jpeg', '.jpg', '.png', '.pdf', '.txt', '.doc', '.docx'];

  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService, private http: HttpClient) { }

  ngOnInit() {
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingForm = new FormGroup({
      consulta: new FormControl('', []),
      textoDesestimacion: new FormControl('', [Validators.required, Validators.maxLength(10)])
    });
  }

  get consulta() {
    return this.validatingForm.get('consulta');
  }

  get textoDesestimacion() {
    return this.validatingForm.get('textoDesestimacion');
  }

  onKey(event) {
    for (var i = 0; i < 1; i++) {
      var div = document.getElementsByClassName('nw-editor__res')[i];
      this.contador = div.textContent.length
    }
  }

  addDesestimacion() {
    if (this.textoDesestimacion.value == "") {
      this.toastr.error(this.translate.instant('texto_286'), this.translate.instant('texto_243'));
    } else {
      /* if(this.contador>4000){
         this.toastr.error(this.translate.instant('texto_266'), this.translate.instant('texto_243'));
       }else{*/
      let estado = "8";
      if (this.consulta.value == true) {
        estado = "7";
      }

      if (this.myForm.get('fileSource').value != "") {
        this.subirFichero();
      }
      this.apiService.updateComplaintById(this.denuncia[0].id_denuncia, estado, this.auth.getSociedad(), this.textoDesestimacion.value, "desestimacion").subscribe(
        res => {
          if (res == 200) {
            this.toastr.success(this.translate.instant('texto_287'), this.translate.instant('texto_192'));

            let $clau = false;
            let clausulas_correo = localStorage.getItem("clausulas_correo");
            if (clausulas_correo == '1') {
              $clau = true;
            }
            //Ingresa si existe el correo del denunciante
            if (this.denuncia[0].denunciante_correo != "") {
              //console.log('ingreso');
              let url = environment.base + "/#/ver-denuncia/consultar-denuncia";
              let message =
                this.translate.instant('texto_576') + " " +
                this.denuncia[0].denunciante_nombre + " " +
                this.denuncia[0].denunciante_apellidos +
                this.translate.instant('texto_604') + " " +
                this.denuncia[0].id_denuncia + " " +
                this.translate.instant('texto_605') + " " +
                this.textoDesestimacion.value + "<br><br>";
              this.apiService.sendEmail(this.denuncia[0].denunciante_correo, this.translate.instant('texto_610') + this.denuncia[0].id_denuncia, message, url, $clau, this.translate.instant('texto_610')).subscribe(
                res => {
                  if (res == 200) {
                    if (estado == '7') {
                      //console.log('ingreso 2', estado);
                      // Ingresa si existe el correo de consultas
                      if (this.auth.getConsultas() != "") {
                        //Send Information to email consultas configured in admin panel
                        let message =
                          this.translate.instant('texto_606') +
                          this.denuncia[0].denunciante_nombre + " " +
                          this.denuncia[0].denunciante_apellidos +
                          this.translate.instant('texto_607') + " " +
                          this.denuncia[0].denuncia_desc + "<br><br>" +
                          this.translate.instant('texto_688') + '<br>' +
                          this.translate.instant('texto_632') + " " + this.denuncia[0].fecha_incidente + '<br>' +
                          this.translate.instant('texto_634') + " " + this.denuncia[0].descripcion_tipo_denuncia + '<br>' +
                          this.translate.instant('texto_633') + " " + this.denuncia[0].descripcion_relacion + '<br>' +
                          this.translate.instant('texto_64') + ": " + this.denuncia[0].nombre_empresa + '<br>' +
                          this.translate.instant('texto_636') + " " + this.denuncia[0].comentario + '<br><br>' +
                          this.translate.instant('texto_689') + '<br>' +
                          this.translate.instant('texto_629') + " " + this.denuncia[0].denunciante_correo + '<br>' +
                          //this.translate.instant('texto_628')+ " " +  this.denuncia[0].denunciante_dni + '<br>' +
                          this.translate.instant('texto_630') + " " + this.denuncia[0].denunciante_telefono + '<br><br>';
                        //console.log('datos del segundo if', message, 'getConsultas = ', this.auth.getConsultas());
                        //console.log(this.denuncia[0]);
                        this.apiService.sendEmail(this.auth.getConsultas(), this.translate.instant('texto_608') + this.denuncia[0].id_denuncia, message, '', $clau, this.translate.instant('texto_608')).subscribe(
                          res => {
                            if (res == 200) {
                              this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                            } else {
                              this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                            }
                          });
                      } else {
                        this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                      }
                    } else {
                      this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                    }
                  } else {
                    this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                  }
                });
            } else {
              //if information email from user exists
              if (estado == '7') {
                if (this.auth.getConsultas() != "") {
                  //send email from user data
                  let message =
                    this.translate.instant('texto_609') + " " + this.denuncia[0].denunciante_nombre + " " + this.denuncia[0].denunciante_apellidos + " " +
                    this.translate.instant('texto_607') + ": " + this.denuncia[0].denuncia_desc + '<br><br>' +
                    this.translate.instant('texto_688') + '<br>' +
                    this.translate.instant('texto_632') + " " + this.denuncia[0].fecha_incidente + '<br>' +
                    this.translate.instant('texto_634') + " " + this.denuncia[0].descripcion_tipo_denuncia + '<br>' +
                    this.translate.instant('texto_633') + " " + this.denuncia[0].descripcion_relacion + '<br>' +
                    this.translate.instant('texto_64') + ": " + this.denuncia[0].nombre_empresa + '<br>' +
                    this.translate.instant('texto_636') + " " + this.denuncia[0].comentario + '<br>' +
                    this.translate.instant('texto_605') + " " + this.textoDesestimacion.value + '<br><br>' +
                    this.translate.instant('texto_689') + '<br>' +
                    this.translate.instant('texto_629') + " " + this.denuncia[0].denunciante_correo + '<br>' +
                    //this.translate.instant('texto_628')+ " " +  this.denuncia[0].denunciante_dni + '<br>' +
                    this.translate.instant('texto_630') + " " + this.denuncia[0].denunciante_telefono + '<br><br>';
                  this.apiService.sendEmail(this.auth.getConsultas(), this.translate.instant('texto_608') + this.denuncia[0].id_denuncia, message, '', $clau, this.translate.instant('texto_608')).subscribe(
                    res => {
                      if (res == 200) {
                        this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                      } else {
                        this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                      }
                    });
                } else {
                  this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                }
              }
            }

          } else {
            this.toastr.error(this.translate.instant('texto_196'), this.translate.instant('texto_192'));
          }
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['dashboard/complaint-management/mis-denuncias']);
          });
        });
    }
  }

  subirFichero() {

    const formData = new FormData();
    formData.append('file', this.myForm.get('fileSource').value);
    formData.append('id_sociedad', this.denuncia[0].id_sociedad);
    formData.append('id_denuncia', this.denuncia[0].id_denuncia);
    formData.append('comentario', "Adjuntos a la desestimación");
    formData.append('id_usuario', "setUser");
    this.http.post(`${this.PHP_API_SERVER}/api/uploadDocument.php`, formData).subscribe(
      res => {
        if (res == 200) {
          this.toastr.success(this.translate.instant('texto_202'), this.translate.instant('texto_203'));
        } else {
          this.toastr.error(this.translate.instant('texto_204'), this.translate.instant('texto_203'));
        }
      },
      err => {
        this.toastr.error(this.translate.instant('texto_204'), this.translate.instant('texto_203'));
      })
  }


  /**
     * Function called to handle file upload.
     * It processes the selected files, checks their extensions, and performs validations.
     * 
     * @param event The change event triggered when files are selected.
     */
  uploadFile(event) {
    console.log("entró a upload fileeee");

    // Loop through each selected file.
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
      console.log("entró a upload file " + index);

      // Check if the file extension is allowed.
      const fileExtension = element.name.split('.').pop().toLowerCase();
      console.log(fileExtension);

      if (!this.allowedExtensions.includes('.' + fileExtension)) {
        // Alert if the file does not have an allowed extension.
        this.toastr.error(this.translate.instant('texto_181'));
        continue; // Continue to the next file
      }

      // Add the file to the list of files
      this.files.push(element.name);

      if (this.files.length > 1) {
        // If more than one file is selected, show an error message
        this.files.pop(element.name);
        this.toastr.error(this.translate.instant('texto_334'), this.translate.instant('texto_203'));
      } else {
        if (element.size / 1000000 > 16) {
          // If the file size exceeds the limit, show an alert.
          this.files.pop(element.name);
          alert(this.translate.instant('texto_333'));
        } else {
          // Call the function to handle file change.
          this.onFileChange(event);
        }
      }
    }
  }

  /**
   * Function similar to uploadFile, is called when a file is upload by drag and drop
   * It processes the selected files, checks their extensions, and performs validations.
   * 
   * @param files The change event triggered when files are selected.
   */
  dragFile(files: FileList) {
    // Loop through each selected file.
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
  
      // Check if the file extension is allowed.
      const fileExtension = element.name.split('.').pop().toLowerCase();
  
      if (!this.allowedExtensions.includes('.' + fileExtension)) {
        // Alert if the file does not have an allowed extension.
        this.toastr.error(this.translate.instant('texto_181'));
        continue;
      }
  
      // Check if more than one file is selected.
      if (this.files.length > 0) {
        // Show an error message.
        this.toastr.error(this.translate.instant('texto_334'), this.translate.instant('texto_203'));
        break;
      }
  
      // Check if the file size exceeds the limit.
      if (element.size / 1000000 > 20) {
        // Show an alert for exceeding file size limit.
        this.toastr.error(this.translate.instant('texto_333'));
        continue;
      }
  
      // Call the function to handle file change.
      this.onFileChangeDrag(files);
  
      // Add the file to the list of files.
      this.files.push(element.name);
    }
  }

  /**
  * Function called when the user change the file.
  * It updates the value of a field in the reactive form with the information of the selected file.
  * Also, it performs validation to ensure that the selected file has an allowed extension.
  * 
  * @param event The change event triggered when the user selects a file.
  */
  onFileChange(event) {
    console.log("entró a on file change");
    // Check if files are selected.
    if (event.target.files.length > 0) {
      // Get the first file from the list of selected files.
      const file = event.target.files[0];

      // Check if the file has an allowed extension.
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!this.allowedExtensions.includes('.' + fileExtension)) {
        // Display error message if the extension is not allowed.
        this.toastr.error(this.translate.instant('texto_181'));
        return;
      }

      // Update the value of the field in the reactive form with the information of the selected file.
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }

  /**
  * Function similar to onFileChange, is called when a file is changed by drag and drop.
  * It updates the value of a field in the reactive form with the information of the selected file.
  * Also, it performs validation to ensure that the selected file has an allowed extension.
  * 
  * @param files The change event triggered when the user selects a file.
  */
  onFileChangeDrag(files: FileList) {
    // Check if files are selected.
    if (files.length > 0) {
      // Get the first file from the list of selected files.
      const file = files[0];

      // Update the value of the field in the reactive form with the information of the selected file.
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }



  deleteAttachment(index) {
    //this.archivos.nombreArchivo =  "";
    this.files.splice(index, 1)
    const formData = new FormData();
    let file = this.myForm.get('fileSource').value;

    formData.append('file', file);
    formData.append('id_sociedad', this.denuncia[0].id_sociedad);
    formData.append('id_denuncia', this.denuncia[0].id_denuncia);
    //formData.append('comentario', this.contactFormModalMessage.value);
    formData.append('id_usuario', this.denuncia[0].denunciante_correo)
    // this.http.post(`${this.PHP_API_SERVER}/api/deleteDocument.php`, formData).subscribe(
    //   res => {
    //     if(res == 200){
    //       this.toastr.success(this.translate.instant('texto_332'), this.translate.instant('texto_203'));
    //     }else{
    //       this.toastr.error(this.translate.instant('texto_331'), this.translate.instant('texto_203'));
    //     }
    // },
    // err =>{
    //   this.toastr.error(this.translate.instant('texto_331'), this.translate.instant('texto_203'));
    // })
  }
}
