import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-politica-cookies',
  templateUrl: './politica-cookies.component.html',
  styleUrls: ['./politica-cookies.component.scss'],
})
export class PoliticaCookiesComponent implements OnInit {
  constructor(private translate: TranslateService, private auth: AuthService,) {}

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    //Loaded Script By Cookies
    let body = document.getElementById('box');
    let script = document.createElement('script');
    script.id = 'CookieDeclaration';
    script.src = 'https://consent.cookiebot.com/744f7958-942c-4d41-a79f-e60fe7b1c617/cd.js';
    script.type = 'text/javascript';
    script.async = true;

    //Error handling
    script.onerror = () => {
      console.log('Error occurred while loading script');
    };

    //Insert Script in Body
    body.appendChild(script);
  }

  hide() {
    window.location.reload();
  }
}
