import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DatosDenuncia } from 'src/app/model/datosDenuncia';
import { EnviarDenunciaService } from 'src/app/enviar-denuncia/services/enviar-denuncia.service';

/**
 * This Component Edits The Type Complaint Only With 'Gestor' = 11 OR 1 Role.
 */

@Component({
  selector: 'app-change-type-complaint',
  templateUrl: './change-type-complaint.component.html',
  styleUrls: ['./change-type-complaint.component.css']
})
export class ChangeTypeComplaintComponent implements OnInit {
  validatingForm: FormGroup; //validation forms
  typecomplaints: String; //model tye complaint
  idComplaintUrl: string //url id
  @Input() denuncia: DatosDenuncia; //data complaint

  constructor(
    private actRoute: ActivatedRoute,
    private apiService: ApiService,
    private enviarDenunciaService: EnviarDenunciaService,
    private auth: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService) { }


  ngOnInit(): void {
    //translation language
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.idComplaintUrl = this.actRoute.snapshot.params.id; //id Complaint

    //validations form
    this.validatingForm = new FormGroup({
      typecomplaint : new FormControl('', [Validators.required]),
    });

    //get type complaint from database
    this.enviarDenunciaService.searchTypeComplaint(this.auth.getSociedad(),false,language).subscribe((typecomplaint: String)=>{
      this.typecomplaints = typecomplaint;
    });

  }

  //get value type complaint
  get typecomplaint() {
    return this.validatingForm.get('typecomplaint');
  }

  //method edit complaint
  editComplaint(){
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    //method update complaint by type complaint
    this.apiService.updateComplainType(this.auth.getSociedad(), this.typecomplaint.value, this.denuncia[0].id_denuncia, this.auth.getIdUser(),language).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_236'), this.translate.instant('texto_238'));
        }else{
          this.toastr.error(this.translate.instant('texto_237'), this.translate.instant('texto_238'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['editar-denuncia/', this.idComplaintUrl]);
        });
    });
  }
}
