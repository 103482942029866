<section style="position: absolute;width: 100%;z-index: 1;" class="image-container"
  style="background-image: url(assets/img/wizard/wizard-1.jpg);">
  <br>
  <br>
  <div class="container" style="padding: 0;">
    <div class="board" style="width: 100% !important; height: auto;">
      <button type="button" style="z-index:100;float: right;" class="btn btn-dark" (click)="hide();"aria-label="Cerrar modal">X</button>
      <div class="tab-content">
        <div class="tab-pane fade in active">
          <h4 class="head text-center">{{ 'texto_687' | translate }}</h4>
          <div class='row'>
            <div class='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8'>
              <div class="row">
                <!--SCRIPT LOAD COOKIES-->
                <div class='cookies col-xs-12 col-sm-12' id="box">
                </div>
              </div>
            </div>
          </div>
          <br>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
</section>