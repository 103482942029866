
<button style="float:right;" class="btn btn-primary " type="button" mdbBtn  rounded="true" data-toggle="modal" data-target="#basicExample"
        (click)="frame.show()" mdbWavesEffect>{{ 'texto_60' | translate }}
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document">
    <!--Content-->
    <div class="modal-content" >

        <div class="modal-body mx-3" style="padding-right: 1rem; padding-left: 1rem;">
            <div class="card-header card-header-info">
              <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_58' | translate }}</h5>
          </div>
          <button type="button" style="z-index:100;float: right;margin-top: -2.8rem;" class="btn btn-dark" data-dismiss="modal" (click) = "frame.hide()"aria-label="Cerrar modal">X</button>

          <div class="row">
            <div class="md-form mb-3 col-md-12">
              <input type="text" id="orangeForm-email" class="form-control" [formControl]="signupFormModalId"
                     mdbInput mdbValidate>
              <label for="orangeForm-email">ID. </label>
              <mdb-error
                *ngIf="signupFormModalId.invalid && (signupFormModalId.dirty || signupFormModalId.touched)">{{ 'texto_61' | translate }}
              </mdb-error>
              <mdb-success
                *ngIf="signupFormModalId.valid && (signupFormModalId.dirty || signupFormModalId.touched)">{{ 'texto_62' | translate }}
              </mdb-success>
            </div>

            <div class="md-form mb-3  col-md-12">
              <input type="text" id="orangeForm-name" class="form-control" [formControl]="signupFormModalName"
                     mdbInput mdbValidate maxlength="255">
              <label for="orangeForm-name">{{ 'texto_44' | translate }}</label>
              <mdb-error *ngIf="signupFormModalName.invalid && (signupFormModalName.dirty || signupFormModalName.touched)">
                {{ 'texto_61' | translate }}
              </mdb-error>
              <mdb-success *ngIf="signupFormModalName.valid && (signupFormModalName.dirty || signupFormModalName.touched)">
                {{ 'texto_62' | translate }}
              </mdb-success>
            </div>
          </div>

          <div class="row">
            <div class="md-form mb-3 col-md-12">
                <input type="text" id="form-email" class="form-control" [formControl]="signupFormModalEmail"
                       mdbInput mdbValidate>
                <label for="form-email">Email</label>
                <mdb-error *ngIf="signupFormModalEmail.invalid && (signupFormModalEmail.dirty || signupFormModalEmail.touched)">
                  {{ 'texto_61' | translate }}
                </mdb-error>
                <mdb-success *ngIf="signupFormModalEmail.valid && (signupFormModalEmail.dirty || signupFormModalEmail.touched)">
                  {{ 'texto_62' | translate }}
                </mdb-success>
            </div>

            <!--<div class="md-form mb-3 col-md-6">
                <label for="form-email">{{ 'texto_71' | translate }}</label>
                <mat-form-field>
                    <mat-select  [formControl]="selectType">
                      <mat-option *ngFor="let tipo of denuncias" [value]="tipo.id_tp_denuncia">
                        {{tipo.nombre}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>-->
          </div>

            <div class="md-form">
                <label for="form-email">{{ 'texto_46' | translate }}</label>
                <mdb-icon fas icon="pencil-alt" class="prefix grey-text" style="margin-top: 10%;"></mdb-icon>
                <textarea maxlength="4000" type="text" id="form107Modal" class="md-textarea form-control" rows="5" mdbInput [formControl]="contactFormModalMessage" ></textarea>
              </div>
           </div>


        <div class="text-center mt-3">
          <button (click)="addAbogado()"  mdbBtn  rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_60' | translate }}
          </button>
        </div>

    </div>
    <!--/.Content-->
  </div>
</div>
