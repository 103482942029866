import { Component, Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from 'src/app/ver-denuncias/models/datosDenuncia';
import { Testigo } from 'src/app/ver-denuncias/models/testigo';
import { Documento } from 'src/app/ver-denuncias/models/documento';
import { Comentario } from 'src/app/ver-denuncias/models/comentario';
import { Usuarios } from 'src/app/ver-denuncias/models/usuarios';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { VerDenunciaService } from 'src/app/ver-denuncias/services/ver-denuncia.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { PeertopeerService } from 'src/app/shared/services/peertopeer.service';
import * as CryptoJS from 'crypto-js';

@Injectable()
@Component({
  selector: 'app-ver-denuncia',
  templateUrl: './ver-denuncia.component.html',
  styleUrls: ['./ver-denuncia.component.css'],
  providers: [DatePipe],
})
export class VerDenunciaComponent implements OnInit {
  validatingForm: FormGroup;
  audioBlobUrl2: any; //audio recorder
  audioBlob: any; //audio recorder
  audioName = ''; //audio recorder
  isAudioRecording = false; //boton de stop
  audioRecordedTime = ''; //audio time
  pin_code: string;
  denuncia: DatosDenuncia[];
  testigo: Testigo[];
  documento: Documento[];
  bExisteTestigo = true;
  bExisteDocumento = true;
  id: string;
  comentarios: Comentario[];
  avatar: string;
  showChat: boolean = false;
  show: boolean = true;
  progressBar: number = 0;
  backgroundColor: string = 'red';
  ipAddress = '';
  sendChat: boolean = false;
  usuario_denuncia: Usuarios[];
  archivo: Documento[];
  showCenter: boolean = false;
  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";

  constructor(
    private verDenunciaService: VerDenunciaService,
    private router: Router,
    private auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private actRoute: ActivatedRoute,
    private datepipe: DatePipe,
    private http: HttpClient,
    private spinnerSvc: SpinnerService,
    private cryptoService: PeertopeerService
  ) { }

  ngOnInit() {

    let language = this.auth.getLanguage();
    if (language == '' || language == null) {
      language = 'es';
    }
    this.id = this.actRoute.snapshot.params.id;

    this.validatingForm = new FormGroup({
      comentarioDenuncia: new FormControl(''),
    });

    //Activa el indicador para consultar la tabla QSC_CENTROS
    let isActiveCenter = '0';
    if (this.auth.getCentros() == '1') {
      isActiveCenter = '1';
      this.showCenter = true;
    }
    this.avatar = environment.baseAvatar + 'social.png';
    this.translate.setDefaultLang(language);
    this.pin_code = this.actRoute.snapshot.params.id;
    this.verDenunciaService
      .searchComplaintById(
        this.pin_code,
        language,
        this.auth.getIdUser(),
        isActiveCenter
      )
      .subscribe((denuncia: DatosDenuncia[]) => {
        this.denuncia = denuncia;

        if (denuncia.length == 0) {
          // this.getIPAddress();
          // console.log('No se encontraron denuncias');
          this.toastr.error(
            this.translate.instant('texto_189'),
            this.translate.instant('texto_190')
          );
          this.router
            .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(['/ver-denuncia/', this.id]);
            });
        } else {
          let indice = localStorage.getItem('verdenuncia');
          if (indice == '0') {
            this.router.navigate(['/ver-denuncia/consultar-denuncia']);
          } else {
            localStorage.setItem('verdenuncia', '1');
          }
          if (this.denuncia[0].id_fase == '0') {
            this.progressBar = 10;
            this.sendChat = true;
          } else if (this.denuncia[0].id_fase == '1') {
            this.progressBar = 15;
            this.showChat = true;
            this.backgroundColor = 'red';
            this.sendChat = true;
          } else if (this.denuncia[0].id_fase == '11') {
            this.progressBar = 15;
            this.showChat = true;
            this.backgroundColor = 'red';
            this.sendChat = true;
          } else if (this.denuncia[0].id_fase == '2') {
            this.progressBar = 50;
            this.showChat = true;
            this.backgroundColor = '#F1C453';
            this.sendChat = true;
          } else if (this.denuncia[0].id_fase == '3') {
            this.showChat = true;
            this.progressBar = 65;
            this.backgroundColor = '#EFEA5A';
            this.sendChat = true;
          } else if (this.denuncia[0].id_fase == '4') {
            this.progressBar = 75;
            this.backgroundColor = '#B9E769';
            this.show = false;
          } else if (this.denuncia[0].id_fase == '5') {
            this.progressBar = 85;
            this.backgroundColor = '#83E377';
            this.show = false;
          } else if (this.denuncia[0].id_fase == '6') {
            this.progressBar = 100;
            this.backgroundColor = '#16DB93';
            this.show = false;
          }

          let denunciante = this.denuncia[0].denunciante_correo;
          if (denunciante == '') {
            denunciante = 'Denunciante';
          }
          this.verDenunciaService
            .searchWitnessByUserComplaint(
              this.denuncia[0].id_denuncia,
              denunciante
            )
            .subscribe((testigo: Testigo[]) => {
              this.testigo = testigo;
              if (testigo.length == 0) {
                this.bExisteTestigo = false;
              }
            });

          this.verDenunciaService
            .searchDocumentsByUserComplaint(
              this.denuncia[0].id_denuncia,
              denunciante
            )
            .subscribe((documento: Documento[]) => {
              this.documento = documento;
              if (documento.length == 0) {
                this.bExisteDocumento = false;
              }
            });
        }

        this.verDenunciaService
          .searchCommentsByComplaint(this.denuncia[0].id_denuncia, false)
          .subscribe((comentario: Comentario[]) => {
            this.comentarios = comentario;
            if (this.comentarios.length > 0) {
              this.showChat = true;
            }
          });
      });
  }

  /**
* Method for navigating to "boton1" after navigating to a section "services".
*/
  navigateToButton() {
    // Navigate to the 'services' section first
    // this.navigationService.navigateToSection('services');
    const element = document.getElementById('mainContent');

    setTimeout(() => {
      // After a delay, invoke the navigateToButton method of the NavigationService to focus the button.
      if (element) {
        element.focus();
      }
    }, 500); // Wait for a specified time to ensure the navigation is complete before focusing the button.
  }

  get comentarioDenuncia() {
    return this.validatingForm.get('comentarioDenuncia');
  }


  /**
  * Function to send a comment and notifies all related users via email.
  *
  * @param {number} id_denuncia - The ID of the complaint.
  */
  enviarMensaje(id_denuncia) {
    // Get the current date and time, formatted as 'yyyy-MM-dd HH:mm:ss'.
    let date = new Date();
    let _date = this.datepipe.transform(date, 'yyyy-MM-dd HH:mm:ss');

    // Determine the name to use in the message, either the complainant's name or user ID.
    let nombre = this.denuncia[0].denunciante_nombre != '' ? this.denuncia[0].denunciante_nombre : this.denuncia[0].id_usuario;

    // Get the comment from the complaint, stripping any HTML tags.
    let comentario = this.comentarioDenuncia.value.replace(/<[^>]*>/g, '');

    if (comentario) {
      // Replace any single quotes in the comment to prevent SQL injection.
      comentario = comentario.replace(/'/g, '');

      // Check if the complaint exists and the complaint ID is valid.
      if (this.denuncia && this.denuncia.length > 0 && id_denuncia) {
        //encrypt data
        const encryIdSociedad = this.cryptoService.encryptData(this.denuncia[0].id_denuncia);
        const encryIdDenuncia = this.cryptoService.encryptData(id_denuncia);
        const encrytNombre = this.cryptoService.encryptData(nombre);
        const encryComentario = this.cryptoService.encryptData(comentario);
        const encryDate = this.cryptoService.encryptData(_date);
        const encryIdUsuarioIdentificador = this.cryptoService.encryptData('Denunciante');

        // Send a message to the chat service associated with the complaint.
        this.verDenunciaService.sendMessageChat(encryIdSociedad, encryIdDenuncia, encrytNombre, encryComentario, encryDate, encryIdUsuarioIdentificador, false)
          .subscribe((res) => {
            if (res == 200) {
              // Retrieve the list of users related to the complaint.
              this.verDenunciaService.buscarUsuarioDenuncia(id_denuncia, false)
                .subscribe((usuarios: Usuarios[]) => {
                  this.usuario_denuncia = usuarios;
                  if (usuarios.length > 0) {
                    // Check if email clauses are enabled in local storage.
                    let $clau = localStorage.getItem('clausulas_correo') == '1';
                    // Construct the URL for editing the complaint.
                    let url = environment.base + '/editar-denuncia/' + this.denuncia[0].id_denuncia;
                    //let url = 'http://localhost:4200/editar-denuncia/' + this.denuncia[0].id_denuncia;

                    // Loop through each user and send an email notification.
                    usuarios.forEach(usuario => {
                      let message = `${this.translate.instant('texto_576')} ${usuario.n_user} ${usuario.ape_user}${this.translate.instant('texto_652')} ${this.denuncia[0].id_denuncia}${this.translate.instant('texto_653')}`;
                      this.verDenunciaService.sendEmail(
                        usuario.mail_user,
                        `${this.translate.instant('texto_654')} ${this.denuncia[0].id_denuncia}`,
                        message,
                        url,
                        $clau,
                        this.translate.instant('texto_654')
                      ).subscribe((emailRes) => {
                        if (emailRes != 200) {
                          this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                        }
                      });
                    });

                    // Show a success notification and clear the local storage entry for 'verdenuncia'.
                    this.toastr.success(this.translate.instant('texto_363'), this.translate.instant('texto_364'));
                    localStorage.setItem('verdenuncia', '');
                  }
                });
            } else {
              // Show an error notification if the chat message could not be sent.
              this.toastr.error('texto_29', 'texto_364');
            }
            // Refresh the component and navigate to the complaint view.
            this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true })
              .then(() => {
                this.router.navigate(['ver-denuncia/', this.id]);
              });
          });
      }
    } else {
      // Show an error notification if the comment is empty.
      this.toastr.error(this.translate.instant('texto_481'), this.translate.instant('texto_484'));
    }
  }

  getIPAddress() {
    this.http.get('http://api.ipify.org/?format=json').subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  logout() {
    this.auth.logoutSimple();
    //window.location.reload();
  }

  /**
   * Function to download a file based on its ID, name, and type.
   * @param id_archivo The ID of the file.
   * @param n_archivo The name of the file.
   * @param tipo_archivo The type of the file.
   */
  verArchivo(id_archivo: string, n_archivo: string, tipo_archivo: string) {
    let linkSource = '';
    let mimeType = '';

    //spinner.
    //this.spinnerSvc.show();
    this.toastr.info(this.translate.instant('texto_723'));

    //determine the file type.
    switch (tipo_archivo) {
      case 'pdf':
        mimeType = 'application/pdf';
        break;
      case 'jpe':
      case 'jpeg':
      case 'jpg':
        mimeType = 'image/jpeg';
        break;
      case 'doc':
      case 'docx':
        mimeType = 'application/msword';
        break;
      case 'txt':
      case 'bas':
      case 'c':
      case 'h':
        mimeType = 'text/plain';
        break;
      case 'xls':
      case 'xlsx':
        mimeType = 'application/vnd.ms-excel';
        break;
      case 'msg':
      case 'eml':
        mimeType = 'application/vnd.ms-outlook';
        break;
      case 'mp4':
        mimeType = 'video/mp4';
        break;
      case 'zip':
        mimeType = 'application/zip';
        break;
      case 'mp3':
        mimeType = 'audio/mpeg';
        break;
      case 'htm':
      case 'html':
      case 'stm':
        mimeType = 'text/html';
        break;
      case 'mp2':
      case 'mpa':
      case 'mpe':
      case 'mpeg':
      case 'mpg':
      case 'mpv2':
        mimeType = 'audio/mpeg';
        break;
      case 'gif':
        mimeType = 'image/gif';
        break;
      case 'png':
        mimeType = 'image/png';
        break;
      default:
        this.toastr.error(this.translate.instant('texto_181'));
        return;
    }


    this.verDenunciaService.verArchivo(id_archivo).subscribe(
      (data) => {

        // constructs a data URL for the file using the mimeType and the fetched file data.
        linkSource = 'data:' + mimeType + ';base64,' + data;
        const downloadLink = document.createElement('a');
        const fileName = n_archivo;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.target = '_blank';
        downloadLink.click();
        downloadLink.remove();
        //this.spinnerSvc.hide();
        this.toastr.clear();
        this.toastr.success(this.translate.instant('texto_724'));

      },
      (error) => {
        this.toastr.error(this.translate.instant('texto_204'));
        this.spinnerSvc.hide();

      }
    );
  }

}
