import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Users } from '../../../model/users';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../../services/login.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  users:  Users;
  email: string;
  password: string;
  HabilitarSSO: boolean = false;
  loginOk: boolean = false;
  loginOkBlock: boolean = false;
  loginNoUser: boolean = false;
  language: string;
  incorrectPassword: boolean = false;
  //sended2FactorEmail: boolean = false;

  @ViewChild("myModalInfo", {static: false}) myModalInfo: TemplateRef<any>;

  constructor(
    private titleService: Title,
    private modalService: NgbModal,
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {

    // Set title based on selected language
    this.translate.get('texto_252', { value: this.language }).subscribe((text: string) => {
      this.titleService.setTitle(text);
    });
  }

  validatingForm: FormGroup;
  ngOnInit() {
    if (this.language == "" || this.language == null) {
      this.language = 'es';
    }
    this.translate.setDefaultLang(this.language);
    // TODO: No veo implementado nada de single sign on
    if (localStorage.getItem("HabSSO") == "1") {
      this.HabilitarSSO = true;
    }else{
      this.HabilitarSSO = false;
    }
    this.validatingForm = new FormGroup({
      loginFormModalEmail: new FormControl('', [
        Validators.email,
        Validators.required,
      ]),
      loginFormModalPassword: new FormControl('', [Validators.required])
    });
  }

   /**
  * Method for navigating to "boton1" after navigating to a section "services".
  */
   navigateToButton() {
    // Navigate to the 'services' section first
   // this.navigationService.navigateToSection('services');
   const element = document.getElementById('mainContent');

    setTimeout(() => {
      // After a delay, invoke the navigateToButton method of the NavigationService to focus the button.
      if (element) {
        element.focus();
      }
    }, 500); // Wait for a specified time to ensure the navigation is complete before focusing the button.
  }

  login() {
    console.log("Valor de HabilitarSSO: LOGIN", this.HabilitarSSO);

    this.loginService.login(this.loginFormModalEmail.value, this.loginFormModalPassword.value)
      .subscribe({
        next: () => {
          //this.sended2FactorEmail = true;
          this.toastr.info(
            this.translate.instant('texto_715'),
            this.translate.instant('texto_194'),{
              timeOut: 0,  // 0 para que no se cierre automáticamente
              closeButton: true,  // Puedes agregar un botón de cierre si lo deseas
          });

          //variables for message hidde when login is successfull
          this.loginOk = false;
          this.loginNoUser = false;
          this.loginOkBlock = false;
          this.incorrectPassword =false;
        },
        error: (errorResponse) => {
          if (errorResponse.status === 401) {
            if (errorResponse.error.message == "Password Incorrect"){
              this.showIncorrectPassword();
            } else if (errorResponse.error.message == "User incorrect"){
              this.showInvalidUserError();
            } else {
              this.showNotUserError();
            }
          }
          if (errorResponse.status === 423) {
            this.showUserBlockedError();
          }
        }
      });
  }

  private showNotUserError() {
    this.loginOk = false;
    this.loginNoUser = true;
    this.loginOkBlock = false;
    this.incorrectPassword =false;
  }

  private showInvalidUserError() {
    this.loginOk = false;
    this.loginNoUser = true;
    this.loginOkBlock = false;
    this.incorrectPassword =false;
  }

  private showUserBlockedError() {
    this.loginOk = false;
    this.loginNoUser = false;
    this.loginOkBlock = true;
    this.incorrectPassword =false;
  }

  /**
   * Method for show message incorrect password
   */
  private showIncorrectPassword() {
    this.loginOk = false;
    this.loginNoUser = false;
    this.loginOkBlock = false;
    this.incorrectPassword = true;
  }

  login2(){
    console.log("Invocado login2(). Valor de HabilitarSSO:", this.HabilitarSSO);
    this.loginService.loginSingleSignOn();
  }

  get loginFormModalEmail() {
    return this.validatingForm.get('loginFormModalEmail');
  }

  get loginFormModalPassword() {
    return this.validatingForm.get('loginFormModalPassword');
  }


}
