
  <!-- End Navbar -->
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header card-header-primary" style="height: 3.75rem">
                  <h4 class="card-title ">{{sociedad}}</h4>
¡                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <th></th>
                            <th>{{ 'texto_91' | translate }}</th>
                            <th>{{ 'texto_44' | translate }}</th>
                            <th>{{ 'texto_158' | translate }}</th>
                            <th>{{ 'texto_159' | translate }}</th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of users">
                            <td *ngIf="item.foto != null ; else elseBlock">
                              <img src="{{item.foto}}" alt="Avatar" height="42" width="42">
                            </td>
                          <ng-template #elseBlock>
                            <td><img src="{{avatar}}" height="42" width="42"></td>
                          </ng-template>

                            <td>{{item.id_user}}</td>         
                            <td>{{item.n_user}} {{item.ape_user}}</td>
                            <td><button (click)="editarUsuario(item.id_user)" type="button" rel="tooltip" title="Edit Task" class="" style="background: none;color:black;border:none;">
                                <i class="material-icons">how_to_reg</i>
                              </button></td>
                            <td>
                              <button (click)="eliminarUsuario(item.id_user)" type="button" rel="tooltip" title="Remove" class="" style="background: none;color:red;border:none;">
                                <i class="material-icons">clear</i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <app-add-user></app-add-user>
                  </div>
                </div>
              </div>
            </div>
      </div>
    </div>
  </div>
