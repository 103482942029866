import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common'

import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { GestionUsuariosService } from 'src/app/dashboard/services/gestion-usuarios.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Empresa } from 'src/app/dashboard/models/empresa';
import { Roles } from 'src/app/dashboard/models/roles';
@Component({
  selector: 'app-add-user-role',
  templateUrl: './add-user-role.component.html',
  styleUrls: ['./add-user-role.component.css'],
  providers: [
    DatePipe,
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class AddUserRoleComponent implements OnInit {
  validatingForm: FormGroup;
  sociedad: string;
  empresas: Empresa[];
  roles: Roles[];
  selectedEmpresa: string;
  selectedRole: string;
  id: string;
  constructor(private apiService: GestionUsuariosService, private auth: AuthService, private toastr: ToastrService, private router: Router, private actRoute: ActivatedRoute, private translate: TranslateService, private datepipe: DatePipe) { }

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    this.sociedad = this.auth.getSociedad();
    this.id = this.actRoute.snapshot.params.id;
   /* this.apiService.searchCompanyByUser(this.auth.getIdUser(),this.sociedad).subscribe((empresas: Empresa[])=>{
      this.empresas = empresas;
    });*/

    this.validatingForm = new FormGroup({
     // selectEmpresa: new FormControl('', [Validators.required]),
      selectRole: new FormControl('', [Validators.required]),
      fechaInicio: new FormControl('', [Validators.required]),
      fechaFin: new FormControl('', [
      ]),

    });
    this.apiService.searchRole().subscribe((roles: Roles[])=>{
        this.roles = roles;
    });
  }

 /* get selectEmpresa() {
    return this.validatingForm.get('selectEmpresa');
  }*/

  get selectRole() {
    return this.validatingForm.get('selectRole');
  }

  get fechaInicio() {
    return this.validatingForm.get('fechaInicio');
  }

  get fechaFin() {
    return this.validatingForm.get('fechaFin');
  }

  addRole(){
    let fechaFinValue = "2150-01-01";
    if(this.fechaFin.value != ""){
     // fechaFinValue = this.fechaFin.value;
      fechaFinValue = this.datepipe.transform(this.fechaFin.value, 'yyyy-MM-dd');
    }
    let fechaInicioValue = this.datepipe.transform(this.fechaInicio.value, 'yyyy-MM-dd');
    let re = /\-/gi;

    let FF =  fechaFinValue.replace(re, "");
    let FI =  fechaInicioValue.replace(re, "");
    if(Number(FI) > Number(FF)){
      this.toastr.error(this.translate.instant('texto_307'), this.translate.instant('texto_310'));
    }else{
      this.apiService.addRoleByIdUser(this.id, this.auth.getSociedad(), this.selectRole.value, fechaInicioValue, fechaFinValue).subscribe(
        res => {
          if(res == 200){
            this.toastr.success(this.translate.instant('texto_308'), this.translate.instant('texto_310'));
          }else{
            this.toastr.error(this.translate.instant('texto_309'), this.translate.instant('texto_310'));
          }
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/dashboard/layout/user/',this.id]);
          });
      },
      err =>{
        this.toastr.error(this.translate.instant('texto_309'), this.translate.instant('texto_310'));
      });
    }

  }

}
