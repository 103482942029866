  <!-- End Navbar -->
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header card-header-primary" style="height: 3.75rem">
                  <h4 class="card-title ">{{sociedad}}</h4>
¡                </div>

                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <th>{{ 'texto_43'  | translate }}</th>
                            <th>{{ 'texto_44'  | translate }}</th>
                            <th>{{ 'texto_38' | translate }}</th>
                            <th>{{ 'texto_46' | translate }}</th>
                            <th></th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of abogados; let i = index">
                            <td>{{ item.id_abogado }}</td>
                            <td>{{ item.n_abogado }}</td>
                            <td>{{ item.email }}</td>
                            <td><div [innerHtml]="item.comentario | safe: 'html'"></div></td>
                            <td class="td-actions text-right">
                                <button (click)="showLayer(item)" type="button" rel="tooltip" title="Edit Lawyer" class="" style="background: none;color:black;border:none;">
                                    <i class="material-icons">edit</i>
                                  </button>
                              <button (click)="deleteLayer(item.id_abogado)" type="button" rel="tooltip" title="Remove" class="" style="background: none;color:red;border:none;">
                                <i class="material-icons">clear</i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <br><br>
                      <div [style.display]="IsHiddenForm ? 'block' : 'none'"   class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                        <div class="modal-dialog cascading-modal modal-avatar modal-sm"  role="document">
                          <!--Content-->

                          <div class="modal-content" >

                            <div class="modal-body mx-3" style="padding-right: 1rem; padding-left: 1rem;">
                                <div class="card-header card-header-info">
                                  <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_47' | translate }}</h5>
                              </div>
                              <button type="button" style="z-index:100;float: right;margin-top: -2.8rem;" class="btn btn-dark" data-dismiss="modal" (click) = "hideModal()"aria-label="Cerrar modal">X</button>

                              <div class="row">
                                <div class="md-form mb-3">
                                  <input type="text" class="form-control" [formControl]="signupFormModalId"
                                         mdbInput mdbValidate value={{id_abogado}} readonly>
                                  <label for="orangeForm-email">ID. </label>
                                  <mdb-error
                                    *ngIf="signupFormModalId.invalid && (signupFormModalId.dirty || signupFormModalId.touched)">{{ 'texto_61' | translate }}
                                  </mdb-error>
                                  <mdb-success
                                    *ngIf="signupFormModalId.valid && (signupFormModalId.dirty || signupFormModalId.touched)">{{ 'texto_62' | translate }}
                                  </mdb-success>
                                </div>

                                <div class="md-form mb-3" >
                                  <input type="text"  class="form-control" [formControl]="signupFormModalName"
                                         mdbInput mdbValidate maxlength="255" value={{n_abogado}}>
                                  <label for="orangeForm-name">{{ 'texto_44' | translate }}</label>
                                  <mdb-error *ngIf="signupFormModalName.invalid && (signupFormModalName.dirty || signupFormModalName.touched)">
                                    {{ 'texto_61' | translate }}
                                  </mdb-error>
                                  <mdb-success *ngIf="signupFormModalName.valid && (signupFormModalName.dirty || signupFormModalName.touched)">
                                    {{ 'texto_62' | translate }}
                                  </mdb-success>
                                </div>
                            </div>
                            <div class="row">
                                <div class="md-form mb-3">
                                    <input type="text"  class="form-control" [formControl]="signupFormModalEmail"
                                           mdbInput mdbValidate value={{email}}>
                                    <label for="form-email">Email</label>
                                    <mdb-error *ngIf="signupFormModalEmail.invalid && (signupFormModalEmail.dirty || signupFormModalEmail.touched)">
                                      {{ 'texto_61' | translate }}
                                    </mdb-error>
                                    <mdb-success *ngIf="signupFormModalEmail.valid && (signupFormModalEmail.dirty || signupFormModalEmail.touched)">
                                      {{ 'texto_62' | translate }}
                                    </mdb-success>
                                </div>

                                <!--<div class="md-form mb-3 col-md-6">
                                    <label style="display: contents;" for="form-email">{{ 'texto_71' | translate }}</label>
                                    <mat-form-field style="margin-left: 10px;">
                                        <mat-select [(ngModel)]="selected" [formControl]="selectType">
                                          <mat-option>--</mat-option>
                                          <mat-option *ngFor="let tipo of denuncias" [value]="tipo.id_tp_denuncia">
                                            {{tipo.nombre}}
                                          </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>-->
                            </div>

                                <div class="md-form">
                                    <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
                                    <textarea maxlength="4000" placeholder="{{ 'texto_63' | translate }}" type="text" mdbInput [formControl]="contactFormModalMessage" [(ngModel)]="comentario" class="md-textarea form-control" rows="5" value={{comentario}}></textarea>
                                  </div>
                               </div>

                              <div class="text-center mt-3">
                                <button (click)="updateLayer(id_abogado)"  mdbBtn  rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_59' | translate }}
                                </button>
                              </div>

                          </div>



                    </div>
                  </div>
                  <app-add-abogado></app-add-abogado>
              </div>
            </div>
      </div>
    </div>
  </div>

