<div class="page-content">
    <!-- Section What's Canal Denuncia -->
    <section class="da-section da-work" id="services" style="background-color:var(--color-bg-seccion1)!important;">
      <div class="container  margin-container">
        <h2 class="h3 pb-3 text-center" data-aos="" style="color:var(--color-txt-seccion-1)">{{ 'texto_9' | translate }}
        </h2>
        <div class="row">
          <div class="col-md-6 col-lg-6" style="color:var(--color-txt-seccion-1);text-align: justify;">
            <p class="line-spacing">{{ 'texto_10' | translate }}</p>
            <p class="line-spacing">{{ 'texto_11' | translate }}</p>
            <p class="line-spacing">{{ 'texto_12' | translate }}</p>
            <p class="line-spacing">{{ 'texto_336' | translate }}</p>
            <p class="line-spacing">{{ 'texto_337' | translate }}</p>
          </div>
          <div class="col-md-6 col-lg-6">
            <div class="row">
              <div class="col-md-12 col-lg-12">
                <img width="100%" src="assets/img/inicio/inicio-2.jpg" alt="">
              </div>
            </div>
            <div class="row" style="padding-top: 20px;">
              <div class="col-md-4 col-lg-4">
                <div style="text-align:center;" *ngIf="boton1 == '1'">
                  <a target="_blank" [href]=docboton1 [id]="boton1 == '1' ? 'boton1' : ''" tabindex="0">
                    <button [ngStyle]="(selectedLanguage === 'ek') ? euskeraStyles : normalStyles">
                      {{ 'texto_467' | translate }}
                    </button>
                  </a>
                </div>
              </div>
              <div class="col-md-4 col-lg-4">
                <div style="text-align:center;" *ngIf="boton2 == '1'">
                  <a target="_blank" [href]=docboton2 [id]="boton1 == '0' ? 'boton1' : ''" tabindex="0">
                    <button [ngStyle]="(selectedLanguage === 'ek') ? euskeraStyles : normalStyles">
                      {{ 'texto_487' | translate }}
                    </button>
                  </a>
                </div>
              </div>
              <div class="col-md-4 col-lg-4">
                <div style="text-align:center;" *ngIf="boton3 == '1'">
                  <a target="_blank" [href]=docboton3 [id]="boton1 == '0' && boton2 == '0' ? 'boton1' : ''" tabindex="0">
                    <button [ngStyle]="(selectedLanguage === 'ek') ? euskeraStyles : normalStyles">
                      {{ 'texto_489' | translate }}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Section Services -->

    <!-- Section How to do a complaint -->
    <section class="da-section" id="denuncia" style="background-color:var(--color-bg-seccion2)">
      <div class="da-services">
        <div class="container text-center  margin-container">
          <h2 class="h3 pb-5 text-center" style="color:var(--color-txt-seccion2)" data-aos="">{{ 'texto_13' | translate }}
          </h2>
          <div class="row line-spacing">
            <div style="color:var(--color-txt-seccion2);text-align: justify;" class="col-md-6 col-lg-6">
              <p class="font-weight-bold">{{ 'texto_14' | translate }}</p>
              <ul>
                <li>{{ 'texto_15' | translate }}</li>
                <li>{{ 'texto_16' | translate }}</li>
                <li>{{ 'texto_17' | translate }}</li>
                <li>{{ 'texto_18' | translate }}</li>
                <li>{{ 'texto_19' | translate }}</li>
              </ul>
              <p>{{'texto_20' | translate }}</p>
              <p><b>{{'texto_21' | translate }}: </b>{{'texto_22'| translate }}</p>
            </div>
            <div class="col-md-6 col-lg-6" style="color:var(--color-txt-seccion2); text-align: justify;">
              <p class="font-weight-bold" style="text-align: justify;">{{ 'texto_23' | translate }}</p>
              <p style="text-align: justify;">{{ 'texto_24' | translate }}</p>
              <ul style="color:var(--color-txt-seccion2);text-align: justify;margin-left: -40px;">
                <ol> {{ 'texto_25' | translate }}</ol>
                <ol> {{ 'texto_26' | translate }}</ol>
              </ul>
              <p style="color:var(--color-txt-seccion2); text-align: justify; margin-top: 0; margin-bottom: 0;">
                <b>{{ 'texto_27' | translate }}</b> {{ 'texto_28' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Section How to do a complaint -->

    <!-- Section support -->
    <section class="da-projects" id="support" style="background-color:var(--color-bg-seccion3)">
      <div class="container margin-container">
        <h2 class="h3 pb-5 text-center" style="color:var(--color-txt-seccion3);" data-aos="">{{ 'texto_4' | translate }}</h2>
        <div class="row line-spacing">
          <div class="col-md-6 col-lg-6">
            <img width="100%" src="assets/img/inicio/inicio-3.jpg" alt="">
          </div>
          <div class="col-md-6 col-lg-6 compact-form" style="color:var(--color-txt-seccion3);">
            <p style="text-align: justify;">{{ 'texto_399' | translate }}</p>
            <div style="text-align: justify;">
              <p>{{ 'texto_32' | translate }}<br><a href="{{ 'texto_33' | translate }}" id="url" tabindex="0">{{ 'texto_33' | translate }}</a></p>
              <p>{{ 'texto_34' | translate }}</p>
            </div>
            <div class="da-contact-message">
              <form [formGroup]="form">
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-sm-12 mb-3">
                    <label for="firstName" class="col-form-label">{{ 'texto_35' | translate }}</label>
                    <input id="firstName" formControlName="firstName" class="mr-3 form-control" type="text" name="firstName" placeholder="{{ 'texto_35' | translate }}" autocomplete="given-name"
                      [attr.aria-invalid]="form.get('firstName').invalid && (form.get('firstName').dirty || form.get('firstName').touched)" />
                    <div *ngIf="form.get('firstName').invalid && (form.get('firstName').dirty || form.get('firstName').touched)" class="alert alert-danger">
                      <div>{{ 'texto_61' | translate }}</div>
                    </div>
                  </div>

                  <div class="col-md-6 col-lg-6 col-sm-12 mb-3">
                    <label for="lastName" class="col-form-label">{{ 'texto_36' | translate }}</label>
                    <input id="lastName" formControlName="lastName" class="form-control" type="text" name="lastName" placeholder="{{ 'texto_36' | translate }}" autocomplete="family-name" [attr.aria-invalid]="lastName.invalid && (lastName.dirty)" />
                    <div *ngIf="lastName.invalid && (lastName.dirty)" class="alert alert-danger">
                      <div>{{ 'texto_61' | translate }}</div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label for="subject" class="col-form-label">{{ 'texto_37' | translate }}</label>
                    <input id="subject" formControlName="subject" class="form-control" type="text" name="subject" placeholder="{{ 'texto_37' | translate }}" [attr.aria-invalid]="subject.invalid && (subject.dirty)" />
                    <div *ngIf="subject.invalid && (subject.dirty)" class="alert alert-danger">
                      <div>{{ 'texto_61' | translate }}</div>
                    </div>
                  </div>
                  <div class="col">
                    <label for="replyTo" class="col-form-label">{{ 'texto_38' | translate }}</label>
                    <input id="replyTo" formControlName="replyTo" class="form-control" type="email" name="replyTo" autocomplete="email" placeholder="{{ 'texto_38' | translate }}" [attr.aria-invalid]="replyTo.invalid && (replyTo.dirty || replyTo.touched)" />
                    <div *ngIf="replyTo.invalid && (replyTo.dirty || replyTo.touched)" class="alert alert-danger">
                      <div>{{ 'texto_106' | translate }}</div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col">
                    <label for="message" class="col-form-label">{{ 'texto_39' | translate }}</label>
                    <textarea id="message" formControlName="message" class="form-control" name="message" placeholder="{{ 'texto_39' | translate }}" rows="3" required="required" [attr.aria-invalid]="message.invalid && (message.dirty || message.touched)"></textarea>
                    <div *ngIf="message.invalid && (message.dirty || message.touched)" class="alert alert-danger">
                      <div>{{ 'texto_61' | translate }}</div>
                    </div>
                    <p><small style="font-size: 10px;">*{{ 'texto_483' | translate }}</small></p>
                  </div>
                </div>

                <mat-checkbox formControlName="terminos" (change)="toggleButton()">
                  {{ 'texto_755' | translate }}
                  <a *ngIf="terminos2 == '1'; else elseBlock" target="_blank" [href]="url">
                    {{ 'texto_751' | translate }}
                  </a>
                  <ng-template #elseBlock>
                    <a target="_blank" [href]="docpolitica">{{ 'texto_751' | translate }}</a>
                  </ng-template>
                </mat-checkbox>
                <div class="row">
                  <div class="col text-center">
                    <button (click)="enviarEmail()" [disabled]="form.invalid || !form.get('terminos').value" class="btn btn-primary">{{ 'texto_41' | translate }}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--End Section Support -->
</div>
