<app-spinner></app-spinner>

<!-- Content Area Audio Recording -->
<section style="position: absolute;width: 100%;z-index: 1;" class="image-container"
  style="background-image: url(assets/img/wizard/wizard-1.jpg);">
  <br>
  <br>
  <div class="container" style="padding: 0;">
    <div class="board" style="width: 100% !important; height: 80%; ">
      <button type="button" style="z-index:100;float: right;" class="btn btn-dark" (click)="hide();"aria-label="Cerrar modal">X</button>
      <!-- Navigation Area (Circular Tabs) -->
      <!-- Content Area -->
      <div class="tab-content">
        <form #tipoForm="ngForm" class="editForm" novalidate>
          <div class="tab-pane fade in active">
            <h5 class="head text-center">{{ 'texto_691' | translate }}</h5>
            <div class='row'>
              <div class='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8'>
                <div class="row">
                  <!--Record Drop-Down Button-->
                  <div class='col-xs-12 col-sm-6'>
                    <label class="control-label" for="tipoHecho">{{ 'texto_693' | translate }}</label> <br>
                    <button class="button2 btn btn-danger btn-outline-rounded" type="button"
                      style="height: 37%; width: 70%;" (click)="showRecorder(true)">
                      <ion-icon name="mic"></ion-icon>
                      {{ 'texto_692' | translate }}
                    </button>
                  </div>
                  <div class='col-xs-12 col-sm-6'>
                    <label class="control-label" for="tipoHecho">{{ 'texto_689' | translate }}</label> <br>
                    <button class="button2 btn btn-info btn-outline-rounded" type="button"
                      style="height: 37%; width: 70%;" (click)="showInformationTransciption(true)">
                      <ion-icon name="eye-outline"></ion-icon>
                      {{ 'texto_714' | translate }}
                    </button>
                  </div>
                  <!--END Record Drop-Down Button-->

                  <!--MENU 0-->
                  <!--SELECTED EMPRESA WITH SEARCH INPUT-->
                  <div *ngIf="showMenu1" class="container" style="align-items: center;">
                    <div *ngIf="showInputAutocomplete" class='col-xs-12 col-sm-6'>
                      <div class="form-group">
                        <label class="control-label" for="tipoHecho">{{ 'texto_67' | translate }}</label> <br>
                        <mat-form-field [color]="colorfieldAutocomplete">
                          <input matInput #miInput id="orangeForm-name" [formControl]="searchInput"
                            placeholder="Buscar..." [matAutocomplete]="auto" (input)="onInputChange(miInput.value)">
                          <mat-autocomplete #auto="matAutocomplete">
                            <ng-container *ngIf="suggestions && suggestions.length > 0; else noSuggestions">
                              <mat-option *ngFor="let suggestion of suggestions"
                                (click)="selectSuggestion(suggestion.n_empresa, suggestion.id_empresa, suggestion.id_sociedad)">{{
                                suggestion.n_empresa
                                }}
                              </mat-option>
                            </ng-container>
                            <!-- Mensaje de error cuando no hay sugerencias -->
                            <ng-template #noSuggestions>
                              <mat-option>
                                {{ 'texto_713' | translate }}
                              </mat-option>
                            </ng-template>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                    </div>
                    <!--END SELECTED EMPRESA WITH SEARCH INPUT-->

                    <!--SELECTED EMPRESA-->
                    <div *ngIf="!showInputAutocomplete" class='col-xs-12 col-sm-6'>
                      <div class="form-group">
                        <label class="control-label" for="tipoHecho">{{ 'texto_67' | translate }}</label> <br>
                        <mat-form-field>
                          <mat-label>{{ 'texto_140' | translate }}</mat-label>
                          <mat-select #empresa="ngModel" id="empresa" name="empresa"
                            (selectionChange)="onChange($event)" [(ngModel)]="selectedCompany" required>
                            <mat-option *ngFor="let emp of companies" [value]="emp">
                              {{emp.n_empresa}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <!--END SELECTED EMPRESA-->

                    <!--SELECTED CENTER-->
                    <div class='col-xs-12 col-sm-6' *ngIf=showSelected;>
                      <div class="form-group">
                        <label class="control-label" for="tipoHecho">{{ 'texto_672' | translate }}</label> <br>
                        <mat-form-field>
                          <mat-label>{{ 'texto_140' | translate }}</mat-label>
                          <mat-select id="centro" name="centro" [(ngModel)]="selectedCenter"
                            (selectionChange)="onChangeCenter($event)" required>
                            <mat-option *ngFor="let cent of centers" [value]="cent">
                              {{cent.n_centro}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <!--END CENTER-->

                    <!--SELECTED COMUNICATION TYPE-->
                    <div class='col-xs-12 col-sm-6'>
                      <div class="form-group">
                        <label class="control-label" for="tipoHecho">{{ 'texto_71' | translate }}</label> <br>
                        <mat-form-field>
                          <mat-label>{{ 'texto_140' | translate }}</mat-label>
                          <mat-select id="tipoDenuncia" name="tipoDenuncia" [(ngModel)]="complaintType"
                            (selectionChange)="onChangeComplaint($event)" required="">
                            <mat-option matTooltip="{{denuncia.nombre}}" *ngFor="let denuncia of complaints"
                              [value]="denuncia.id_tp_denuncia">
                              {{denuncia.nombre}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <!--END SELECTED COMUNICATION TYPE-->

                    <!--SELECTED TIPO DE RELACION-->
                    <div class='col-xs-12 col-sm-6'>
                      <div class="form-group">
                        <label class="control-label" for="tipoHecho">{{ 'texto_342' | translate }}</label> <br>
                        <mat-form-field>
                          <mat-label>{{ 'texto_140' | translate }}</mat-label>
                          <mat-select id="denuncianteRelacion" name="denuncianteRelacion" [(ngModel)]="relationType"
                            (selectionChange)="onChangeRelation($event)" required>
                            <mat-option *ngFor="let tipo of tipoDenunciantes" [value]="tipo.id_tp_relacion">
                              {{tipo.nombre}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                     <!--END SELECTED TIPO DE RELACION-->
                    <br>
                    <br>
                    <br>
                  </div>
                  <br><!--END MENU 0-->

                  <!--MENU 1-->
                  <div *ngIf="showMenu1" class="container" style="align-items: center;">
                    <div class='col-xs-12 col-sm-6' *ngIf="ShowSetReceptor">
                      <div class="form-group">
                        <label class="control-label" for="">{{ 'texto_148' | translate }}</label> <br>
                        <p id="receptor" value="{{Receptoraleatorio}}" name="receptor">{{Receptoraleatorio}}</p>
                      </div>
                    </div>
                    <div class='col-xs-12 col-sm-6' *ngIf="Showselect">
                      <div class="form-group">
                        <label class="control-label" for="tipoHecho">{{ 'texto_491' | translate }}</label> <br>
                        <mat-form-field>
                          <mat-label>{{ 'texto_35' | translate }}</mat-label>
                          <mat-select id="receptor" name="receptor" [(ngModel)]="receptor"
                            (selectionChange)="onChangeReceptor($event)" (click)="onClickReceptor($event)" required>
                            <mat-option *ngFor="let recept of receptores" [value]="recept.id_user">
                              {{recept.n_user}} {{recept.ape_user}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class='col-xs-12 col-sm-6' *ngIf="ShowSetReceptor">
                      <div class="form-group">
                        <label class="control-label" for="">{{ 'texto_155' | translate }}</label> <br>
                        <button style="height: 18%; width: 67%;" class="btn btn-outline-rounded btn-default"
                          (click)="buscarReceptores()">{{ 'texto_150' |
                          translate }}</button>
                      </div>
                    </div>

                    <div class='col-xs-12 col-sm-6' *ngIf="typeButton">
                      <div class="form-group">
                        <label class="control-label" for="">{{ 'texto_155' | translate }}</label> <br>
                        <button style="height: 18%; width: 90%;" class="btn btn-outline-rounded btn-default"
                          (click)="receptoresAutomatico()">{{ 'texto_694' | translate }}</button>
                      </div>
                    </div>
                  </div> <!--END MENU 1-->

                  <!--MENU 2 DATOS DEL DENUNCIANTE-->
                  <div *ngIf="showMenu2" style="padding-top: 18px;">
                    <div class='col-xs-12 col-sm-12'>
                      <form #denuncianteForm="ngForm" class="editForm" novalidate>
                        <div class="tab-pane fade in active">
                          <h5 class="head text-center">{{ 'texto_78'| translate }}</h5>
                          <div class='row'>
                            <div class="row">
                              <!--Record Drop-Down Button-->
                              <div class='col-xs-12 col-sm-6'>
                                <label class="control-label" for="tipoHecho">{{ 'texto_545'| translate }}</label> <br>
                                <mat-form-field class="example-full-width">
                                  <input #denuncianteFirstName="ngModel" id="denuncianteFirstName" value=""
                                    name="denuncianteFirstName" [(ngModel)]="dataComplaint.denuncianteFirstName"
                                    matInput>
                                </mat-form-field>
                              </div>
                              <div class='col-xs-12 col-sm-6'>
                                <label class="control-label" for="tipoHecho">{{ 'texto_546'| translate }}</label> <br>
                                <mat-form-field class="example-full-width">
                                  <input #denuncianteLastName="ngModel" id="denuncianteLastName" value=""
                                    name="denuncianteLastName" [(ngModel)]="dataComplaint.denuncianteLastName" matInput>
                                </mat-form-field>
                              </div>
                              <div class='col-xs-12 col-sm-6'>
                                <label class="control-label" for="tipoHecho">{{ 'texto_547'| translate }}</label> <br>
                                <mat-form-field class="example-full-width">
                                  <input #denuncianteEmail="ngModel" id="denuncianteEmail" value=""
                                    name="denuncianteEmail" [(ngModel)]="dataComplaint.denuncianteEmail" matInput>
                                </mat-form-field>
                              </div>
                              <div class='col-xs-12 col-sm-6'>
                                <label class="control-label" for="tipoHecho">{{ 'texto_548'| translate }}</label> <br>
                                <mat-form-field class="example-full-width">
                                  <input #denunciantePhone="ngModel" id="denunciantePhone" value=""
                                    name="denunciantePhone" [(ngModel)]="dataComplaint.denunciantePhone" matInput>
                                </mat-form-field>
                              </div>
                            </div>

                            <div style="font-size: 0.93rem;line-height: 16px;border: 0px solid black;">
                              <p>*{{ 'texto_339' | translate }} {{ 'texto_340' | translate }}</p>
                            </div>
                          </div>
                        </div>
                        <div class="botones">
                          <div class="form-group text-center" style="margin-top: 30px;">
                            <button class="btn btn-outline-rounded btn-default" (click)="return()"> <span
                                style="margin-right:10px;" class="glyphicon glyphicon-arrow-left"></span> {{ 'texto_142'
                              | translate }}</button>
                            <button class="btn btn-success btn-outline-rounded btn-info"
                              (click)="menu3(denuncianteForm)"> {{ 'texto_141' | translate }} <span
                                style="margin-left:10px;" class="glyphicon glyphicon-arrow-right"></span></button>
                          </div>
                        </div>

                        <!-- <div *ngIf="showInputAutocomplete" class="botones">
                          <div class="form-group text-center" style="margin-top: 30px;">

                            <button class="btn btn-outline-rounded btn-default" (click)="returnMenu3()"> <span
                                style="margin-right:10px;" class="glyphicon glyphicon-arrow-left"></span> {{ 'texto_142'
                              | translate }}</button>

                            <button class="btn btn-success btn-outline-rounded btn-info"
                              [disabled]="enablebuttonNext" (click)="saveAudioRecordedData(denuncianteForm)"> {{
                              'texto_164' | translate }} <span style="margin-left:10px;"
                                class="glyphicon glyphicon-arrow-right"></span></button>
                          </div>
                        </div> -->


                      </form>
                    </div>
                  </div> <!--END MENU 2 DATOS DEL DENUNCIANTE-->

                  <!--MENU 3 TESTIGOS-->
                  <div *ngIf="showMenu3" class="container" style="align-items: center; padding-top: 20px;">
                    <div class='col-xs-12 col-sm-12'>
                      <form #personalForm="ngForm" class="editForm" novalidate>
                        <div class="tab-pane fade in active">
                          <h5 class="head text-center">{{ 'texto_357'| translate }}</h5>
                          <div class='row'>
                            <div class="row">
                              <!--Record Drop-Down Button-->
                              <div class='col-xs-12 col-sm-6'>
                                <label class="control-label" for="firstname">{{ 'texto_35' | translate }}</label>
                                <mat-form-field class="example-full-width">
                                  <input #firstname="ngModel" id="firstname" name="firstname" type="text"
                                    [(ngModel)]="personal.firstName" matInput>
                                </mat-form-field>
                              </div>
                              <div class='col-xs-12 col-sm-6'>
                                <label class="control-label" for="lastname">{{ 'texto_36' | translate }}</label>
                                <mat-form-field class="example-full-width">
                                  <input #lastname="ngModel" id="lastname" name="lastname"
                                    [(ngModel)]="personal.lastName" matInput>
                                </mat-form-field>
                              </div>
                              <div class='col-xs-12 col-sm-6'>
                                <label class="control-label" for="email">{{ 'texto_38' | translate }}</label><br>
                                <mat-form-field class="example-full-width">
                                  <input #email="ngModel" pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$" id="email"
                                    name="email" [(ngModel)]="personal.email" matInput>
                                </mat-form-field>
                              </div>
                              <div class='col-xs-12 col-sm-6'>
                                <label class="control-label" for="phone">{{ 'texto_66' | translate }}</label>
                                <mat-form-field class="example-full-width">
                                  <input #phone="ngModel" id="phone" name="phone" maxlength="25" type="text"
                                    [(ngModel)]="personal.phone" matInput>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="row" style="width: 100%;">
                              <div class='col-xs-12 col-sm-12'>
                                <label class="control-label" for="description">{{ 'texto_63' | translate }}</label>
                                <textarea maxlength="4000" class="form-control input-md" #description="ngModel"
                                  id="description" name="description" type="text" [(ngModel)]="personal.description"
                                  rows="2"></textarea>
                                <p><small style="font-size: 0.62rem;">*{{ 'texto_152' | translate }}</small></p>
                              </div>
                            </div>
                          </div>
                          <div class="botones">
                            <div class="form-group text-center" style="margin-top: 30px;">
                              <button class="btn btn-outline-rounded btn-default" (click)="returnMenu2()"> <span
                                  style="margin-right:10px;" class="glyphicon glyphicon-arrow-left"></span> {{
                                'texto_142' | translate }}</button>
                              <button class="btn btn-success btn-outline-rounded btn-info"
                                [disabled]="!personalForm.valid" (click)="saveWitness(personalForm)"> {{ 'texto_141' |
                                translate }} <span style="margin-left:10px;"
                                  class="glyphicon glyphicon-arrow-right"></span></button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div> <!--end menu 3 TESTIGOS-->

                  <!--MENU 4 UPLOAD DOCUMENT-->
                  <div *ngIf="showMenu4" class="container" style="align-items: center; padding-top: 20px;">
                    <div class='col-xs-12 col-sm-12'>
                      <!-- Content Area -->
                      <div class="tab-content">
                        <form #archivoForm="ngForm" class="editForm" novalidate>
                          <div class="tab-pane fade in active">
                            <h5 class="head text-center">{{'texto_358' | translate }}</h5>
                            <div class='row'>
                              <div class='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8'>
                              <div class="row" style="margin-top: -30px;">
                                <div class="col-md-12">
                                  <form [formGroup]="myForm" style="width:100%;display:contens;color:white">
                                    <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop
                                      (onFileDropped)="dragFile($event)">
                                      <input [(ngModel)]="archivos.nombreArchivo" formControlName="file"
                                        accept=".pptx,.pptx,.pptm,.potx,.potm,.ppam,.ppsx,.ppsm,.xlsx,.xlsm,.xltx,.xltm,.xlam,image/gif,image/jpeg,image/jpg,image/png,video/*,audio/*,.pdf,.txt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        id="file" hidden type="file" #fileInput (change)="uploadFile($event)">
                                    </div>
                                    <div class="files-list" *ngFor="let file of files;let i= index">
                                      <p> {{ file }} </p>
                                      <button class="delete-file" (click)="deleteAttachment(i)">
                                        <img src="../../assets/img/borrar-fichero.png">
                                      </button>
                                    </div>
                                  </form>
                                </div>
                                <div class='col-xs-12 col-sm-12'>
                                  <label class="control-label" for="descripcion">{{ 'texto_63' | translate }}</label>
                                  <div class="md-form">
                                    <textarea maxlength="4000" class="form-control input-md" [formControl]="descripcion"
                                      id="descripcion" name="descripcion" type="text"
                                      placeholder="{{ 'texto_63' | translate }}"
                                      [(ngModel)]="archivos.descripcionArchivo" rows="4"></textarea>
                                  </div>
                                  <p><small style="font-size: 0.62rem;">*{{ 'texto_334' | translate }}</small></p>
                                </div>
                              </div>
                                <div class="botones">
                                  <div class="form-group text-center" style="margin-top: 30px;">
                                    <button class="btn btn-outline-rounded btn-default"
                                      (click)="returnMenu3(archivoForm)"> <span style="margin-right:10px;"
                                        class="glyphicon glyphicon-arrow-left"></span> {{ 'texto_142' | translate
                                      }}</button>
                                    <button class="btn btn-success btn-outline-rounded btn-info"
                                      [disabled]="!archivoForm.valid" (click)="goToNext(archivoForm)"> {{ 'texto_141' |
                                      translate }} <span style="margin-left:10px;"
                                        class="glyphicon glyphicon-arrow-right"></span></button>
                                  </div>
                                </div>
                              </div>
                          </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div> <!--end menu 4-->


                  <!--MENU 5 DATOS DE LA DENUNCIA-->
                  <div *ngIf="showMenu5" class="container" style="align-items: center; padding-top: 20px;">
                    <div class='col-xs-12 col-sm-12'>
                      <form #denuncianteForm="ngForm" class="editForm" novalidate>
                        <div class="tab-pane fade in active">
                          <h5 class="head text-center">{{ 'texto_109'| translate }}</h5>
                          <div class='row'>
                            <div class="row">
                              <!--Fecha de la incidencia-->
                              <div class='col-xs-12 col-sm-6'>
                                <label class="control-label" for="tipoHecho">{{ 'texto_345'| translate }}</label> <br>
                                <mat-form-field>
                                  <input matInput required [matDatepicker]="fechaDatosDenuncia"  placeholder="{{ 'texto_92'| translate }}"
                                    id="fechaDatosDenuncia" name="fechaDatosDenuncia"
                                    [(ngModel)]="dataComplaint.fechaDatosDenuncia">
                                  <mat-datepicker-toggle matSuffix [for]="fechaDatosDenuncia"></mat-datepicker-toggle>
                                  <mat-datepicker #fechaDatosDenuncia></mat-datepicker>
                                </mat-form-field>
                              </div>
                            </div>
                            <!-- End Fecha de la incidencia-->

                            <!--Términos y Condiciones-->
                            <div class="row">
                              <div class='col-xs-12 col-sm-12' style="font-size:10px;">
                                <mat-checkbox required id="terminos" name="terminos"
                                  [(ngModel)]="dataComplaint.terminos" style="font-size: 11px;">{{ 'texto_161' |
                                  translate }}
                                  <a *ngIf="terminos2 == '1' ; else elseBlock" target="_blank" [href]=url> {{
                                    'texto_162' | translate }}</a>
                                  <ng-template #elseBlock>
                                    <a target="_blank" [href]=documentConditions>{{ 'texto_162' | translate }}</a>
                                  </ng-template>
                                  {{ 'texto_163' | translate }}
                                </mat-checkbox>
                              </div>
                            </div>
                            <!--End Términos y Condiciones-->
                          </div>
                        </div>
                        <br>
                        <div style="font-size: 11px;line-height: 16px;">
                          <div style="font-size: 11px;line-height: 16px;border: 0px solid black;">
                            <p>{{ 'texto_695' | translate }}</p>
                          </div>
                        </div>
                        <div class="botones">
                          <div class="form-group text-center" style="margin-top: 30px;">
                            <!--ANTERIOR-->
                            <button class="btn btn-outline-rounded btn-default" (click)="returnMenu4()"> <span
                                style="margin-right:10px;" class="glyphicon glyphicon-arrow-left"></span> {{ 'texto_142'
                              | translate }}</button>
                            <!--SIGUIENTE-->
                            <button class="btn btn-success btn-outline-rounded btn-info"
                              [disabled]="!denuncianteForm.valid" (click)="saveAudioRecordedData(denuncianteForm)"> {{
                              'texto_164' | translate }} <span style="margin-left:10px;"
                                class="glyphicon glyphicon-arrow-right"></span></button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div> <!--end menu 5-->
                </div>
                <div class="form-group text-center" style="margin-top: 30px;">
                  <button *ngIf="showButtonMenu1" class="btn btn-success btn-outline-rounded btn-info"
                    [disabled]="enablebuttonNext || !tipoForm.valid" (click)="menu2()"> {{ 'texto_141' | translate }}
                    <span style="margin-left:10px;" class="glyphicon glyphicon-arrow-right"></span></button>
                </div>
              </div>
            </div>
          </div>
          <div #recapthca></div>
        </form>
      </div>
      <!-- End Content Area -->
    </div>
  </div>
</section>
<!-- Content Area Audio Recording -->

<!--MODAL AUDIO RECORDING-->
<div class="ui" *ngIf="showModalAudioRecording">
  <br>
  <br>
  <br>
  <br>
  <br>
  <div class="wrapper" style="width: 100%;">
    <div class="recorder">
      <button class="button3 btn btn-dark btn-outline-rounded" type="button" (click)="showRecorder(false)"> <ion-icon
          name="arrow-undo-circle">{{ 'texto_142' | translate }}</ion-icon></button>
      <div class="container d-flex justify-content-center align-items-center">
        <form #miFormulario="ngForm">
          <mat-form-field style="width: 140px;">
            <mat-select placeholder="Selecciona un idioma" [(value)]="idiomaSeleccionado"
              (selectionChange)="onIdiomaSeleccionadoChange()">
              <mat-option *ngFor="let idioma of idiomas" [value]="idioma.value">{{ idioma.label }}</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <div class="recorder2">
        <div class="icon">
          <div class="record-btn" *ngIf="!showButtonRec" role="button" (click)="startService()">
            <!-- <ion-icon [name]="isAudioRecording ? 'stop' : 'mic-outline'"></ion-icon> -->
            <i class="material-icons" [innerHTML]="isAudioRecording ? 'stop' : 'mic_outline'"></i>
          </div>
          <div class="record-btn play-btn" [class.recording]="isAudioRecording" *ngIf="showButtonRec" (click)="stop()">
            <ion-icon name="stop"></ion-icon>
          </div>
        </div>
        <div class="timer" id="audioTimer" *ngIf="isAudioRecording">{{ audioRecordedTime }}</div>

        <div class="player">
          <div style="font-size: 0.75rem;line-height: 16px;"
            [innerHtml]="'*El audio se transcribirá al finalizar la grabación.'| safe: 'html'">
          </div>
          <div style="font-size: 0.75rem;line-height: 16px; padding-top: 6px;"
            [innerHtml]="'*Se recomienda grabar sin ruido en el entorno.'| safe: 'html'">
          </div>
        </div>

        <div class="" *ngIf="isStoppedSpeechRecog" style="padding-top: 10px;">
          <button class="btn btn-light btn-outline-rounded" (click)="clearAudioRecordedData()">{{ 'texto_151' |
            translate }}</button>
          <button class="btn btn-info btn-outline-rounded" (click)="showRecorder(false)">
            {{ 'texto_141' | translate }}</button>
        </div>

      </div>
    </div>
  </div>
</div>
<!--END MODAL AUDIO RECORDING-->

<!--MODAL INFORMATION TRANCRIPTION-->
<div class="ui" *ngIf="showInformation">
  <br>
  <br>
  <br>
  <br>
  <br>
  <div class="wrapper" style="width: 100%;">
    <div class="recorder">

      <button class="button3 btn btn-dark btn-outline-rounded" type="button"
        (click)="showInformationTransciption(false)"> <ion-icon name="arrow-undo-circle">{{ 'texto_142' | translate
          }}</ion-icon></button>

      <div class="recorder2">
        <label for="clipboard-example-textarea">Transcripción</label>
        <textarea style="display: block;
        margin: 4px 0 8px; color: black; font-size: 1rem;" id="clipboard-example-textarea" cols="30" rows="10"
          [(ngModel)]="text"></textarea>
        <div class="player">
          <div style="font-size: 0.75rem;line-height: 16px;" [innerHtml]="'*La transcripción es editable.'| safe: 'html'">
          </div>
        </div>
        <div class="" *ngIf="text" style="padding-top: 10px;">
          <button class="btn btn-light btn-outline-rounded" (click)="clearAudioRecordedData()">{{ 'texto_445' |
            translate }}</button>
          <button class="btn btn-light btn-outline-rounded" [cdkCopyToClipboard]="text">Copiar</button>
          <button class="btn btn-info btn-outline-rounded" (click)="showInformationTransciption(false)"
            [cdkCopyToClipboard]="text">{{ 'texto_114' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END MODAL INFORMATION TRANCRIPTION-->
