import { Component, ElementRef, OnInit } from '@angular/core';
import { NavigationService } from '../shared/services/navigation.Service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {


  constructor(

  ) { }

  ngOnInit() {
  }


 
}
