import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private isLoaddingSubject = new BehaviorSubject<boolean>(false);
  isLoadding$ = this.isLoaddingSubject.asObservable();

  show(): void {
    this.isLoaddingSubject.next(true);
    
  }

  hide(): void {
    this.isLoaddingSubject.next(false);
  }
}
