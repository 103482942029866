import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MAT_MOMENT_DATE_FORMATS,MomentDateAdapter,MAT_MOMENT_DATE_ADAPTER_OPTIONS,} from '@angular/material-moment-adapter';
import {DateAdapter,MAT_DATE_FORMATS,MAT_DATE_LOCALE,} from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {Datos,GrabarDenuncia,} from 'src/app/enviar-denuncia/models/formData.model';
import { FormDataService } from 'src/app/enviar-denuncia/services/formData.service';

declare const webkitSpeechRecognition: any;

@Component({
  selector: 'app-datos-denuncia',
  templateUrl: './datos-denuncia.component.html',
  styleUrls: ['./datos-denuncia.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class DatosDenunciaComponent implements OnInit {

  contador = 0;
  title = 'texto_109';
  datos: Datos;
  form: any;
  hoy = new Date();


  //Variables to add audio
  showModalAudioRecording = false; //Show Modal Audio Recording
  isAudioRecording = false; //status animation recording
  showButtonRec: boolean = false; //shows the record button
  isStoppedSpeechRecog = false; //status stop record
  idiomas = [
    { value: 'es-ES', label: 'Español (ES)' },
    { value: 'en-US', label: 'Inglés (US)' },
    { value: 'fr-FR', label: 'Francés (FR)' },
    { value: 'it-IT', label: 'Italiano (IT)' },
    { value: 'pt-PT', label: 'Portugués (PT)' },
    { value: 'ca-ES', label: 'Catalán (ES)' },
    { value: 'eu-ES', label: 'Euskera (ES)' },
  ];
  idiomaSeleccionado: string = 'es-ES';
  audioRecordedTime = ''; //record time audio
  recognition = new webkitSpeechRecognition(); //lib Speech Recognition
  tempWords: any; //words temp
  descripcionDatosDenunciaext = 'no hay información'; //text about record
  isNotCompleteInformationAudio: boolean = false; //status button siguiente
  showInformation: boolean = true; //show recording information
  dataComplaint: GrabarDenuncia;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private formDataService: FormDataService,
    private _adapter: DateAdapter<any>,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.hoy.setDate(this.hoy.getDate());
  }

  // ADD AUDIO RECORD
  //Show Modal Audio recording
  showRecorder(mode: false | true | null) {
    this.isAudioRecording = false;
    this.showModalAudioRecording = mode;
    this.showButtonRec = false;
    this.isStoppedSpeechRecog = true;
    // Iniciar el retraso antes de llamar a stop()
    // setTimeout(() => {
    //   this.recognition.stop();
    // }, 1000);
  }

  //Change Idioma from Speech Recognition
  onIdiomaSeleccionadoChange(): void {
    this.init();
  }

  //Inicialize the Api Recognition
  init(): void {
    this.recognition = new webkitSpeechRecognition();
    this.recognition.continuous = true; // Grabación continua
    this.recognition.interimResults = true; // Obtener resultados provisionale
    this.recognition.lang = this.idiomaSeleccionado;

    //this.recognition.onresult = (event) => {
    this.recognition.addEventListener('result', (e: any) => {
      const transcript = Array.from(e.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join('');
      this.tempWords = transcript;
    });
  }

  //start button, audio recording
  start(): void {
    this.isStoppedSpeechRecog = false;
    this.recognition.start();
    this.recognition.addEventListener('end', () => {
      if (this.isStoppedSpeechRecog) {
        this.recognition.stop();
      } else {
        this.wordConcat();
        //this.recognition.start();
        var palabraBuscada = 'coma';
        var resultado = this.datos.descripcionDatosDenuncia
          .toLowerCase()
          .includes(palabraBuscada.toLowerCase()); //gets the word "coma" in the sentence
        if (resultado) {
          this.datos.descripcionDatosDenuncia = this.datos.descripcionDatosDenuncia + ','; //add "," in the sentence
          var palabras = this.datos.descripcionDatosDenuncia.trim().split(' ');
          var ultimaPalabra = palabras[palabras.length - 2]; //gets the penultimate word

          if (ultimaPalabra === 'coma' || ultimaPalabra === ',coma') {
            //Check if there are at least two words before trying to delete the second to last one
            if (palabras.length >= 2) {
              //Eliminate the penultimate word
              palabras.splice(-2, 1);
              this.datos.descripcionDatosDenuncia = palabras.join(' ');
            }
          }
        }
      }
    });
  }

  //stop audio recording
  stop(): void {
    this.isAudioRecording = false;
    this.isStoppedSpeechRecog = true;
    this.showButtonRec = false; //hidde button recording
    this.wordConcat();
    this.checkAudioFieldCompleted(this.datos.descripcionDatosDenuncia); //set Value for hidde button from 'siguiente'
    // Iniciar el retraso antes de llamar a stop()
 // Asegúrate de que this.text esté definido antes de llamar a checkAudioFieldCompleted

    // Forzar actualización del DOM
  this.cdr.detectChanges();
  //console.log('detectChanges', this.text);
    setTimeout(() => {
      this.recognition.stop();
    }, 2000);
  }

  // Check if the audio recording field is completed.
  checkAudioFieldCompleted(descripcionDatosDenuncia: string) {

    // Set Value for hide button from 'siguiente'
    if (descripcionDatosDenuncia === undefined || descripcionDatosDenuncia === 'undefined '|| descripcionDatosDenuncia === 'undefined') {
      this.toastr.warning(
        this.translate.instant('texto_180')
      );
      this.isNotCompleteInformationAudio = true;
      //console.log('entro1');
    } else if (descripcionDatosDenuncia === null || descripcionDatosDenuncia.trim() === '') {
      this.toastr.warning(
        this.translate.instant('texto_180')
      );
      this.isNotCompleteInformationAudio = true;
      ///console.log('entro2');
    } else if (descripcionDatosDenuncia === 'no hay información') {
      this.isNotCompleteInformationAudio = true;
      //console.log('entro3');
    } else {
      this.isNotCompleteInformationAudio = false;
      //console.log('entro4');
    }
  }

  //This method is triggered when the description of the complaint data changes, updating the state of isNotCompleteInformationAudio based on the new text.
  onDescriptionChange(text: string) {
    this.checkAudioFieldCompleted(text);
  }
  //concatenate words from audio recording
  wordConcat(): void {
    this.datos.descripcionDatosDenuncia = this.datos.descripcionDatosDenuncia + this.tempWords + ' ';
    this.tempWords = ' ';
  }

  //method that inicialize audio recording
  startService(): void {
    this.datos.descripcionDatosDenuncia = '';
    this.isAudioRecording = true;
    this.showButtonRec = true;
    this.start();
  }

  //clear text data from audio recording
  clearAudioRecordedData() {
    this.datos.descripcionDatosDenuncia = '';
  }

  saveAudioRecordedData(form: any) {
    //console.log(this.isNotCompleteInformationAudio);
    if (this.isNotCompleteInformationAudio) {
      if (form) {
        let day = form.value.fechaDatosDenuncia._i.date;
        let month = form.value.fechaDatosDenuncia._i.month + 1;
        let year = form.value.fechaDatosDenuncia._i.year;
        let fecha = year + '-' + month + '-' + day;
        this.dataComplaint.fechaDatosDenuncia = fecha.toString();
        this.dataComplaint.transcripcion = this.datos.descripcionDatosDenuncia;
        //console.log('guardar', this.text);
        this.save(form);
      } else {
        //this.save();
      }
    } else {
      this.toastr.error(
        this.translate.instant('texto_712'),
        this.translate.instant('texto_264')

      );
    }
  }
  //Show Modal Information Transcription
  showInformationTransciption(mode: false | true | null) {
    this.showInformation = mode;
  }
  // End ADD AUDIO RECORDING

  ngOnInit() {
    this.init(); //Inicialize the Api Recognition
    this._adapter.setLocale('es');
    this.datos = this.formDataService.getDatos();
    // let datosDescripcion = (document.getElementById("descripcionDatosDenuncia") as HTMLInputElement).value;
    this.contador = this.datos.descripcionDatosDenuncia.length;
  }

  save(form: any): boolean {
    if (!form.valid) {
      return false;
    }

    //  this.datos.fechaDatosDenuncia = "2015-02-02"
    if (this.contador > 4000) {
      this.toastr.error(
        this.translate.instant('texto_266'),
        this.translate.instant('texto_243')
      );
    } else {
      this.formDataService.setDatos(this.datos);
      return true;
    }
  }

  goToPrevious(form: any) {
    if (this.save(form)) {
      // Navigate to the personal page
      this.router.navigate(['/enviar-denuncia/denunciante']);
    }
  }

  goToNext(form: any) {
    if (this.save(form)) {
      // Navigate to the work page
      this.router.navigate(['/enviar-denuncia/testigo']);
    }
  }

  hide() {
    window.location.reload();
    /* this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/inicio']);
      });*/
  }

  onKey(event) {
    //this.datos.descripcionDatosDenuncia = event.target.value;
    let datos = (
      document.getElementById('descripcionDatosDenuncia') as HTMLInputElement
    ).value;
    this.contador = datos.length;
  }

  onCopied() {
    this.toastr.success(this.translate.instant('texto_718'));
  }
}
