import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../shared/services/api.service';
import { AuthService } from '../shared/services/auth.service';
import { Idiomas } from '../model/idiomas';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.css']
})
export class TranslationComponent implements OnInit {
  @ViewChild('menu') menu!: MatSelect;

  public activeLang = '';
  idiomas: Idiomas[];
  constructor( private auth: AuthService,private apiService: ApiService,private translate: TranslateService) {
  //this.translate.setDefaultLang(this.activeLang);
  }
  ngOnInit() {
    this.cargarIdiomasDesdeBD();


  }

  /**
   * Method to access languages by keyboard
   * @param event
   */
  onMenuKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();
      this.menu.open();
    } else if (event.key === 'Enter') {
      event.preventDefault();
      // Perform the selection action
      this.menu.close();
      this.cambiarLenguaje(this.activeLang);
    }
  }

  public cambiarLenguaje(lang) {
    this.auth.setLanguage(lang);
    this.activeLang = lang;
    this.translate.use(lang);
    window.location.reload();

  }

  showMenu() {
    this.menu.open();
    setTimeout(() => {
      this.menu.focus();  // Enfocar el menú para que sea navegable con el teclado
    }, 0);
  }

  cargarIdiomasDesdeBD() {
    this.apiService.searchIdiomas().subscribe((idiomas: Idiomas[]) => {

      const ordenIdiomas = {
        'es': 1,
        'en': 2,
        'fr': 3,
        'pt': 4,
        'it': 5,
        'ct': 6,
        'ek': 7,

      };

      idiomas.sort((a, b) => ordenIdiomas[a.id_idioma] - ordenIdiomas[b.id_idioma]);
      this.idiomas = idiomas;
    });
  }


}
