<section class="bloque-contenedor" id="contenido-principal">
  <header style="z-index: 90 !important; background-color: white;">
    <nav class="navbar navbar-expand-lg navbar-dark bg-transparent px-0"
      style="z-index: 90 !important; padding-left: 5% !important; padding-right: 5% !important; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); margin-bottom: 0px !important;">
      <a href="#inicio" class="button-link">
        <img src="assets/img/logos/logo.png" width="160px" style="margin-left: -10px;"
          alt="{{ 'texto_294' | translate }} {{ 'texto_6' | translate }}">
      </a>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a href="/inicio" class="close-btn" [attr.aria-label]="'texto_735' | translate"><i
              class="bi bi-x-square-fill"></i></a>

        </li>
      </ul>
    </nav>
  </header>
  <div class="container">
    <div class="container">
      <div class="breadcrum-div">
        <nav aria-label="breadcrumb" class="nav">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/inicio">{{ 'texto_1' | translate }}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ 'texto_744'| translate }}</li>
          </ol>
        </nav>
      </div>
      <h1>
        <div>
          <span class="titulo">
            Declaración de accesibilidad de Canal denuncia para el aplicativo de canaldenuncia.app
          </span>
        </div>
      </h1>
      <div class="line"></div>

      <p>Resultado: <span class='verde'>conforme </span></p>

      <p>Canal denuncia ADDAW se ha comprometido a hacer accesible su sitio web de conformidad con el <a
          href='https://www.boe.es/boe/dias/2018/09/19/pdfs/BOE-A-2018-12699.pdf'>Real Decreto 1112/2018, de 7 de
          septiembre</a>.</p>

      <p>A fecha de 28/06/2024, <a href='https://addaw.org/es' target='_blank'>El equipo de ADDAW</a> ha realizado una
        auditor&iacute;a de accesibilidad del sitio https://addaw.canaldenuncia.app siguiendo la metodolog&iacute;a
        establecida en la norma UNE-EN 301549:2022 </p>
      <p> El resultado de la auditor&iacute;a es <strong>conforme</strong> con el RD 1112/2018 y la norma UNE-EN
        301549:2022
      </p>
      <p> Es importante señalar que se trata de un aplicativo donde cada cliente diseña algunos aspectos de su canal,
        como
        el color y las imágenes. De ahi que los criterios derivados del mal uso del aplicativo en lo referente a estos
        elementos no han sido tenidos en cuenta en esta auditoría.</p>
      <p> La auditoría se ha realizado sobre un canal de denuncia de muestra https://addaw.canaldenuncia.app/</p>

      <table>
        <thead>
          <tr>
            <td colspan="2"></td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Fecha de la conformidad</td>
            <td>28/06/2024</td>
          </tr>
          <tr>
            <td>Versión</td>
            <td>UNE-EN 301549:2022</td>
          </tr>
          <tr>
            <td>Uri</td>
            <td><a target="_blank"
                href="https://www.etsi.org/deliver/etsi_en/301500_301599/301549/03.02.01_60/en_301549v030201p.pdf">Descargar</a>
            </td>
          </tr>
          <tr>
            <td>Nivel de cumplimiento</td>
            <td>AA</td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <td>Alcance de la declaración</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Todo el contenido de la web pública</td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <td colspan="2">Páginas de la muestra</td>
          </tr>
          <tr>
            <td>Título</td>
            <td>Uri</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Inicio</td>
            <td>https://addaw.canaldenuncia.app/inicio</td>
          </tr>
          <tr>
            <td>Login</td>
            <td>https://addaw.canaldenuncia.app/login</td>
          </tr>
          <tr>
            <td>Declaración de accesibilidad</td>
            <td>https://addaw.canaldenuncia.app/declaracion-de-accesibilidad</td>
          </tr>
          <tr>
            <td>Ver denuncia</td>
            <td>https://addaw.canaldenuncia.app/ver-denuncia/3</td>
          </tr>

        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <td colspan="2">Tecnologías presentes</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>CSS</td>
            <td>http://www.w3.org/Style/CSS/specs/</td>
          </tr>
          <tr>
            <td>HTML5</td>
            <td>http://www.w3.org/TR/html5/</td>
          </tr>
          <tr>
            <td>WAI-ARIA</td>
            <td>https://www.w3.org/TR/wai-aria/</td>
          </tr>
          <tr>
            <td>javascript</td>
            <td>https://developer.mozilla.org/es/docs/Web/JavaScript</td>
          </tr>

        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <td>Herramientas utilizadas</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Browser DevTools/Inspector</td>
          </tr>
          <tr>
            <td>Google Lighthouse</td>
          </tr>
          <tr>
            <td>HTML Validation</td>
          </tr>
          <tr>
            <td>Keyboard only</td>
          </tr>
          <tr>
            <td>VoiceOver Screen Reader</td>
          </tr>
          <tr>
            <td>WAVE Browser Extension</td>
          </tr>
          <tr>
            <td>Adobe Color Contrast Analyzer</td>
          </tr>

        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <td>Agentes de usuario empleados</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Android Browser</td>
          </tr>
          <tr>
            <td>Chrome - Latest</td>
          </tr>
          <tr>
            <td>Edge - Latest</td>
          </tr>
          <tr>
            <td>Firefox - Latest</td>
          </tr>
          <tr>
            <td>IE11</td>
          </tr>
          <tr>
            <td>Opera - Latest</td>
          </tr>
          <tr>
            <td>Safari - Latest</td>
          </tr>

        </tbody>
      </table>
      <h2>
        <div>
          <span class="subtitulo">Contacto</span>
        </div>
      </h2>
      <p>Puede realizar comunicaciones sobre requisitos de accesibilidad (artículo 10.2.a) del RD 1112/2018 como por
        ejemplo:</p>
      <ul>
        <li>Informar sobre cualquier posible cumplimiento por parte de este sitio web</li>
        <li>Transmitir otras dificultades de acceso al contenido</li>
        <li>Formular cualquier otra consulta o sugerencia de mejora relativa a la accesibilidad del sitio web</li>
      </ul>
      <p>A través de la cuenta de correo <a href="mailto:admin@addaw.org">admin@addaw.org</a></p>
      <h2>
        <div>
          <span class="subtitulo">Descargar informes</span>
        </div>
      </h2>
      <table>

        <thead>
          <tr style="text-align: center">
            <th style="text-align: center">Identificador</th>
            <th style="text-align: center">Fecha</th>
            <th style="text-align: center">Auditor</th>
            <th style="text-align: center">Estado</th>
            <th style="text-align: center">Enlace</th>
          </tr>
        </thead>
        <tbody>
          <tr style="text-align: center">
            <td>1112</td>
            <td>03/05/24</td>
            <td>David Prieto González</td>
            <td>Parcialmente Conforme</td>
            <td><a href="https://addaw.org/webService/descargarInformePdf?id=1112" aria-label="Ver informe 1112">Ver
                informe</a></td>
          </tr>
          <tr style="text-align: center">
            <td>1127</td>
            <td>28/06/24</td>
            <td>David Prieto González</td>
            <td>Conforme</td>
            <td><a href="https://addaw.org/webService/descargarInformePdf?id=1127" aria-label="Ver informe 1127">Ver
                informe</a></td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>
</section>
<!--  Footer-->
<footer class="da-section">
  <div class="container text-white">
    <div class="row">
      <div class="col-md-4 col-lg-4">
        <p class="p-footer">&copy; 2024 Becompliance. {{ 'texto_42' | translate }}</p>
      </div>
      <div class="col-md-4 col-lg-4">
        <p class="p-footer"><a routerLink="/declaracion-de-accesibilidad" style="color: white">{{ 'texto_744' |
            translate }}</a></p>
      </div>
      <div class="col-md-4 col-lg-4">
        <p class="p-footer">CanalDenuncia.app v 3.3.6</p>
      </div>
    </div>
  </div>
</footer>
<!-- End  Footer -->