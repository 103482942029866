<section style="position: absolute;width: 100%;z-index: 1;" class="image-container">
  <div class="container" style="padding: 0;">
    <div class="board" style="width: 100% !important;">
      <button type="button" style="z-index:100;float: right;" class="btn btn-dark" (click)="hide();"aria-label="Cerrar modal">X</button>

      <!-- Navigation Area (Circular Tabs) -->
      <msw-navbar></msw-navbar>
      <!-- End Navigation Area (Circular Tabs) -->

      <!-- Content Area -->
      <ng-content></ng-content>
    </div>
  </div>
</section>
