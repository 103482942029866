<div class="contenedor" style="display: flex;">
  <a href="javascript:void(0);" class="nav-link-translation-right" (click)="showMenu()">
    {{ 'texto_153' | translate }}
  </a>
  <div class="nav-link-translation-left" >
    <mat-select #menu class="select" [(value)]="activeLang"  (selectionChange)="cambiarLenguaje($event.value)" (keydown)="onMenuKeyDown($event)" tabindex="-1">
      <mat-option *ngFor="let idioma of idiomas" [value]="idioma.id_idioma">
        <span style="display: flex; align-items: center;" >
          <img src="/assets/img/idiomas/{{ idioma.bandera }}" style="width: 30px; height: 20px; margin-right: 10px;">
          {{ idioma.n_idioma }}
        </span>
      </mat-option>
    </mat-select>
  </div>
</div>


