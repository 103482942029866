// search.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { Empresa } from '../model/empresa';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteWordService {
  PHP_API_SERVER = environment.baseUrl;
  empresas: Empresa[]; //Model empresa

  constructor(private httpClient: HttpClient) {
    this.searchCompanies();

  }
  //get the companies
  searchCompanies() {
    this.httpClient.get(`${this.PHP_API_SERVER}/api/getCompanies.php`).subscribe((empresa: Empresa[]) => {
      this.empresas = empresa;
    });
  }

  searchCompaniesByCharacter(caracter: string): Empresa[] {
    return this.empresas.filter(empresa => empresa.n_empresa.includes(caracter));
  }

  getAutocompleteSuggestions(query: string): Observable<any> {
    const companiesfound = this.searchCompaniesByCharacter(query);
    return of(companiesfound);
  }

  getAutocompleteSuggestionsByWord(query: string): any {
    // Iterates over the Json array and extracts the names
    const arrayNames: string[] = []; //Initialize a new array to store the names
    this.empresas.forEach(item => {
      arrayNames.push(item.n_empresa);
    });
    // Use the find method to search for the word
    const wordFound = arrayNames.find(word =>
      word === query
    );

    // Update status based on wheter the word was found or not
    if (wordFound) {
      return of(wordFound);
    } else {
      return of(false);
    }
  }
}
