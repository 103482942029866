import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { GestionUsuariosService } from 'src/app/dashboard/services/gestion-usuarios.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { Usuarios } from 'src/app/dashboard/models/usuarios';

@Component({
  selector: 'app-gestion-usuarios',
  templateUrl: './gestion-usuarios.component.html',
  styleUrls: ['./gestion-usuarios.component.css']
})
export class GestionUsuariosComponent implements OnInit {
  avatar = environment.baseAvatar+"avatar.png";
  users: Usuarios[] ;
  constructor(private apiService: GestionUsuariosService,private router: Router, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService) { }
  sociedad: string;

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.sociedad =this.auth.getNameSociedad();
    this.apiService.searchAllUsers().subscribe((usuarios: Usuarios[])=>{
      this.users = usuarios;
    });
  }

  eliminarUsuario(id_usuario: string){

    this.apiService.searchCountComplaintBySocietyUser(this.auth.getSociedad(), id_usuario).subscribe(
      res => {
        if(res == 200){
          this.apiService.deleteUser(id_usuario, this.auth.getSociedad()).subscribe(
            res => {
              if(res == 200){
                this.toastr.success(this.translate.instant('texto_260'), this.translate.instant('texto_261'));
              }else{
                this.toastr.error(this.translate.instant('texto_259'), this.translate.instant('texto_261'));
              }
              this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/dashboard/layout/usuarios']);
              });
            });
        }else{
            this.toastr.error(this.translate.instant('texto_258'), this.translate.instant('texto_261'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/dashboard/layout/usuarios']);
        });
      });

    }

  editarUsuario(id_usuario: string){
    this.router.navigate(['/dashboard/layout/user/', id_usuario]);
  }
}
