import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Abogado } from 'src/app/dashboard/models/abogado';
import { TipoDenunciaService } from 'src/app/dashboard/services/tipo-denuncia.service';
import { ReceptorObligatorio } from 'src/app/dashboard/models/receptorobligaorio';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-add-tipo-denuncia',
  templateUrl: './add-tipo-denuncia.component.html',
  styleUrls: ['./add-tipo-denuncia.component.css']
})
export class AddTipoDenunciaComponent implements OnInit {

  validatingForm: FormGroup;
  abogados: Abogado[];
  selectConflicto: string;
  receptoreso: ReceptorObligatorio[];
  constructor(private tipoDenunciaService: TipoDenunciaService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit() {
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    this.validatingForm = new FormGroup({
      signupFormModalName: new FormControl('', Validators.required),
      signupFormModalOrden: new FormControl('', [Validators.required, Validators.min(1), Validators.pattern(/^[1-9]\d*$/)]),
      signupFormModalId: new FormControl('', Validators.required),
      contactFormModalMessage: new FormControl('', []),
      abogado: new FormControl('', [
      ]),
    });

    this.tipoDenunciaService.searchLayerByUserSociety(this.auth.getSociedad()).subscribe((abogado: Abogado[]) => {
      this.abogados = abogado;
    });

    this.tipoDenunciaService.searchReceptorObligatoryByUserSociety(this.auth.getIdUser(), this.auth.getSociedad()).subscribe((users: ReceptorObligatorio[]) => {
      this.receptoreso = users;
    });
  }

  get signupFormModalName() {
    return this.validatingForm.get('signupFormModalName');
  }

  get signupFormModalId() {
    return this.validatingForm.get('signupFormModalId');
  }

  get signupFormModalOrden() {
    return this.validatingForm.get('signupFormModalOrden');
  }

  get contactFormModalMessage() {
    return this.validatingForm.get('contactFormModalMessage');
  }

  get abogado() {
    return this.validatingForm.get('abogado');
  }

  addTipoDenuncia() {
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    let id_gestor_conflicto = this.selectConflicto;
    if (id_gestor_conflicto == "" || id_gestor_conflicto == null) {
      id_gestor_conflicto = "";
    }

    // console.log(id_gestor_conflicto);

    this.tipoDenunciaService.addTypeComplaint(this.auth.getSociedad(), this.signupFormModalId.value, this.signupFormModalName.value, this.contactFormModalMessage.value, this.abogado.value.tipo_receptor, this.abogado.value.id, language, id_gestor_conflicto, this.signupFormModalOrden.value).subscribe(
      res => {
        if (res == 200) {
          this.toastr.success(this.translate.instant('texto_317'), this.translate.instant('texto_319'));
        } else {
          this.toastr.error(this.translate.instant('texto_318'), this.translate.instant('texto_319'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/dashboard/layout/tipo-denuncia']);
        });
      },
      err => {
        this.toastr.error(this.translate.instant('texto_318'), this.translate.instant('texto_319'));
      });

  }

  selectabogadoconlficto(event) {
    this.selectConflicto = event.value;
    // console.log("this.selectConflicto", this.selectConflicto);
  }

  public options: Object = {
    charCounterCount: true,
    toolbarButtons: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize', 'html', 'backgroundColor', 'textColor', 'alignLeft', 'alignCenter', 'alignRight', 'insertLink', '|', 'undo', 'redo'],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize', 'html', 'backgroundColor', 'textColor', 'alignLeft', 'alignCenter', 'alignRight', 'insertLink', '|', 'undo', 'redo'],
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],
    imageMaxSize: 5 * 1024 * 1024,
  };
}
