import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from '../model/datosDenuncia';
import { PlazosDenuncia } from '../model/plazosDenuncia';

@Component({
  selector: 'app-edit-plazos',
  templateUrl: './add-plazos.component.html',
  styleUrls: ['./add-plazos.component.css']
})
export class AddPlazosComponent implements OnInit {
  validatingForm: FormGroup; // Formulario reactivo para la validación de entradas
  @Input() denuncia: DatosDenuncia; // Input que recibe los datos de la denuncia
  plazos: PlazosDenuncia[]; // Lista para almacenar los datos de plazos de la denuncia
  plazoFaseInvestigacionF: string = ''; // String para almacenar el valor del plazo de investigación
  plazoFaseDecisorF: string = ''; // String para almacenar el valor del plazo del decisor
  plazoFaseComplianceF: string = ''; // String para almacenar el valor del plazo de compliance
  cargado: boolean = false;  

  constructor(
    private apiService: ApiService, // Servicio personalizado para llamadas API
    private auth: AuthService, // Servicio personalizado para autenticación
    private router: Router, // Servicio para la navegación
    private toastr: ToastrService, // Servicio para mostrar notificaciones
    private translate: TranslateService // Servicio para la internacionalización
  ) { }

  ngOnInit() {
    // Obtener el idioma del usuario y configurarlo
    let language = this.auth.getLanguage();
    if (language == "" || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    // Inicializar el formulario reactivo con validaciones
    this.validatingForm = new FormGroup({
      plazoFaseInvestigacion: new FormControl('', [Validators.required]),
      plazoFaseDecisor: new FormControl('', Validators.required),
      plazoFaseCompliance: new FormControl('', [Validators.required]),
    });

    // Obtener el ID de la denuncia desde la URL
    let url = window.location.href.split("/", 5);
    let idDenuncia = url[4];

    // Obtener los plazos de la denuncia mediante una llamada API
    this.apiService.searchInvestigatorByIdDenuncia(idDenuncia, '1').subscribe((investigator: PlazosDenuncia[]) => {
      this.plazos = investigator;
      if (this.plazos.length > 0) {
        if (this.plazos[0].id_denuncia != "") {
          this.cargado = true;
          this.plazoFaseInvestigacionF = this.plazos[0].plazo_investigacion;
          this.plazoFaseDecisorF = this.plazos[0].plazo_decisor;
          this.plazoFaseComplianceF = this.plazos[0].plazo_compliance;
        }
      } else {
        // Si no hay plazos cargados, usar los valores almacenados localmente
        this.plazoFaseInvestigacionF = localStorage.getItem("diasinves");
        this.plazoFaseDecisorF = localStorage.getItem("diasdecisor");
        this.plazoFaseComplianceF = localStorage.getItem("diascompliance");
      }
    });
  }

  // Getters para obtener los controles del formulario
  get plazoFaseInvestigacion() {
    return this.validatingForm.get('plazoFaseInvestigacion');
  }

  get plazoFaseDecisor() {
    return this.validatingForm.get('plazoFaseDecisor');
  }

  get plazoFaseCompliance() {
    return this.validatingForm.get('plazoFaseCompliance');
  }

  // Método para editar los plazos de la denuncia
  editPlazos() {
    // Validar que todos los campos del formulario estén llenos
    if (this.plazoFaseInvestigacion.value == "" || this.plazoFaseDecisor.value == "" || this.plazoFaseCompliance.value == "") {
      this.toastr.error(this.translate.instant('texto_323'), this.translate.instant('texto_274')); // Mostrar mensaje de error
    } else {
      // Realizar una llamada API para actualizar los plazos de la denuncia
      this.apiService.updateDeadlinesByIdComplaint(this.auth.getSociedad(), this.denuncia[0].id_denuncia, this.plazoFaseInvestigacion.value, this.plazoFaseDecisor.value, this.plazoFaseCompliance.value).subscribe(
        res => {
          if (res == 200) {
            this.toastr.success(this.translate.instant('texto_745'), this.translate.instant('texto_326')); // Mostrar mensaje de éxito
          } else {
            this.toastr.error(this.translate.instant('texto_746'), this.translate.instant('texto_326')); // Mostrar mensaje de error
          }
          // Refrescar la vista después de la actualización
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['editar-todas-denuncias/', this.denuncia[0].id_denuncia]);
          });
        });
    }
  }

  // Método para agregar días a una fecha dada
  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }
}
