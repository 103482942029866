import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Subject } from "rxjs/internal/Subject";

@Injectable({
   providedIn: 'root'
})
export class ReloadComponent {
 private isLoaddingSubject = new BehaviorSubject<boolean>(false);
 subject$ = this.isLoaddingSubject.asObservable();

 isLoadding$ = new Subject<boolean>(); //observable

 sendSignal(): void {
   this.isLoaddingSubject.next(true);
 }

}