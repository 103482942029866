<!--Aqui empieza el nuevo html para asignar decisor-->
<button style="background-image: url(/../../assets/img/botones/Boton_Asignar.png);" class="btn btn-rounded btn-cabecera"
  matTooltip="{{'texto_177' | translate }}" type="button" mdbBtn rounded="true" data-toggle="modal"
  data-target="#basicExample" (click)="frame.show()" mdbWavesEffect>
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="max-width: 100% !important;">
    <!--Content-->
    <div class="modal-content">

      <div class="modal-body mx-3" style="padding-right: 1rem; padding-left: 1rem;">
        <div class="card-header card-header-info">
          <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_406' | translate }}</h5>
        </div>
        <button type="button" style="z-index:100;float: right;margin-top: -2.8rem;" class="btn btn-dark"
          data-dismiss="modal" (click)="frame.hide()" aria-label="Cerrar modal">X</button>
        <form #validatingForm="ngForm" class="editForm" novalidate>
          <div class="row">
            <div class='col-xs-12 col-md-12' style="padding-left: 0rem; padding-right: 0rem">
              <div class="form-group">
                <label class="control-label">{{ 'texto_728' | translate }}</label> <br>
                <div style="display: flex; align-items: center; width: 100%;">
                  <div style="flex-grow: 1; margin: 0; margin-top: 1rem;">
                    <mat-form-field>
                      <mat-label>{{ 'texto_140' | translate }}</mat-label>
                      <mat-select [formControl]="selectDecisor" required>
                        <mat-option *ngFor="let item of decisores" [value]="item.id_user">
                          {{item.n_user}} {{item.ape_user}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <!-- <button (click)="asignarDecisor()" mdbBtn rounded="true" class="btn-primary waves-light"
                    mdbWavesEffect style="width: 100px;">{{ 'texto_60' | translate }}</button> -->
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class='col-xs-12 col-md-12'>
              <div class="form-group">
                <label class="control-label">{{ 'texto_729' | translate }}</label> <br>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <th colspan="2">{{ 'texto_728' | translate }}</th>
                      <th></th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item2 of decisores_select">
                        <td>{{item2.n_user}} {{item2.ape_user}}</td>
                        <td class="td-actions text-right" style="width:90px;">
                          <button (click)="deleteinves(item2.id_user)" type="button" rel="tooltip" title="Remove"
                            class="" style="background: none;color:red;border:none;">
                            <i class="material-icons">clear</i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center mt-3">
            <button data-dismiss="modal" (click)="asignarDecisor()" mdbBtn rounded="true"
              class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_60' | translate }}
            </button>
          </div>
        </form>

      </div>
      <!--/.Content-->
    </div>
  </div>
