import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment.prod';

/**
 * Service for encrypt Peer to Peer
 */

@Injectable({
  providedIn: 'root'
})
export class PeertopeerService {
  private key: string = environment.secretKey;

  constructor() { }

  encryptData(value: string): string {
    const key = CryptoJS.enc.Hex.parse(CryptoJS.SHA256(this.key).toString());
    const encrypted = CryptoJS.AES.encrypt(value, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.ZeroPadding
    });
    return encrypted.toString();
  }

  decryptData(encryptedValue: any): any {
    try {
      // Convertir la clave a un objeto WordArray
      const keyBytes = CryptoJS.enc.Hex.parse(
        CryptoJS.SHA256(this.key).toString(CryptoJS.enc.Hex)
      );

      // Decodificar el valor encriptado desde Base64
      const ciphertextBytes = CryptoJS.enc.Base64.parse(encryptedValue);

      // Desencriptar el valor usando AES y la clave
      const decrypted = CryptoJS.AES.decrypt(
        {
          ciphertext: ciphertextBytes
        },
        keyBytes,
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.ZeroPadding
        }
      );

      // Devolver el valor desencriptado como string
      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error('Error al desencriptar:', error);
      return null;
    }
  }


}
