import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReceptorService {



  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
  // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";
   constructor(private router: Router, private httpClient: HttpClient, private auth: AuthService) {}


  searchUsersReceptors(id_sociedad:string,id_tp_denuncia:string,idioma:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getUserReceptorBySociety.php`, {
      id_sociedad: id_sociedad,
      id_tp_denuncia: id_tp_denuncia,
      idioma: idioma,
    }, options);
  }
  searchUsersReceptorsRamdom(id_sociedad:string,id_tp_denuncia:string,idioma:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getUserReceptorRamdonBySociety.php`, {
      id_sociedad: id_sociedad,
      id_tp_denuncia: id_tp_denuncia,
      idioma: idioma,
    }, options);
  }
  searchUsersSocietyReceptors(id_sociedad:string, id_user:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getUserReceptorByUserSociety.php`, {
      id_sociedad: id_sociedad,
      id_user: id_user
    }, options);
  }
}
