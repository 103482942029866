import { Component, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { NavigationService } from '../shared/services/navigation.Service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-informacion-principal',
  templateUrl: './informacion-principal.component.html',
  styleUrls: ['./informacion-principal.component.css']
})
export class InformacionPrincipalComponent implements OnInit, OnDestroy {
  private navigateToSectionSubscription: Subscription;
  private focusButtonSubscription: Subscription;
  private focusUrlSubscription: Subscription;
  validatingForm: FormGroup;
  form: FormGroup;
  finalizar: any = {};
  version: string;
  boton1: string;
  docboton1: string;
  boton2: string;
  docboton2: string;
  boton3: string;
  docboton3: string;
  popUp: string;
  selectedLanguage: string = 'default';
  mostrarMensaje: boolean = true;
  terminos2: string;
  url: string;
  documento: string;

  politicaEnabled: string;
  urlpolitica: string;
  docpolitica: string;

  private subscriptions: Subscription[] = [];

  euskeraStyles = {
    'background-color': 'var(--main-color-bg)',
    'border-color': 'var(--main-color-bg)',
    'color': 'var(--color-txt-botones)',
    'text-transform': 'uppercase',
    'display': 'inline-block',
    'font-weight': '400',
    'text-align': 'center',
    'vertical-align': 'middle',
    'user-select': 'none',
    'border': '1px solid transparent',
    'padding': '0.375rem 0.75rem',
    'font-size': '0.87rem',
    'line-height': '0.85',
    'border-radius': '0.25rem'
  };
  normalStyles = {
    'background-color': 'var(--main-color-bg)',
    'border-color': 'var(--main-color-bg)',
    'color': 'var(--color-txt-botones)',
    'text-transform': 'uppercase',
    'display': 'inline-block',
    'font-weight': '400',
    'text-align': 'center',
    'vertical-align': 'middle',
    'user-select': 'none',
    'border': '1px solid transparent',
    'padding': '0.375rem 0.75rem',
    'font-size': '0.87rem',
    'line-height': '1.5',
    'border-radius': '0.25rem'
  };

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService,
    private navigationService: NavigationService,
    private elementRef: ElementRef
  ) {
    this.form = this.fb.group({
      firstName: ['', [Validators.pattern('^[A-Za-zÁÉÍÓÚáéíóúÑñ\\s]+$')]],
      lastName: ['', [Validators.pattern('^[A-Za-zÁÉÍÓÚáéíóúÑñ\\s]+$')]],
      subject: ['',],
      replyTo: ['', [Validators.email, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$')]],
      message: ['', Validators.required],
      terminos: [false, Validators.requiredTrue],
      enviarButton: [{ value: '', disabled: true }] // Control para el botón de enviar
    });
  }

  ngOnInit() {
    // Code for functionality "Saltar al contenido principal"
    // Subscribe to the observable that navigates to a specific section on the page.
    this.navigateToSectionSubscription = this.navigationService.navigateToSection$.subscribe(sectionId => {
      // Select the DOM element with the given section ID.
      const sectionElement = this.elementRef.nativeElement.querySelector(`#${sectionId}`);
      sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });

    // Subscribe to the observable that triggers focusing on a specific button (boton1).
    this.focusButtonSubscription = this.navigationService.focusButton$.subscribe(() => {
      this.setFocusOnButton();
    });

    // Subscribe to the observable that triggers focusing on a utl (a).
    this.focusUrlSubscription = this.navigationService.focusUrlSupport$.subscribe(() => {
      this.setFocusOnUrl();
    });

    // call to function for navigating between sections
    this.animacionHref();
    // Inicialización de las propiedades
    this.version = localStorage.getItem("version") || '';
    this.boton1 = localStorage.getItem("boton1") || '';
    this.docboton1 = localStorage.getItem("docboton1") || '';
    this.boton2 = localStorage.getItem("boton2") || '';
    this.docboton2 = localStorage.getItem("docboton2") || '';
    this.boton3 = localStorage.getItem("boton3") || '';
    this.docboton3 = localStorage.getItem("docboton3") || '';
    this.popUp = localStorage.getItem("popUp") || '';
    this.terminos2 = localStorage.getItem('terminos') || '';
    this.url = localStorage.getItem('url') || '';
    this.documento = localStorage.getItem('documento') || '';
    this.politicaEnabled = localStorage.getItem('politicaEnabled') || '';
    this.urlpolitica = localStorage.getItem('urlpolitica') || '';
    this.docpolitica = localStorage.getItem('docpolitica') || '';

    const langChangeSubscription = this.translate.onLangChange.subscribe((event) => {
      this.selectedLanguage = event.lang;
    });
    this.subscriptions.push(langChangeSubscription);


  }

  ngOnDestroy(): void {
    if (this.navigateToSectionSubscription) {
      this.navigateToSectionSubscription.unsubscribe();
    }
    if (this.focusButtonSubscription) {
      this.focusButtonSubscription.unsubscribe();
    }
    if (this.focusUrlSubscription) {
      this.focusUrlSubscription.unsubscribe();
    }
  }

  get firstName() {
    return this.form.get('firstName');
  }

  get lastName() {
    return this.form.get('lastName');
  }

  get subject() {
    return this.form.get('subject');
  }

  get replyTo() {
    return this.form.get('replyTo');
  }

  get message() {
    return this.form.get('message');
  }

  get terminos() {
    return this.form.get('terminos');
  }

  toggleButton() {

    // Redirige automáticamente al enlace cuando se marca el checkbox
    // if (this.form.get('terminos').value) {
    //   if(this.terminos2 == '1'){
    //     window.open(this.url, '_blank');
    //   } else {
    //     window.open(this.documento, '_blank');
    //   }
    // }
    if (this.form.get('terminos').value) {
      if(this.politicaEnabled == '1'){
        window.open(this.urlpolitica , '_blank');
      } else {
        window.open(this.docpolitica, '_blank');
      }
    }

    // Habilitar o deshabilitar el botón de enviar según el valor de 'terminos'
    if (this.form.get('terminos').value) {
      this.form.controls['enviarButton'].enable();
    } else {
      this.form.controls['enviarButton'].disable();
    }
  }

  enviarEmail() {
    if (this.form.valid && this.terminos.value) { // Check if the form is valid and if the terms have been accepted
      this.apiService.sendEmailSupport(
        this.firstName.value,
        this.lastName.value,
        this.subject.value,
        this.replyTo.value,
        this.message.value
      ).subscribe(
        res => {
          if (res === 200) {
            this.toastr.success(this.translate.instant('texto_255'), this.translate.instant('texto_257'));
          } else {
            this.toastr.error(this.translate.instant('texto_256'), this.translate.instant('texto_257'));
          }

          //window.location.reload();

        },
        err => {
          console.error('Error al enviar el formulario', err);
          this.toastr.error(this.translate.instant('texto_256'), this.translate.instant('texto_257'));
        }
      );
    } else {
      console.log('Formulario no válido o términos no aceptados');
      this.toastr.error(this.translate.instant('texto_253'), this.translate.instant('texto_243'));
    }
  }

  /**
  * Function for navigating between sections of the home page.
  */
  animacionHref() {
    this.navigationService.navigateToSection$.subscribe(sectionId => {
      // Select the DOM element with the given section ID
      const sectionElement = this.elementRef.nativeElement.querySelector(`#${sectionId}`);
      // Scroll the selected section into view smoothly, aligning it to the start of the viewport
      sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  }

  /**
  * Function to navigate to button 1 after pressing "Saltar al contenido principal"
  */
  setFocusOnButton() {
      // Select the button element within the section with ID 'boton1'
      const button = this.elementRef.nativeElement.querySelector('#boton1 button') as HTMLButtonElement;
      if (button) {
        button.focus();
      }
  }

  /**
  * Function to navigate to url after pressing "Saltar al contenido principal"
  * and when the buttons are disabled
  */
  setFocusOnUrl() {
      // Select the a element within the section with ID 'url'
      const a = this.elementRef.nativeElement.querySelector('#url') as HTMLAnchorElement;
      if (a) {
        a.focus();
      }
  }

}
