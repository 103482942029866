import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DenunciasRoutingModule } from './denuncias-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxWigModule } from 'ngx-wig';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { VerDenunciaComponent } from 'src/app/ver-denuncias/components/ver-denuncia/ver-denuncia.component';
import { ConsultarDenunciaComponent } from 'src/app/ver-denuncias/components/consultar-denuncia/consultar-denuncia.component';
import { ConsultarDenunciaGuard } from './guards/consultar-denuncia.guard';

@NgModule({
  declarations: [
    VerDenunciaComponent,
    ConsultarDenunciaComponent
  ],
  imports: [
    CommonModule,
    DenunciasRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    //MDBBootstrapModule.forRoot(),
    NgxWigModule,
    MatProgressBarModule


  ],
  exports:[
    ConsultarDenunciaComponent,
    VerDenunciaComponent,

  ],
  providers: [ConsultarDenunciaGuard]
})
export class DenunciasModule { }
