  import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PoliticaCookiesComponent } from './politica-cookies/politica-cookies.component';
import { TipoComponent } from 'src/app/enviar-denuncia/components/fase-1/tipo/tipo.component';
import { DenuncianteComponent } from 'src/app/enviar-denuncia/components/fase-2/denunciante/denunciante.component';
import { DatosDenunciaComponent } from 'src/app/enviar-denuncia/components/fase-3/datos-denuncia/datos-denuncia.component';
import { ArchivosComponent } from 'src/app/enviar-denuncia/components/fase-5/archivos/archivos.component';
import { WorkflowGuard } from './workflow/workflow-guard.service';
import { AuthGuard } from './auth-1/auth.guard';
import { AuthService } from './shared/services/auth.service';
import { LoginComponent } from './auth/components/login/login.component';
import { TerminadaDenunciaComponent } from './enviar-denuncia/components/fase-7/terminada-denuncia/terminada-denuncia.component';
import { AppComponent } from './app.component';
import { EditarDenunciaComponent } from './editar-denuncia/editar-denuncia.component';
import { TodasDenunciasComponent } from './dashboard/modules/complaint-management/components/todas-denuncias/todas-denuncias.component';
import { EditarTodasDenunciasComponent } from './editar-todas-denuncias/editar-todas-denuncias.component';
import { AddAudioRecorderComponent } from './add-audio-recorder/add-audio-recorder.component';
import { EmailTokenGuard } from './auth-1/email-token.guard';
import { InitComponent } from './init/init.component';
import { DenunciasComponent } from 'src/app/dashboard/modules/complaint-management/components/denuncias/denuncias.component';
import { AdminComponent } from './admin/admin.component';
import { DeclaracionAccesibilidadComponent } from './declaracion-accesibilidad/declaracion-accesibilidad/declaracion-accesibilidad.component';

const routes: Routes = [
  //MÓDULO DASHBOARD
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    children: [{
      path: '',
      loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    }]
  },

  //MÓDULO VER DENUNCIA
  {
    path: 'ver-denuncia',
    children: [{
      path: '',
      loadChildren: () => import('./ver-denuncias/denuncias.module').then(m => m.DenunciasModule)
    }]
  },

  //MÓDULO ENVIAR DENUNCIA
  {
    path: 'enviar-denuncia',
    children: [{
      path: '',
      loadChildren: () => import('./enviar-denuncia/enviar-denuncia.module').then(m => m.EnviarDenunciaModule)
    }]
  },

  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent, canActivate: [EmailTokenGuard] },
  { path: 'editar-denuncia/:id', component: EditarDenunciaComponent, canActivate: [AuthGuard] },
  { path: 'editar-todas-denuncias/:id', component: EditarTodasDenunciasComponent, canActivate: [AuthGuard] },
  { path: 'politica-cookies', component: PoliticaCookiesComponent },
  { path: 'inicio', component: AppComponent },
  { path: 'grabar-denuncia', component: AddAudioRecorderComponent },
  { path: 'declaracion-de-accesibilidad', component: DeclaracionAccesibilidadComponent },
  //{ path: 'validate-email', component: InitComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },

  // 6th Route
  { path: '**', component: LoginComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
  providers: [WorkflowGuard, AuthService, AuthGuard]
})
export class AppRoutingModule { }
