import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatePipe } from 'src/app/pipes/paginate.pipe';
import { SafePipe } from 'src/app/safe.pipe';
import { FooterComponent } from 'src/app/footer/footer.component';
import { TranslationComponent } from 'src/app/translation/translation.component';
import { MatSelectModule } from '@angular/material/select';
import { SpinnerComponent } from 'src/app/shared/spinner/spinner.component';
import { TranslateModule } from '@ngx-translate/core';
import { AddWitnessComponent } from 'src/app/add-witness/add-witness.component';
import { AddDocumentComponent } from 'src/app/add-document/add-document.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconsModule, MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgxWigModule } from 'ngx-wig';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatOptionModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LayoutRoutingModule } from '../dashboard/modules/layout/layout-routing.module';
import { IconsComponent } from './icons/icons.component';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { DragDropDirective } from '../drag-drop.directive';



@NgModule({
  declarations: [
    PaginatePipe,
    SafePipe,
    TranslationComponent,
    FooterComponent,
    SpinnerComponent,
    AddWitnessComponent,
    AddDocumentComponent,
    IconsComponent,
    DragDropDirective,


  ],
  imports: [
    CommonModule,
    MatSelectModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule,
    NgxWigModule,
    TranslateModule.forChild(),
    ClipboardModule,
    MatAutocompleteModule,
    MatInputModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    MatTabsModule,
    MatTabsModule,
    MatCheckboxModule,
    LayoutRoutingModule,
    MatDatepickerModule,
    MatCardModule,
    MatTableModule

  ],
  exports: [
    PaginatePipe,
    SafePipe,
    TranslationComponent,
    FooterComponent,
    TranslateModule,
    SpinnerComponent,
    AddWitnessComponent,
    AddDocumentComponent,
    MatTooltipModule,
    MDBBootstrapModule,
    MatDatepickerModule,
    ClipboardModule, MatAutocompleteModule,
    MatInputModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    MatTabsModule,
    MatTabsModule,
    MatCheckboxModule,
    LayoutRoutingModule,
    ReactiveFormsModule,
    IconsComponent,
    MatCardModule,
    MatTableModule,
    DragDropDirective


  ]
})
export class SharedModule { }
