import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-declaracion-accesibilidad',
  templateUrl: './declaracion-accesibilidad.component.html',
  styleUrls: ['./declaracion-accesibilidad.component.css']
})
export class DeclaracionAccesibilidadComponent implements AfterViewInit {

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const contenidoPrincipal = this.elementRef.nativeElement.querySelector('#contenido-principal');
    
    if (contenidoPrincipal) {
      contenidoPrincipal.scrollIntoView({  block: 'start' });
    }
  }

}
