import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FaseService {

    PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
    // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";
    constructor(private router: Router, private httpClient: HttpClient, private auth: AuthService) { }

    updatePhaseByUser(id_sociedad: string, id_fase: string, n_fase: string, idioma: string, comentario: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/Fases/updatePhase.php`, {
            id_sociedad: id_sociedad,
            id_fase: id_fase,
            n_fase: n_fase,
            idioma: idioma,
            comentario: comentario
        }, options);
    }

    searchPhaseByUser(id_sociedad: string, idioma: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/Fases/searchPhaseByUser.php`, {
            id_sociedad: id_sociedad,
            idioma: idioma
        }, options);
    }
}


