  <!-- End Navbar -->
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header card-header-primary" style="height: 3.75rem">
                  <h4 class="card-title ">{{sociedad}}</h4>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <th>ID.</th>
                            <th style="width: 300px;">N. {{ 'texto_71' | translate }}</th>
                            <th>{{ 'texto_63' | translate }}</th>
                            <th>{{ 'texto_490' | translate }}</th>
                            <th>{{ 'texto_690' | translate }}</th>
                            <th>{{ 'texto_754' | translate }}</th>
                            <th  style="width: 100px;"></th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of denuncias">
                            <td>{{ item.id_tp_denuncia }}</td>
                            <td>{{ item.nombre }}</td>
                            <td><div [innerHtml]="item.descripcion | safe: 'html'"></div></td>
                            <td>{{ item.n_gestor }}</td>
                            <td>{{ item.n_gestor_conflicto }}</td>
                            <td>{{item.orden}}</td>
                            <ng-template #elseBlock><td></td></ng-template>
                            <td class="td-actions text-right">
                              <button (click)="showComplaint(item)" type="button" rel="tooltip" title="Edit Complaint" class="" style="background: none;color:black;border:none;">
                                <i class="material-icons">edit</i>
                              </button>
                              <button (click)="deleteComplaint(item.id_tp_denuncia)" type="button" rel="tooltip" title="Remove" class="" style="background: none;color:red;border:none;">
                                <i class="material-icons">clear</i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br><br>
                      <div [style.display]="IsHiddenForm ? 'block' : 'none'"   class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                        <div class="modal-dialog cascading-modal modal-avatar modal-sm"  role="document">
                          <!--Content-->
                          <div class="modal-content" style="z-index: 200;" >
                            <div class="modal-body mx-3" style="padding-right: 1rem; padding-left: 1rem;">
                              <div class="card-header card-header-info">
                                <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_139' | translate }}</h5>
                            </div>

                            <button type="button" style="z-index:100;float: right;margin-top: -2.8rem;" class="btn btn-dark" data-dismiss="modal" (click) = "hideModal()" aria-label="Cerrar modal">X</button>


                              <div class="md-form mb-3">
                                <input type="text" class="form-control" [formControl]="signupFormModalId"
                                       mdbInput mdbValidate value={{idDenuncia}} readonly>
                                <label for="orangeForm-email">ID. {{ 'texto_71' | translate }}</label>
                                <!--<mdb-error
                                  *ngIf="signupFormModalId.invalid && (signupFormModalId.dirty || signupFormModalId.touched)">{{ 'texto_61' | translate }}
                                </mdb-error>
                                <mdb-success
                                  *ngIf="signupFormModalId.valid && (signupFormModalId.dirty || signupFormModalId.touched)">{{ 'texto_62' | translate }}
                                </mdb-success>-->
                              </div>

                              <!-- INPUT TIPO DENUNCIA -->
                              <div class="md-form mb-3">
                                <input type="text" class="form-control" [formControl]="signupFormModalName"
                                       mdbInput mdbValidate maxlength="255" value={{nombre}}>
                                <label for="orangeForm-name">N. {{ 'texto_71' | translate }}</label>
                                <mdb-error *ngIf="signupFormModalName.invalid && (signupFormModalName.dirty || signupFormModalName.touched)">
                                  {{ 'texto_61' | translate }}
                                </mdb-error>
                                <mdb-success *ngIf="signupFormModalName.valid && (signupFormModalName.dirty || signupFormModalName.touched)">
                                  {{ 'texto_62' | translate }}
                                </mdb-success>
                              </div>

                              <!-- INPUT ORDEN -->
                              <div class="md-form mb-3">
                                <input type="number" id="orangeForm-orden" class="form-control" [formControl]="signupFormModalOrden"
                                       mdbInput mdbValidate maxlength="255" value={{orden}} min="1" step="1">
                                <label for="orangeForm-orden">N. {{ 'texto_754' | translate }}</label>
                                <mdb-error *ngIf="signupFormModalOrden.invalid && (signupFormModalOrden.dirty || signupFormModalOrden.touched)">
                                  {{ 'texto_61' | translate }}
                                </mdb-error>
                                <mdb-success *ngIf="signupFormModalOrden.valid && (signupFormModalOrden.dirty || signupFormModalOrden.touched)">
                                  {{ 'texto_62' | translate }}
                                </mdb-success>
                              </div>

                              <div class="md-form">
                                  <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
                                  <textarea maxlength="4000" placeholder="{{ 'texto_63' | translate }}" type="text" mdbInput [formControl]="contactFormModalMessage" [(ngModel)]="comentario" class="md-textarea form-control" rows="5" value={{comentario}}></textarea>
                                </div>
                            </div>
                            <!--GESTOR-->
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="control-label">{{ 'texto_490' | translate }}</label> <br>
                                  <mat-form-field>
                                    <mat-label>{{ 'texto_140' | translate }}</mat-label>
                                    <mat-select [formControl]="abogado" [(ngModel)]="selectAdabogado" (selectionChange)="selectabogado($event)">
                                      <!--<mat-option>--</mat-option>-->
                                      <mat-option *ngFor="let item of receptoreso" [value]="item" >
                                        {{item.nombre}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                              </div>
                            </div>

                            <!--GESTOR CONFLICTO-->
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="control-label">{{ 'texto_690' | translate }}</label> <br>
                                  <mat-form-field>
                                    <mat-label>{{ 'texto_140' | translate }}</mat-label>
                                    <mat-select [(ngModel)]="selectConflicto" (selectionChange)="selectabogadoconlficto($event)">
                                      <!--<mat-option>--</mat-option>-->
                                      <mat-option *ngFor="let item of receptoreso" [value]="item.id" >
                                        {{item.nombre}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                              </div>
                            </div>
                          <div class="text-center mt-3">
                            <button (click)="updateComplaint(idDenuncia)" [disabled]="!this.validatingForm.valid" mdbBtn  rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_59' | translate }}
                            </button>
                          </div>

                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                  <app-add-tipo-denuncia></app-add-tipo-denuncia>
              </div>
            </div>
      </div>
    </div>
  </div>

