import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GestionExternaService {

  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
  // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";
   constructor(private router: Router, private httpClient: HttpClient, private auth: AuthService) {}

  searchLayerByUserSociety(id_sociedad:string,) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarAbogado.php`, {
      id_sociedad: id_sociedad,
    }, options);
  }
  searchComplaintByUserSocietyID(id_sociedad:string, id_tp_denuncia:string,seguro:boolean,idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTipoDenunciabyId.php`, {
      id_sociedad: id_sociedad,
      id_tp_denuncia: id_tp_denuncia,
      seguro: seguro,
      idioma: idioma,
    }, options);
  }
  searchComplaintByUserSociety(id_sociedad:string,idioma:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTipoDenuncia.php`, {
      id_sociedad: id_sociedad,
      idioma: idioma,
    }, options);
  }
  countComplaintByIdAbogados(id_sociedad:string,id_abogado:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/contarDenunciasPorAbogados.php`, {
      id_sociedad: id_sociedad,
      id_abogado: id_abogado
    }, options);
  }
  deleteLayerByUser(id_sociedad:string, id_abogado:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarAbogado.php`, {
      id_sociedad: id_sociedad,
      id_abogado: id_abogado
    }, options);
  }
  updateLayerByUser(id_sociedad:string, id_abogado:string, id_tp_denuncia:string, n_abogado:string, comentario:string, email:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarAbogado.php`, {
      id_sociedad: id_sociedad,
      id_abogado: id_abogado,
      id_tp_denuncia: id_tp_denuncia,
      n_abogado: n_abogado,
      comentario: comentario,
      email:email
    }, options);
  }

  // ADD-ABOGADO


  addLayer(id_sociedad:string, id_abogado:string, id_tp_denuncia:string, n_abogado:string, comentario:string, email:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearAbogado.php`, {
      id_sociedad: id_sociedad,
      id_abogado: id_abogado,
      id_tp_denuncia: id_tp_denuncia,
      n_abogado: n_abogado,
      comentario: comentario,
      email: email,
    }, options);
  }
}
