<button style="background-image: url(/../../assets/img/botones/Boton_Subir\ Archivo.png);"
    class="btn btn-finish btn-wd btn-cabecera" matTooltip="{{'texto_99' | translate }}" type="button" mdbBtn
    rounded="true" data-toggle="modal" data-target="#basicExample" (click)="frame.show()" mdbWavesEffect>
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="max-width: 100% !important;">
        <!--Content-->
        <div class="modal-content">

            <div class="modal-body mx-3" style="padding-left: 1rem; padding-right: 1rem;">
                <div class="card-header card-header-info">
                  <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_68' | translate }}</h5>
                </div>
                <button type="button" style="z-index:100;float: right;margin-top: -2.8rem;" class="btn btn-dark" data-dismiss="modal" (click)="frame.hide()"  aria-label="Cerrar modal">X</button>

                <div class="md-form">
                  <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
                  <label class="control-label" for="textoMCU">{{ 'texto_70' | translate }}</label><br><br>
                  <ngx-wig id="addDocument" [formControl]="contactFormModalMessage" [buttons]="'bold, italic, link, underline'" (keyup)="onKey($event)"></ngx-wig>
                  <span style="float: left;font-weight: bold;font-size: 0.75rem">{{contador}} {{ 'texto_69' | translate }} 4000</span>
                </div>
                <br>
                <div class="md-form mb-3" >
                  <form [formGroup]="myForm" style="width:100%;display:contens;color:whitewhite;margin-top: 20px;">
                    <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="dragFile($event)">
                      <input [(ngModel)]="archivos" formControlName="file" accept=".eml,.msg,.pptx,.pptm,.potx,.potm,.ppam,.ppsx,.ppsm,.xlsx,.xlsm,.xltx,.xltm,.xlam,.gif,.jpeg,.jpg,.png,video/*,audio/*,.pdf,.txt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" id="file" hidden type="file" #fileInput (change)="uploadFile($event)">
                    </div>
                    <div class="files-list" *ngFor="let file of files;let i= index">
                      <p> {{ file }} </p>
                      <button class="delete-file" (click)="deleteAttachment(i)">
                        <img src="../../assets/img/borrar-fichero.png">
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div class="text-center mt-3">
                <button (click)="submit()" mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_60' | translate }}</button>
              </div>
        </div>
    </div>
</div>
