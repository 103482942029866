import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DatosDenuncia } from 'src/app/model/datosDenuncia';
import { Usuarios } from 'src/app/dashboard/models/usuarios';
import { environment } from 'src/environments/environment';
import { PlazosDenuncia } from 'src/app/model/plazosDenuncia';

/**
 * This Component Changed Phase Complaint
 */

@Component({
  selector: 'app-change-phase-complaint',
  templateUrl: './change-phase-complaint.component.html',
  styleUrls: ['./change-phase-complaint.component.css'],
})

export class ChangePhaseComplaintComponent implements OnInit {
  validatingForm: FormGroup; //validation forms
  typecomplaints: string; //model tye complaint
  idComplaintUrl: string; //url id
  name: string = ''; //id user
  phaseNext: string = ''; //
  show: boolean = false; //
  id_role: string = '0';
  usuarios = []; //
  phasePrevious: string; // phase previous
  contador2 = 0 //character count
  state = 1; //disable button
  plazos: PlazosDenuncia[];

  @Input() denuncia: DatosDenuncia; //data complaint
  constructor(
    private actRoute: ActivatedRoute,
    private apiService: ApiService,
    private auth: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    //translation language
    let language = this.auth.getLanguage();
    if (language == '' || language == null) {
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    this.idComplaintUrl = this.actRoute.snapshot.params.id; //id Complaint
    //validations form
    this.validatingForm = new FormGroup({
      user: new FormControl('', [Validators.required]),
      comentario: new FormControl('', [Validators.required]),
    });
  }

  //get value type complaint
  get user() {
    return this.validatingForm.get('user');
  }

  //get value type complaint
  get comentario() {
    return this.validatingForm.get('comentario');
  }

  selected() {
    const fase = this.denuncia[0].id_fase;

    if (fase == '0' || fase == '11') {
      //fase de admisión o triaje

      // SHOW MESSAGE ERROR
      this.toastr.error('Error, no se puede retroceder');
      this.router
        .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
        .then(() => {
          this.router.navigate(['editar-denuncia/', this.idComplaintUrl]);
        });
    } else if (fase == '1') {
      //asiganción equipo
      this.id_role = '1'; //receptor
      this.phaseNext = 'texto_373';
    } else if (fase == '2') {
      //Investigación
      this.id_role = '1'; //investigador
      this.phaseNext = 'Asignación Equipo';
    } else if (fase == '3') {
      //Asignar desisor
      this.id_role = '2'; //Desisor
      this.phaseNext = 'Investigación';
    } else if (fase == '4') {
      //Desición
      this.id_role = '1'; //Receptor asigna desisor
      this.phaseNext = 'Asignar desisor';
    } else if (fase == '5') {
      //Compliance
      this.id_role = '3'; //Compliance
      this.phaseNext = 'Decisión';
    } else if (fase == '6') {
      //Finished
      this.id_role = '4'; //Compliance
    }

    if (this.id_role != '0') {
      //Search in database
      this.apiService
        .searchUserByComplaintType(this.id_role, this.auth.getSociedad())
        .subscribe((usuario: Usuarios[]) => {
          this.usuarios = usuario;
        });
      this.show = true; //show description
    }
  }

  changePhase() {
    const fase_retroceder = this.denuncia[0].id_fase - 1;
    let comentario = this.comentario.value;
    if(!comentario){
      comentario = 'Sin información';
    }

    //Service For Change Phase Complaint
    this.apiService
      .changeComplaintPhase(
        this.auth.getSociedad(),
        this.id_role,
        '',
        this.denuncia[0].id_denuncia,
        this.denuncia[0].id_fase,
        this.name,
        fase_retroceder.toString(),
        comentario
      )
      .subscribe((response) => {
        if(response == 200){
          this.toastr.success(
            this.translate.instant('texto_697'),
            this.translate.instant('texto_698'),
          );

          // add user only when complait is in phase 3(asignar decisor to return to investigación) 
          // or 5 (compliance to return decisión).
          if(this.denuncia[0].id_fase == '3' || this.denuncia[0].id_fase == '5'){
            // get deadlines. 
            this.apiService.searchPlazosByIdDenuncia(this.denuncia[0].id_denuncia).subscribe((plazos: PlazosDenuncia[]) => {
              this.plazos = plazos;
              // add user to table QSC_DENUNCIAS_PLAZOS only if it don't exists yet.
              this.apiService.createDeadlinesByIdComplaint(this.auth.getSociedad(), this.denuncia[0].id_denuncia, this.plazos[0].plazo_investigacion, this.plazos[0].plazo_decisor, this.plazos[0].plazo_compliance, this.name).subscribe(
                res => {
                  if (res == 200) {
                    this.toastr.success(this.translate.instant('texto_325'), this.translate.instant('texto_326'));
                  } else {
                    this.toastr.error(this.translate.instant('texto_298'), this.translate.instant('texto_297'));
                  }
                },
                err => {
                  this.toastr.error(this.translate.instant('texto_298'), this.translate.instant('texto_297'));
                }
              );

            });
          }

          //Send Mail
          let $clau = false;
          let clausulas_correo = localStorage.getItem("clausulas_correo");
          if(clausulas_correo == '1'){
            $clau = true;
          }
          let message = this.translate.instant('texto_700') + " " + this.denuncia[0].id_denuncia + " " + this.translate.instant('texto_701');
          let url2 = environment.base + "/#/editar-denuncia/"+this.denuncia[0].id_denuncia;
          this.apiService.sendEmail(this.name,this.translate.instant('texto_702'), message,url2,$clau, this.translate.instant('texto_702')).subscribe(
            res => {
                if(res == 200){
                  this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                }else{
                  this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
              }
              });
        } else {
          this.toastr.success(
            this.translate.instant('texto_699')
          );
        }
      });
    this.router
      .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['dashboard/complaint-management/mis-denuncias/']);
      });
  }

  onChangeUser(event) {
    //datos de la tabla QSC_CENTROS
    this.name = event.value;

    if(this.name){
      this.state = 0;
    }
  }

  //character counter
  onKey(event){
    var datos = document.getElementById("comentario");
    for(var i=0; i< 1; i++){
      var div = datos.getElementsByClassName('nw-editor__res')[i];
      this.contador2 = div.textContent.length
    }
   }
}
