import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

/**
 * INTERCEPTOR FROM LOGIN JWT (CATCH SESSION EXPIRE FOR REFRESH)
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private isRefreshing = false;

  constructor(private apiService: ApiService, private authService: AuthService, private router: Router, private authSrv: AuthService, private apiSrv: ApiService, private toastr: ToastrService, private translate: TranslateService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = request;
    const accessToken = this.authSrv.getToken();

    if (accessToken && this.isAuthUrl(request.url)) {
      authReq = this.addTokenHeader(request, accessToken);
    }

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && error.error.message === 'Token expired' && !this.isRefreshing) {
          this.isRefreshing = true;
          return this.apiSrv.refreshToken(this.authSrv.getRefreshToken()).pipe(
            switchMap((res: any) => {
              this.isRefreshing = false;
              localStorage.setItem("access_token2", res.accessToken);
              this.authSrv.setRefreshToken(res.refreshToken);

              // Llamada a meInfo() y manejo del resultado
              return this.apiService.meInfo().pipe(
                switchMap(userData => {
                  console.log(userData);
                  this.authService.sendToken(
                    userData.id_user,
                    userData.n_user,
                    userData.ape_user,
                    userData.mail_user,
                    userData.foto
                  );
                  authReq = this.addTokenHeader(request, this.authSrv.getToken());
                  return next.handle(authReq);
                })
              );
            }),
            catchError((refreshError) => {
              // Manejo de errores durante la renovación del token
              this.isRefreshing = false;
              localStorage.clear();
              this.router.navigate(["/inicio"]);
              location.reload();
              return throwError(refreshError);
            })
          );
        } else if (error.status === 404) {
          return throwError(error);
        } else if (error.status === 423) {
          return throwError(error);
        } else if (
          error.error.message === "Password Incorrect" ||
          error.error.message === "User or password incorrect" ||
          error.error.message === "User incorrect"
        ) {
          return throwError(error);
        }

        this.isRefreshing = false;
        localStorage.clear();
        this.router.navigate(["/inicio"]);
        location.reload();
        return throwError(error);
      })
    );
  }

  private isAuthUrl(url: string) {
    const withoutAuthPaths = ['login', 'refresh-token'];
    return !withoutAuthPaths.some(path => url.includes(path));
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
  }
}
