import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnviarDenunciaRoutingModule } from './enviar-denuncia-routing.module';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SharedModule } from 'src/app/shared/shared.module';
import { TipoComponent } from 'src/app/enviar-denuncia/components/fase-1/tipo/tipo.component';
import { DenuncianteComponent } from 'src/app/enviar-denuncia/components/fase-2/denunciante/denunciante.component';
import { DatosDenunciaComponent } from 'src/app/enviar-denuncia/components/fase-3/datos-denuncia/datos-denuncia.component';
import { FormDataService } from 'src/app/enviar-denuncia/services/formData.service';
import { ArchivosComponent } from 'src/app/enviar-denuncia/components/fase-5/archivos/archivos.component';
import { TerminadaDenunciaComponent } from 'src/app/enviar-denuncia/components/fase-7/terminada-denuncia/terminada-denuncia.component';
import { ReceptorComponent } from 'src/app/enviar-denuncia/components/fase-1/receptor/receptor.component';
import { PersonalComponent } from 'src/app/enviar-denuncia/components/fase-4/personal/personal.component';
import { FinalizarDenunciaComponent } from 'src/app/enviar-denuncia/components/fase-6/finalizar-denuncia/finalizar-denuncia.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LayoutFasesComponent } from 'src/app/enviar-denuncia/templates/layout-fases/layout-fases.component';
import { NavbarComponent } from 'src/app/enviar-denuncia/templates/navbar/navbar.component';
import { DatosService } from './services/datos.service';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    TipoComponent,
    DenuncianteComponent,
    ReceptorComponent,
    PersonalComponent,
    DatosDenunciaComponent,
    ArchivosComponent,
    TerminadaDenunciaComponent,
    FinalizarDenunciaComponent,
    LayoutFasesComponent,
    NavbarComponent,
    //IconsComponent,

  ],
  imports: [
    CommonModule,
    EnviarDenunciaRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    MatTooltipModule,
    MatPaginatorModule,
    MDBBootstrapModule,
    SharedModule,
    MatCheckboxModule,
    MatIconModule



  ],
  providers: [
    { provide: FormDataService, useClass: FormDataService },
    { provide: DatosService, useClass: DatosService }
  ]
})
export class EnviarDenunciaModule { }
