import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CentrosService } from 'src/app/dashboard/services/centros.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Empresa } from 'src/app/dashboard/models/empresa';
@Component({
  selector: 'app-add-center',
  templateUrl: './add-center.component.html',
  styleUrls: ['./add-center.component.css']
})
export class AddCenterComponent implements OnInit {
  validatingForm: FormGroup;
  empresas: Empresa[];
  sociedad: string;
  constructor(private centersService: CentrosService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }
  IsHiddenForm: boolean =  false;

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }

    this.translate.setDefaultLang(language);
    this.validatingForm = new FormGroup({
      signupFormModalName: new FormControl(''),
      signupFormModalId: new FormControl(''),
      signupFormModalidEmpresa: new FormControl(''),
      contactFormModalMessage: new FormControl('', []),
    });

    this.sociedad = this.auth.getNameSociedad();
    //Select a QSC_EMPRESAS
    this.centersService.searchCompanyByUser(this.auth.getSociedad()).subscribe((empresas: Empresa[])=>{
      this.empresas = empresas;
    });
  }

  //NOMBRE CENTRO
  get signupFormModalName() {
    return this.validatingForm.get('signupFormModalName');
  }

  //ID CENTRO
  get signupFormModalId() {
    return this.validatingForm.get('signupFormModalId');
  }

  //COMENTARIOS
   get contactFormModalMessage() {
     return this.validatingForm.get('contactFormModalMessage');
   }

   //ID EMPRESA
  get signupFormModalidEmpresa() {
    return this.validatingForm.get('signupFormModalidEmpresa');
  }

  addCenter(){
    this.centersService.addCenter(this.auth.getSociedad(), this.signupFormModalId.value, this.signupFormModalName.value, this.signupFormModalidEmpresa.value, this.contactFormModalMessage.value).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_683'), this.translate.instant('texto_684'));
        }else{
          this.toastr.error(this.translate.instant('texto_685'), this.translate.instant('texto_684'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/dashboard/layout/centros']);
       });
    },
    err =>{
      this.toastr.error(this.translate.instant('texto_685'), this.translate.instant('texto_188'));
    });
  }

  hideModal(){
    this.IsHiddenForm = false;
  }

}
