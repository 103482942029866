import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from '../model/datosDenuncia';
import { PlazosDenuncia } from '../model/plazosDenuncia';
import { Historico } from '../model/historico';
import { environment } from "../../environments/environment";
import { DatePipe } from '@angular/common';
import { Rol } from '../model/rol';

@Component({
  selector: 'app-enviar-denuncia-investigador',
  templateUrl: './enviar-denuncia-investigador.component.html',
  styleUrls: ['./enviar-denuncia-investigador.component.css'],
  providers:[DatePipe]
})
export class EnviarDenunciaInvestigadorComponent implements OnInit {
  historico: Historico[];
  validatingForm: FormGroup;
  IsHiddenForm: boolean =  false;
  plazos: PlazosDenuncia[];
  roles: Rol[];
  @Input() denuncia: DatosDenuncia;
  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService, private datepipe: DatePipe) { }

  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingForm = new FormGroup({
      enviarDenuncia: new FormControl('', [])
    });
  }

  get enviarDenuncia() {
    return this.validatingForm.get('enviarDenuncia');
  }
//   validar() {
//     if (this.enviarDenuncia.value == true) {
//         this.apiService.searchInvestigatorByIdDenuncia(this.denuncia[0].id_denuncia, '2').subscribe((investigators: PlazosDenuncia[]) => {
//             this.plazos = investigators;
//             if (this.plazos.length > 0) {
//                 this.apiService.updateComplaintById(this.denuncia[0].id_denuncia, "2", this.auth.getSociedad(), "", "").subscribe(
//                     res => {
//                         if (res == 200) {
//                             this.plazos.forEach(investigador => {
//                                 let $clau = false;
//                                 let clausulas_correo = localStorage.getItem("clausulas_correo");
//                                 if (clausulas_correo == '1') {
//                                     $clau = true;
//                                 }
//                                 let fecha = new Date();
//                                 fecha.setDate(fecha.getDate() + parseInt(investigador.plazo_investigacion));
//                                 let _date = this.datepipe.transform(fecha, 'dd-MM-yyyy');
//                                 let url = environment.base + "/#/editar-denuncia/" + this.denuncia[0].id_denuncia;

//                                 let message = this.translate.instant('texto_576') + " " + investigador.id_usuario + this.translate.instant('texto_592') + this.denuncia[0].id_denuncia + this.translate.instant('texto_614') + _date;
//                                  // Depurar el mensaje de correo electrónico
//                                 console.log('Mensaje de correo electrónico:', message);
//                                 console.log('Plazos:', this.plazos);
//                                 this.apiService.sendEmail(investigador.mail_user, this.translate.instant('texto_594') + this.denuncia[0].id_denuncia, message, url, $clau, this.translate.instant('texto_615')).subscribe(
//                                     res => {
//                                         if (res == 200) {
//                                             this.toastr.success(this.translate.instant('texto_273'), this.translate.instant('texto_274'));
//                                         } else {
//                                             this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
//                                         }
//                                     });
//                             });
//                         } else {
//                             this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
//                         }
//                     });
//             } else {
//                 this.toastr.error(this.translate.instant('texto_275'), this.translate.instant('texto_274'));
//                 this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
//                     this.router.navigate(['editar-denuncia/', this.denuncia[0].id_denuncia]);
//                 });
//             }
//         });
//     }
// }


  validar(){
    if(this.enviarDenuncia.value == true){

      this.apiService.searchInvestigatorByIdDenuncia(this.denuncia[0].id_denuncia, '2').subscribe((investigator: PlazosDenuncia[])=>{
        this.plazos = investigator;
        if(this.plazos.length > 0 ){
          /*this.apiService.disableUserByIdComplaint(this.denuncia[0].id_denuncia).subscribe(
          res => {
            if(res == 200){*/

              this.apiService.updateComplaintById(this.denuncia[0].id_denuncia, "2", this.auth.getSociedad(),"","").subscribe(
                res => {
                  if(res == 200){
                    for(let i=0;i<this.plazos.length;i++){
                     /* this.apiService.createHistoryComplaintById(this.denuncia[0].id_denuncia,"2",this.auth.getSociedad(),this.plazos[i].id_usuario).subscribe(
                        res => {
                          if(res == 200){*/
                            this.apiService.createUserByIdComplaint(this.auth.getSociedad(), this.plazos[i].id_usuario, this.denuncia[0].id_denuncia,"2", '1','1', false).subscribe(
                              res => {
                                if(res == 200){
                                  //this.toastr.success(this.translate.instant('texto_273'), this.translate.instant('texto_274'));
                                }else{
                                 // this.toastr.error(this.translate.instant('Error al asignado el investigador'), this.translate.instant('texto_274'));
                                }
                              });

                              let $clau = false;
                              let clausulas_correo = localStorage.getItem("clausulas_correo");
                              if(clausulas_correo == '1'){
                                $clau = true;
                              }

                              let fecha = new Date(); // ó new Date(valor);
                              let plazo = this.plazos[0].plazo_investigacion;
                              fecha.setDate(fecha.getDate() + parseInt(plazo));
                              let _date =this.datepipe.transform(fecha, 'dd-MM-yyyy');
                              let url = environment.base + "/#/editar-denuncia/"+this.denuncia[0].id_denuncia;
                              let message = this.translate.instant('texto_576') + " "  +  this.plazos[i].mail_user  + this.translate.instant('texto_592') + " "  + this.denuncia[0].id_denuncia  + " "  + this.translate.instant('texto_614') + " "  +_date;
                              this.apiService.sendEmail(this.plazos[i].mail_user,this.translate.instant('texto_594')+this.denuncia[0].id_denuncia, message,url,$clau, this.translate.instant('texto_615')).subscribe(
                                res => {
                                  if(res == 200){
                                    this.toastr.success(this.translate.instant('texto_273'), this.translate.instant('texto_274'));
                                  }else{
                                    this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                                  }
                              });
                              if(i == 0){
                                  if(this.denuncia[0].denunciante_correo != ""){
                                    let fecha = new Date(); // ó new Date(valor);
                                    let plazo = this.plazos[0].plazo_investigacion;
                                    fecha.setDate(fecha.getDate() + parseInt(plazo));
                                    let _date =this.datepipe.transform(fecha, 'dd-MM-yyyy');
                                    let url = environment.base + "/#/ver-denuncia/consultar-denuncia";
                                    // let message = this.translate.instant('texto_576') + " " +  this.denuncia[0].denunciante_nombre  + " "+this.denuncia[0].denunciante_apellidos + this.translate.instant('texto_604') + " "  + this.denuncia[0].id_denuncia + " "  + this.translate.instant('texto_616')+ " "  +_date;
                                    let message = this.translate.instant('texto_576') + " " +  this.denuncia[0].denunciante_nombre  + " "+this.denuncia[0].denunciante_apellidos + this.translate.instant('texto_604') + " "  + this.denuncia[0].id_denuncia + " "  + this.translate.instant('texto_616');
                                    this.apiService.sendEmail(this.denuncia[0].denunciante_correo,this.translate.instant('texto_594') +this.denuncia[0].id_denuncia, message,url,$clau, this.translate.instant('texto_598')).subscribe(
                                      res => {
                                        if(res == 200){
                                          this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                                        }else{
                                          this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                                        }
                                    });
                                  }else{
                                    //this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                                  }
                                }
                         /* }else{
                           // this.toastr.error(this.translate.instant('Error al asignar el investigador'), this.translate.instant('texto_274'));
                          }
                        },
                        err =>{
                          this.toastr.error(this.translate.instant('Error al asignar el investigador'), this.translate.instant('texto_274'));
                        });*/

                        }
                       }
                    });
                    //let role = this.auth.getRole();
                    let role = "";
                    this.apiService.searchRoleByUserSociety('setUser',this.auth.getSociedad(),'1').subscribe((roles: Rol[])=>{
                      this.roles = roles;
                      for(let i=0;i<this.roles.length;i++){
                        role = role + "," +this.roles[i].n_rol;
                      }
                    if(role.includes("INVESTIGADOR") && this.plazos[0].id_usuario == this.auth.getIdUser()){
                      //console.log("INV " + this.plazos[0].id_usuario + '---' + this.auth.getIdUser());
                      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                        this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
                      });
                    }else{
                      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                          this.router.navigate(['dashboard/complaint-management/mis-denuncias']);
                      });
                    }

        /*    }else{
              this.toastr.error(this.translate.instant('texto_196'), this.translate.instant('texto_192'));
            }
          });*/
        });
        }else{
          this.toastr.error(this.translate.instant('texto_275'), this.translate.instant('texto_274'));
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
          });
        }
      });


    }

}

}
