import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MedidaCautelarService } from 'src/app/dashboard/services/medida-cautelar.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-add-medida-cautelar',
  templateUrl: './add-medida-cautelar.component.html',
  styleUrls: ['./add-medida-cautelar.component.css']
})
export class AddMedidaCautelarComponent implements OnInit {

  validatingForm: FormGroup;
  constructor(private apiService: MedidaCautelarService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    this.validatingForm = new FormGroup({
      signupFormModalName: new FormControl('', Validators.required),
      signupFormModalId: new FormControl('', Validators.required),
      contactFormModalMessage: new FormControl('', []),
      abogado: new FormControl('', [
      ]),
    });
  }

  get signupFormModalName() {
    return this.validatingForm.get('signupFormModalName');
  }

  get signupFormModalId() {
    return this.validatingForm.get('signupFormModalId');
  }

  get contactFormModalMessage() {
    return this.validatingForm.get('contactFormModalMessage');
  }


  addMedidaCautelar(){
  let language = this.auth.getLanguage();
  if(language == "" || language == null){
    language = 'es';
  }
  this.translate.setDefaultLang(language);

    this.apiService.addCaution(this.auth.getSociedad(), this.signupFormModalId.value, this.signupFormModalName.value, this.contactFormModalMessage.value,language).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_320'), this.translate.instant('texto_322'));
        }else{
          this.toastr.error(this.translate.instant('texto_321'), this.translate.instant('texto_322'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/dashboard/layout/medida-cautelar']);
        });
    },
    err =>{
      this.toastr.error(this.translate.instant('texto_321'), this.translate.instant('texto_322'));
    });
  }



}
