import { Component, OnInit, ViewChild } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { AuthService } from 'src/app/shared/services/auth.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-prueba-correo',
  templateUrl: './prueba-correo.component.html',
  styleUrls: ['./prueba-correo.component.css']
})
export class PruebaCorreoComponent implements OnInit {
  validatingFormMail: FormGroup;
  condition= true;
  email: string;
  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit() {
    let language = this.auth.getLanguage();
    this.condition= true;
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingFormMail = new FormGroup({
      email: new FormControl('',[Validators.email, Validators.required])
    });
  }


  enviarEmailPrueba(){

    let error = false;
    this.email = (document.getElementById("email") as HTMLInputElement).value;
    if(this.email == "" || !this.isMail(this.email)){
      error = true;
    }

    let $clau = false;
    let clausulas_correo = localStorage.getItem("clausulas_correo");
    //console.log(clausulas_correo);
    if(clausulas_correo == '1'){
      $clau = true;
    }

    let message = "Esto es un correo de prueba enviado desde canalDenuncia.app";
    this.apiService.sendEmail(this.email,'Prueba CanalDenuncia.app', message,'',$clau, "Prueba CanalDenuncia.app").subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
        }else{
          this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/dashboard/layout/advanced-setting']);
        });
    });
  }

  isMail(str){
    return typeof str==='string' && /^[\w+\d+._]+\@[\w+\d+_+]+\.[\w+\d+._]{2,8}$/.test(str);
}
}
