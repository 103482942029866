import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Canal } from 'src/app/enviar-denuncia/models/canal';
import { DatosDenuncia } from 'src/app/enviar-denuncia/models/datosDenuncia';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { EnviarDenunciaService } from 'src/app/enviar-denuncia/services/enviar-denuncia.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-terminada-denuncia',
  templateUrl: './terminada-denuncia.component.html',
  styleUrls: ['./terminada-denuncia.component.css']
})
export class TerminadaDenunciaComponent implements OnInit {
  title = 'texto_143'
  pin_code: string;
  canal: Canal;
  IsAnonimo: boolean = false;
  denuncia: DatosDenuncia[];
  idDenuncia: string = '';
  value_pin_code: any;
  value_identificador: any;
  copied: boolean = false;
  language: string;

  constructor(
    private router: Router,
    private actRoute: ActivatedRoute,
    private apiService: ApiService,
    private enviarDenunciaService: EnviarDenunciaService,
    private auth: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private titleService: Title) {
    // Set title based on selected language
    this.translate.get('texto_359', { value: this.language }).subscribe((text: string) => {
      this.titleService.setTitle(text);
    });
  }

  ngOnInit() {
    if (window.history.state.navigationId == 2) {
      window.location.reload();
    } else {
      let language = this.auth.getLanguage();
      if (language == "" || language == null) {
        language = 'es';
      }
      let pin = this.actRoute.snapshot.params.id;
      this.pin_code = pin.substring(5, 20);
      this.value_pin_code = this.pin_code;
      this.apiService.searchComplaintByPin(this.pin_code, language).subscribe((denuncia: DatosDenuncia[]) => {
        this.denuncia = denuncia;
        this.idDenuncia = this.denuncia[0].id_denuncia;
        this.value_identificador = this.idDenuncia;
        if (this.denuncia[0].denunciante_correo == "") {
          this.IsAnonimo = true;
        }
      });

      this.enviarDenunciaService.searchCanal().subscribe((canal: Canal) => {
        this.canal = canal;
        if (this.canal[0].valor == "1") {
          this.IsAnonimo = true;
        }
      });

    }
  }

  consultar() {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/ver-denuncia/consultar-denuncia']);
    });

  }

  inicio() {
    this.auth.logoutSimple();
    /*window.location.reload();*/
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/inicio']);
    });
  }

  hide() {
    this.auth.logoutSimple();
    // //window.location.reload();
    this.router
      .navigateByUrl('/RefreshComponent', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['/inicio']);
      });
  }

  onCopied() {
    this.toastr.success(this.translate.instant('texto_718'));
  }

}
