import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsultarDenunciaService {

  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
 // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";
  constructor(private router: Router, private httpClient: HttpClient, private auth: AuthService) {}

  // Propiedad para validar que se haya ingresado el pin y el id de la denuncia a consultar
  isLoggedInComplaint: boolean = false;

  searchComplaintByPinId(pin_code:string,id_complaint:string, idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciaByPinId.php`, {
      pin_code: pin_code,
      id_complaint: id_complaint,
      idioma: idioma
    }, options);
  }
}
