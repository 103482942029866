import { ChangeDetectorRef, Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';
import { Fase } from 'src/app/dashboard/models/fase';
import { ApiService } from 'src/app/shared/services/api.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FaseService } from 'src/app/dashboard/services/fase.service';


@Component({
  selector: 'app-show-phase',
  templateUrl: './show-phase.component.html',
  styleUrls: ['./show-phase.component.css']
})
export class ShowPhaseComponent implements OnInit {
  sociedad: string;
  fases: Fase[];
  allFases: Fase[] = []; // Mantener una copia de todos los centros
  validatingForm: FormGroup;
  currentPage = 1;
  pageSize = 10; // Tamaño de página deseado, puedes ajustarlo según tus necesidades.
  totalItems = 0;
  id_fase: string;
  n_fase: string;
  comentario: string;


  constructor(private faseService: FaseService, private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService, private changeDetectorRefs: ChangeDetectorRef,private translate: TranslateService) { }
  IsHiddenForm: boolean =  false;

  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.sociedad = this.auth.getNameSociedad();
    //Select a QSC_CENTROS
    this.loadPhases();

    //FORM VALIDATE
    this.validatingForm = new FormGroup({
      signupFormModalName: new FormControl(''),
      signupFormModalId: new FormControl(''),
      contactFormModalMessage: new FormControl('', [])
    });

  }

  loadPhases() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    this.faseService.searchPhaseByUser(this.auth.getSociedad(), language).subscribe((phases: Fase[]) => {
      //console.log(phases);
      this.fases = phases;
      this.allFases = phases;
      this.totalItems = phases.length;
      this.updateCurrentPageCentros(); // Actualizar los centros de la página actual
    });
  }

  updateCurrentPageCentros() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    this.fases = this.allFases.slice(startIndex, startIndex + this.pageSize);
  }

  getCurrentPageCentros(): Fase[] {
    return this.fases;
  }

  onPageChange(event: any) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.loadPhases(); // Vuelve a cargar los datos cuando cambia la página
  }

  //Name Phase
   get signupFormModalName() {
    return this.validatingForm.get('signupFormModalName');
  }

  //ID Phase
  get signupFormModalId() {
    return this.validatingForm.get('signupFormModalId');
  }

  //Comentary
   get contactFormModalMessage() {
     return this.validatingForm.get('contactFormModalMessage');
   }


    /**
   * ACTUALIZAR CENTRO
   * @param id_fase
   */
    updatePhase(id_fase:string){
      let language = this.auth.getLanguage();
      if(language == "" || language == null){
        language = 'es';
      }
      this.translate.setDefaultLang(language);

      let name = "";
      if(this.signupFormModalName.value){
        name = this.signupFormModalName.value;
      } else {
        name = this.n_fase;
      }

      let commentary = "Sin comentarios";
      console.log(this.contactFormModalMessage.value);
      if(this.contactFormModalMessage.value){
        commentary = this.contactFormModalMessage.value;
      }

      this.faseService.updatePhaseByUser(this.auth.getSociedad(), id_fase, name, language, commentary).subscribe(
        res => {
          if(res == 200){
            this.toastr.success(this.translate.instant('texto_708'), this.translate.instant('texto_710'));
          }else{
            this.toastr.error(this.translate.instant('teato_709'), this.translate.instant('texto_710'));
          }
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/dashboard/layout/phases']);
          });
      });
    }

  /**
   * MOSTRAR DATOS
   * @param phase
   */
  showCenter(phase:Fase){
    this.IsHiddenForm = true;
    this.id_fase = phase.id_fase;
    this.n_fase = phase.n_fase;
    this.comentario = phase.comentario;
  }

  /**
   * OCULTAR MODAL
  */
  hideModal(){
    this.IsHiddenForm = false;
  }
}
