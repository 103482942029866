import { Injectable } from '@angular/core';
import { SessionInteruptService } from 'session-expiration-alert';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../shared/services/auth.service';
import { SessionTimerService } from 'session-expiration-alert';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppSessionInteruptService extends SessionInteruptService {
  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
  constructor(private readonly httpClient: HttpClient,private auth: AuthService,public sessionTimer: SessionTimerService) {
    super();
  }
  continueSession() {
    console.log(`I issue an API request to server.`);
  }
  stopSession() {
    this.sessionTimer.stopTimer();
    console.log(`I logout.`);
    this.auth.logoutSimple();
    //this.router.navigate(['/inicio']);
    window.location.reload();
  }
}
