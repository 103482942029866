import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TipoRelacionService {

    PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
    // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";
    constructor(private router: Router, private httpClient: HttpClient, private auth: AuthService) { }

    updateRelationByUser(id_sociedad: string, id_tp_relacion: string, nombre: string, comentario: string, idioma: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarTipoRelacion.php`, {
            id_sociedad: id_sociedad,
            id_tp_relacion: id_tp_relacion,
            nombre: nombre,
            comentario: comentario,
            idioma: idioma
        }, options);
    }

    deleteRelationTypeByUser(id_sociedad: string, id_tp_relacion: string, idioma: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarTipoRelacion.php`, {
            id_sociedad: id_sociedad,
            id_tp_relacion: id_tp_relacion,
            idioma: idioma
        }, options);
    }

    searchComplaintBySocietyRelation(id_sociedad: string, id_tp_relacion: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasPorTipoRelacionSociedad.php`, {
            id_sociedad: id_sociedad,
            id_tp_relacion: id_tp_relacion,

        }, options);
    }

    searchRelationTypeByUserSociety(id_sociedad: string, idioma: string) {
        const headers = { 'Authorization': 'Bearer ' + this.auth.getToken() }
        let options = { headers: headers };

        return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTipoRelacion.php`, {
            id_sociedad: id_sociedad,
            idioma: idioma
        }, options);
    }

    addRelationType(id_sociedad:string, id_tp_relacion:string, nombre:string, comentario:string,idioma: string) {
        const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
        let options = { headers: headers };
    
        return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearTipoRelacion.php`, {
          id_sociedad: id_sociedad,
          id_tp_relacion: id_tp_relacion,
          nombre: nombre,
          comentario: comentario,
          idioma: idioma
        }, options); 
      }


}
