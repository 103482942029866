import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-layout-fases',
  templateUrl: './layout-fases.component.html',
  styleUrls: ['./layout-fases.component.css']
})
export class LayoutFasesComponent implements OnInit {

  constructor( private router: Router) { }

  ngOnInit(): void {
  }

  hide() {
    //this.auth.logoutSimple();
    window.location.reload();
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/inicio']);
    });
  }

}
