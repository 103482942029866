<button  style="background-image: url(/../../assets/img/botones/Boton_Regresar.png);" class="btn btn-rounded btn-cabecera" matTooltip="{{'texto_716' | translate }}" type="button" mdbBtn rounded="true" data-toggle="modal" data-target="#basicExample"
        (click)="frame.show()" mdbWavesEffect>
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="max-width: 100% !important;">
    <!--MODAL THAT SHOW CHANGE TYPE COMPLAINT-->
    <div class="modal-content">
        <div class="modal-body mx-3" style="padding-right: 1rem; padding-left: 1rem;">
            <div class="card-header card-header-info">
              <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_716' | translate }}</h5>
          </div>
          <button type="button" style="z-index:100;float: right;margin-top: -2.8rem;" class="btn btn-dark" data-dismiss="modal" (click) = "frame.hide()"aria-label="Cerrar modal">X</button>
          <form #validatingForm="ngForm" class="editForm" novalidate>
            <div class="row">
                <div class='col-xs-12 col-md-12'>
                    <div class="form-group">
                        <label class="control-label" >{{ 'texto_408' | translate }}</label> <br>
                          <mat-form-field>
                            <mat-label>{{ 'texto_140' | translate }}</mat-label>
                            <mat-select [formControl]="user" (click)="selected()" (selectionChange)="onChangeUser($event)" required>
                              <mat-option *ngFor="let item of usuarios" [value]="item.id_user" >
                                {{item.n_user}} {{item.ape_user}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                </div>

                <div class='col-xs-12 col-sm-12'>
                  <label class="control-label" for="comentario">{{ 'texto_739' | translate }}</label>
                  <ngx-wig id="comentario" [formControl]="comentario" [buttons]="'bold, italic, link, underline'" (keyup)="onKey($event)"></ngx-wig>
                  <span style="float: left;font-weight: bold;font-size: 0.75rem">{{contador2}} {{ 'texto_69' | translate }} 4000</span>
                </div>

                <div class='col-xs-12 col-sm-12' *ngIf="show">
                    <p *ngIf="name">
                      <small style="font-size: 0.62rem;">* Le asignará la denuncia a: {{name}}</small><br>
                      <small *ngIf="phaseNext" style="font-size: 0.62rem;">* La denuncia retrocederá a la fase: {{phaseNext | translate}}</small>
                    </p>
                </div>
            </div>
            <div class="text-center mt-3">
              <button [disabled]="state" (click)="changePhase()"  mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_384' | translate }}
              </button>
            </div>
          </form>
    </div>
  </div>
</div>
