import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, of } from "rxjs";
import { ApiService } from "../shared/services/api.service";
import { AuthService } from "../shared/services/auth.service";
import { map, switchMap, tap } from "rxjs/operators";
import { ReloadComponent } from "../services/reloadComponent.service";

@Injectable({
    providedIn: "root"
})
export class EmailTokenGuard implements CanActivate {
    constructor(private reload: ReloadComponent, private apiService: ApiService, private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const location = window.location.href;
        console.log(location);
        const token = location.split('token=')[1];


        // Si se detecta un token
        if (token) {
            return this.apiService.loginWithToken(token).pipe(
                tap(response => {
                    localStorage.setItem("access_token2", response.accessToken);
                    this.authService.setRefreshToken(response.refreshToken);
                }),
                switchMap(loggedIn => {
                    return this.apiService.meInfo().pipe(
                        tap(userData => {
                            this.authService.sendToken(userData.id_user, userData.n_user, userData.ape_user, userData.mail_user, userData.foto);
                        }),
                        switchMap(() => {
                            return of(null).pipe(
                                tap(() => {
                                    this.reload.sendSignal();
                                    localStorage.setItem('isLoggin', JSON.stringify('1'));
                                    this.router.navigate(["admin"]);
                                    // Agrega la navegación a dashboard aquí después de la navegación a admin
                                    this.router.navigate(["dashboard"]);
                                    window.location.reload();
                                })
                            );
                        })
                    );
                })
            );
        } else {
            return true;
        }


    }

}
