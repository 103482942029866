import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  language: string; //language variable
  dateYear: number = 0; //date year

  constructor(private translate: TranslateService, private auth: AuthService) { }

  ngOnInit() {
    this.language = this.auth.getLanguage();
    if (this.language == "" || this.language == null) {
      this.language = 'es';
    }
    this.translate.setDefaultLang(this.language);
    this.getDateYear(); //Get Year

  }

  /**
 * Get Year For All Rights Reserved
 */
  getDateYear() {
    const currenDate = new Date();
    this.dateYear = currenDate.getFullYear();
  }

}
