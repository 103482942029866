import { Injectable }                        from '@angular/core';
import { WorkflowService }                   from '../workflow/workflow.service';
import { FormData, Personal, Address, Clausulas, Textos, TipoHecho, EmpresaHecho, DatosDenunciante, Receptor, Datos, Archivos, FinalizarDenuncia, ReceptorInicial, GrabarDenuncia }       from './formData.model';

@Injectable()
export class FormDataService {

    private formData: FormData = new FormData();
    private isPersonalFormValid: boolean = false;
    private isWorkFormValid: boolean = false;
    private isAddressFormValid: boolean = false;
    private isTipoHechoFormValid: boolean = false;
    private isEmpresaHechoFormValid: boolean = false;
    private isDatosDenuncianteFormValid: boolean = false;
    private isReceptorFormValid: boolean = false;
    private isReceptorInicialFormValid: boolean = false;
    private isDatosFormValid: boolean = false;
    private isArchivosFormValid: boolean = false;
    private isFinalizarDenunciaFormValid: boolean = false;
    private isTextosFormValid: boolean = false;
    private isClausulasFormValid: boolean = false;

    constructor(private workflowService: WorkflowService) { 
    }
    getPersonal(): Personal {
        // Return the Personal data
        var personal: Personal = {
            firstName: this.formData.firstName,
            lastName: this.formData.lastName,
            email: this.formData.email,
            phone: this.formData.phone,
            description: this.formData.description
        };
        return personal;
    }

    setPersonal(data: Personal) {
        // Update the Personal data only when the Personal Form had been validated successfully
        this.isPersonalFormValid = true;
        this.formData.firstName = data.firstName;
        this.formData.lastName = data.lastName;
        this.formData.email = data.email;
        this.formData.phone = data.phone;
        this.formData.description = data.description;
    }

    getWork() : string {
        // Return the work type
        return this.formData.work;
    }
    
    setWork(data: string) {
        // Update the work type only when the Work Form had been validated successfully
        this.isWorkFormValid = true;
        this.formData.work = data;
    }

    getAddress() : Address {
        // Return the Address data
        var address: Address = {
            street: this.formData.street,
            city: this.formData.city,
            state: this.formData.state,
            zip: this.formData.zip
        };
        return address;
    }

    setAddress(data: Address) {
        // Update the Address data only when the Address Form had been validated successfully
        this.isAddressFormValid = true;
        this.formData.street = data.street;
        this.formData.city = data.city;
        this.formData.state = data.state;
        this.formData.zip = data.zip;
    }

    getFormData(): FormData {
        // Return the entire Form Data
        return this.formData;
    }

    resetFormData(): FormData {
        // Return the form data after all this.* members had been reset
        this.formData.clear();
        this.isPersonalFormValid = this.isWorkFormValid = this.isAddressFormValid = false;
        return this.formData;
    }

    isFormValid() {
        // Return true if all forms had been validated successfully; otherwise, return false
        return this.isPersonalFormValid &&
                this.isWorkFormValid && 
                this.isAddressFormValid;
    }

    getTipoHecho(): TipoHecho {
        var tipoHecho: TipoHecho = {
            empresa: this.formData.empresa,
            idEmpresa: this.formData.idEmpresa,
            identificadorEmpresa: this.formData.identificadorEmpresa,
            tipoDenuncia: this.formData.tipoDenuncia,
            idCentro: this.formData.idCentro, //centro
            identificadorCentro: this.formData.identificadorCentro
        };
        return tipoHecho;
    }

    setTipoHecho(data: TipoHecho) {
        this.isTipoHechoFormValid = true;
        this.formData.empresa = data.empresa;
        this.formData.idEmpresa = data.idEmpresa;
        this.formData.identificadorEmpresa = data.identificadorEmpresa;
        this.formData.tipoDenuncia = data.tipoDenuncia;
        this.formData.idCentro = data.idCentro; //centro
        this.formData.identificadorCentro = data.identificadorCentro;
    }

    getEmpresaHecho(): EmpresaHecho {
        var empresaHecho: EmpresaHecho = {
            idEmpresa: this.formData.idEmpresa,
        };
        return empresaHecho;
    }

    setEmpresaHecho(data: EmpresaHecho) {
        this.isEmpresaHechoFormValid = true;
        this.formData.idEmpresa = data.idEmpresa;
    }

    getTexto(): Textos {
        var texto: Textos = {
            id_texto: this.formData.id_texto,
            texto: this.formData.texto,
            idioma: this.formData.idioma,
            modificable: this.formData.modificable
        };
        return texto;
    }

    getClausula(): Clausulas {
        var clausula: Clausulas = {
            id_clausula: this.formData.id_clausula,
            clausula: this.formData.clausula
        };
        return clausula;
    }

    setTexto(data: Textos) {
        this.isTextosFormValid = true;
        this.formData.texto = data.texto;
        this.formData.texto = data.texto;
        this.formData.idioma = data.idioma;
        this.formData.modificable = data.modificable;
    }

    setClausula(data: Clausulas) {
        this.isClausulasFormValid = true;
        this.formData.id_clausula = data.id_clausula;
        this.formData.clausula = data.clausula;
    }    

    getDenunciante(): DatosDenunciante {
        var denunciante: DatosDenunciante = {
            denuncianteRelacion: this.formData.denuncianteRelacion,
            denuncianteFirstName: this.formData.denuncianteFirstName,
            denuncianteLastName: this.formData.denuncianteLastName,
            denuncianteEmail: this.formData.denuncianteEmail,
            denunciantePhone: this.formData.denunciantePhone,
           // denuncianteDNI: this.formData.denuncianteDNI
        };
        return denunciante;
    }

    setDenunciante(data: DatosDenunciante) {
        this.isDatosDenuncianteFormValid = true;
        this.formData.denuncianteRelacion = data.denuncianteRelacion;
        this.formData.denuncianteFirstName = data.denuncianteFirstName;
        this.formData.denuncianteLastName = data.denuncianteLastName;
        this.formData.denuncianteEmail = data.denuncianteEmail;
        this.formData.denunciantePhone = data.denunciantePhone;
        //this.formData.denuncianteDNI = data.denuncianteDNI;
    }

    getReceptor(): Receptor {
        var receptor: Receptor = {
            receptor: this.formData.receptor,
            motivo: this.formData.motivo
        };
        return receptor;
    }

    setReceptor(data: Receptor) {
        this.isReceptorFormValid = true;
        this.formData.receptor = data.receptor;
        this.formData.motivo = data.motivo;
    }

    setReceptorVacio() {
        this.isReceptorFormValid = true;
        this.formData.receptor = "";
        this.formData.motivo = "";
    }    

    getReceptorInicial(): ReceptorInicial {
        var receptorInicial: ReceptorInicial = {
            receptorInicial: this.formData.receptorInicial,
            motivoInicial: this.formData.motivoInicial
        };
        return receptorInicial;
    }

    setReceptorInicial(data: ReceptorInicial) {
        this.isReceptorInicialFormValid = true;
        this.formData.receptorInicial = data.receptorInicial;
        this.formData.motivoInicial = data.motivoInicial;
    }

    setReceptorInicialVacio() {
        this.isReceptorInicialFormValid = true;
        this.formData.receptorInicial = "";
        this.formData.motivoInicial = "";
    }    


    getDatos(): Datos {
        var datos: Datos = {
            fechaDatosDenuncia: this.formData.fechaDatosDenuncia,
            descripcionDatosDenuncia: this.formData.descripcionDatosDenuncia
        };
        return datos;
    }

    setDatos(data: Datos) {
        this.isDatosFormValid = true;
        this.formData.fechaDatosDenuncia = data.fechaDatosDenuncia;
        this.formData.descripcionDatosDenuncia = data.descripcionDatosDenuncia;
    }

    getArchivos(): Archivos {
        var archivos: Archivos = {
            nombreArchivo: this.formData.nombreArchivo,
            descripcionArchivo: this.formData.descripcionArchivo
        };
        return archivos;
    }

    setArchivos(data: Archivos) {
        this.isArchivosFormValid = true;
        this.formData.nombreArchivo = data.nombreArchivo;
        this.formData.descripcionArchivo = data.descripcionArchivo;
    }

    getFinalizar(): FinalizarDenuncia {
        var finalizar: FinalizarDenuncia = {
            comentario: this.formData.comentario,
            terminos: this.formData.terminos,
            proteccionDatos: this.formData.proteccionDatos
        };
        return finalizar;
    }

    setFinalizar(data: FinalizarDenuncia) {
        this.isFinalizarDenunciaFormValid = true;
        this.formData.comentario = data.comentario;
        this.formData.terminos = data.terminos;
        this.formData.proteccionDatos = data.proteccionDatos;
    }

    setDataComplaint(data: GrabarDenuncia) {
        this.isTipoHechoFormValid = true;
        this.formData.idCentro = data.idCentro;
        this.formData.idEmpresa = data.idEmpresa;
        this.formData.tipoDenuncia = data.tipoDenuncia;
        this.formData.relacion = data.relacion;
        this.formData.receptor = data.receptor;
        this.formData.sociedad = data.sociedad;
        this.formData.receptorInicial = data.receptorInicial;
        this.formData.denuncianteFirstName = data.denuncianteFirstName,
        this.formData.denuncianteLastName = data.denuncianteLastName,
        this.formData.denuncianteEmail = data.denuncianteEmail,
        this.formData.denunciantePhone = data.denunciantePhone,
        this.formData.transcripcion = data.transcripcion,
        this.formData.fechaDatosDenuncia = data.fechaDatosDenuncia,
        this.formData.terminos = data.terminos
    }

    getDataComplaint(): GrabarDenuncia {
        var GrabarDenuncia: GrabarDenuncia = {
            idCentro: this.formData.idCentro,
            idEmpresa: this.formData.idEmpresa,
            tipoDenuncia: this.formData.tipoDenuncia,
            relacion: this.formData.relacion,
            receptor: this.formData.receptor,
            sociedad: this.formData.sociedad,
            receptorInicial: this.formData.receptorInicial,
            transcripcion: this.formData.transcripcion,
            denuncianteFirstName: this.formData.denuncianteFirstName,
            denuncianteLastName : this.formData.denuncianteLastName,
            denuncianteEmail: this.formData.denuncianteEmail,
            denunciantePhone: this.formData.denunciantePhone,
            fechaDatosDenuncia: this.formData.fechaDatosDenuncia,
            terminos: this.formData.terminos

        };
        return GrabarDenuncia;
    }

}