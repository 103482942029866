import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ConsultarDenunciaComponent } from '../components/consultar-denuncia/consultar-denuncia.component';
import { ConsultarDenunciaService } from '../services/consultar-denuncia.service';

@Injectable({
  providedIn: 'root'
})
export class ConsultarDenunciaGuard implements CanActivate {

  constructor(
    private auth: AuthService, 
    private router: Router, 
    private consultarService: ConsultarDenunciaService
    ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        //Validar que isLoggedInComplaint sea igual a true
        if (this.consultarService.isLoggedInComplaint) {
            return true;
          } else {
            this.auth.logoutSimple;
            this.router.navigate(["/login"]);
            return false;
          }
  }
}
