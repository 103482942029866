import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CentrosComponent } from './components/centros/centros/centros.component';
import { ConsultasComponent } from './components/consultas/consultas/consultas.component';
import { GestionUsuariosComponent } from './components/gestion-usuarios/gestion-usuarios/gestion-usuarios.component';
import { TipoRelacionComponent } from './components/tipo-relacion/tipo-relacion/tipo-relacion.component';
import { EmpresasComponent } from './components/empresas/empresas/empresas.component';
import { TipoDenunciaComponent } from './components/tipo-denuncia/tipo-denuncia/tipo-denuncia.component';
import { GestionExternaComponent } from './components/gestion-externa/gestion-externa/gestion-externa.component';
import { ShowPhaseComponent } from './components/fases/show-phase/show-phase.component';
import { MedidaCautelarComponent } from './components/medida-cautelar/medida-cautelar/medida-cautelar.component';
import { ConfiguracionAvanzadaComponent } from './components/configuracion-avanzada/configuracion-avanzada/configuracion-avanzada.component';
import { UpdateUserComponent } from './components/gestion-usuarios/update-user/update-user.component';
import { SociedadesComponent } from 'src/app/sociedades/sociedades.component';

const routes: Routes = [
  {
    path: 'centros',   component: CentrosComponent
  },
  {
    path: 'consultas',   component: ConsultasComponent 
  },
  { 
    path: 'usuarios', component: GestionUsuariosComponent 
  },
  { 
    path: 'tipo-relacion', component: TipoRelacionComponent 
  },
  {
    path: 'empresas',   component: EmpresasComponent
  },
  {
    path: 'tipo-denuncia',   component: TipoDenunciaComponent
  },
  {
    path: 'abogado',   component: GestionExternaComponent
  },
  {
    path: 'phases',   component: ShowPhaseComponent
  },
  {
    path: 'medida-cautelar',   component: MedidaCautelarComponent
  },
  {
    path: 'advanced-setting',   component: ConfiguracionAvanzadaComponent
  },
  {
    path: 'user/:id', component: UpdateUserComponent
  },
  {
    path: 'sociedades', component: SociedadesComponent
  },
  // {
  //   path: '', component: SociedadesComponent
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
