<button  style="background-image: url(/../../assets/img/botones/Boton_Editar.png);" class="btn btn-rounded btn-cabecera" matTooltip="{{'texto_139' | translate }}" type="button" mdbBtn rounded="true" data-toggle="modal" data-target="#basicExample"
        (click)="frame.show()" mdbWavesEffect>
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="max-width: 100% !important;">
    <!--MODAL THAT SHOW CHANGE TYPE COMPLAINT-->
    <div class="modal-content">
        <div class="modal-body mx-3" style="padding-right: 1rem; padding-left: 1rem;">
            <div class="card-header card-header-info">
              <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_139' | translate }}</h5>
          </div>
          <button type="button" style="z-index:100;float: right;margin-top: -2.8rem;" class="btn btn-dark" data-dismiss="modal" (click) = "frame.hide()"aria-label="Cerrar modal">X</button> 
          <form #validatingForm="ngForm" class="editForm" novalidate>
            <div class="row">
                <div class='col-xs-12 col-md-12'>
                    <div class="form-group">
                        <label class="control-label" >{{ 'texto_634' | translate }}</label> <br> 
                          <mat-form-field>
                            <mat-label>{{ 'texto_140' | translate }}</mat-label>
                            <mat-select [formControl]="typecomplaint" required>
                              <mat-option *ngFor="let item of typecomplaints" [value]="item.id_tp_denuncia" >
                                {{item.nombre}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                </div>
            </div>            
            <div class="text-center mt-3">
              <button (click)="editComplaint()"  mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_384' | translate }}
              </button>
            </div>
          </form>
    </div>
  </div>
</div>