<app-spinner></app-spinner>
<section style="position: absolute;width: 100%;z-index: 1;" class="image-container line-spacing"
  style="background-image: url(/assets/img/inicio/inicio-1.jpg);">
  <div class="container" style="padding: 0;">
    <div class="board">
      <a href="/" (click)="navigateToNewPage()">
        <button type="button" style="z-index:100;float: right;" class="btn btn-dark"
          [attr.aria-label]="'texto_735' | translate">X</button>
      </a>

      <!--Breadcrumbs-->
      <div class="breadcrum-div">
        <nav aria-label="breadcrumb" class="nav">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/inicio">{{ 'texto_1' |
                translate }}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ 'texto_717'
              | translate }}</li>
          </ol>
        </nav>
      </div>
      <!--End Breadcrumbs-->
      <!-- Navigation Area (Circular Tabs) -->
      <msw-navbar></msw-navbar>
      <!-- End Navigation Area (Circular Tabs) -->

      <!-- Content Area -->
      <div class="tab-content">
        <form #finalizarForm="ngForm" class="editForm" novalidate>
          <div class="tab-pane fade in active">
            <h2 class="head text-center">{{title | translate }}</h2>
            <div class='row'>
              <div class='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8'>
                <div class="row">
                  <div class='col-xs-12 col-sm-12'>
                    <label class="control-label" for="comentario">{{ 'texto_160' | translate }}
                    </label>
                    <textarea maxlength="4000" class="form-control input-md" #comentario="ngModel" id="comentario"
                      name="comentario" type="text" placeholder="{{ 'texto_95' | translate }}"
                      [(ngModel)]="finalizar.comentario" rows="8"></textarea>

                  </div>
                </div>

                <div class="data-protection-info"
                  style="font-size: 0.75rem; display: block; line-height: 1.5; text-align: justify">
                  <p>{{'texto_740'| translate}}<a></a></p>
                </div>

                <!-- Politica de privacidad -->
                <!-- <div class="row">
                                    <div class='col-xs-12 col-sm-12' style="font-size:10px;">
                                        <mat-checkbox required id="politicas" name="politicas" [(ngModel)]="finalizar.politicas"
                                            (change)="onPoliticasChange()">
                                            {{ 'texto_752' | translate }}
                                            <a *ngIf="politicaEnabled == '1'; else elsePoliticasBlock" target="_blank" [href]="urlpolitica">{{
                                                'texto_751' | translate }}</a>
                                            <ng-template #elsePoliticasBlock>
                                                <a target="_blank" [href]="docpolitica">{{ 'texto_751' | translate }}</a>
                                            </ng-template>
                                            {{ 'texto_163' | translate }}
                                        </mat-checkbox>
                                    </div>
                                </div> -->
                <!-- End Politica de privacidad -->

                <!-- Términos y Condiciones -->
                <div class="row">
                  <div class='col-xs-12 col-sm-12' style="font-size:10px;">
                    <mat-checkbox required id="terminos" name="terminos" [(ngModel)]="finalizar.terminos"
                      (change)="onTerminosChange()">
                      {{ 'texto_738' | translate }}
                      <a *ngIf="terminos2 == '1'; else elseTerminosBlock" target="_blank" [href]="url">{{
                        'texto_162' | translate }}</a>
                      <ng-template #elseTerminosBlock>
                        <a target="_blank" [href]="documento">{{ 'texto_162' | translate }}</a>
                      </ng-template>
                      {{ 'texto_163' | translate }}
                    </mat-checkbox>
                  </div>
                </div>



                <!--Protección de Datos-->
                <!-- <div class="row">
                                    <div class='col-xs-12 col-sm-12' style="font-size:10px;">
                                        <mat-checkbox required id="proteccion" name="proteccion" [(ngModel)]="finalizar.proteccionDatos">{{ 'texto_161' | translate }}
                                            <a *ngIf="proteccionDatos == '1' ; else elseBlock"   target="_blank" [href]=documentoProteccionDatos> {{ 'texto_672' | translate }}</a>
                                            <ng-template #elseBlock>
                                                <a  target="_blank" [href]=documentoProteccionDatos> {{ 'texto_672' | translate }}</a>
                                            </ng-template>
                                            {{ 'texto_163' | translate }}
                                        </mat-checkbox>
                                    </div>
                                </div> -->



                <div class="form-group text-center" style="margin-top: 30px;">
                  <div
                    style="font-size: 0.75rem;line-height: 1.5;border: 0px solid black;padding-top: 10px; padding-top: 10px; text-align: justify;">
                    <p>{{ 'texto_732' | translate }}</p>
                  </div>
                  <button (click)="goToPrevious(finalizarForm)" style="color:#fff" mdbBtn rounded="true"
                    class="btn-back" mdbWavesEffect><span style="margin-right:10px;"
                      class="glyphicon glyphicon-arrow-left">
                    </span>{{ 'texto_142' | translate }}
                  </button>

                  <button [disabled]="!finalizarForm.valid" (click)="goToNext(finalizarForm)" style="color:#fff" mdbBtn
                    rounded="true" class="btn-next" mdbWavesEffect>{{
                    'texto_164' | translate }} <span style="margin-left:10px;" class="glyphicon glyphicon-arrow-right">
                    </span>
                  </button>
                </div>
                <input type="hidden" id="finaliced" name="finaliced" value="">
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>