import { BehaviorSubject, Observable } from "rxjs";

export class DatosService {
    private datosSubject: BehaviorSubject<any> = new BehaviorSubject(null);

    enviarDatos(datos: any){
        this.datosSubject.next(datos);
    }

    obtenerDatos(): Observable<any> {
        return this.datosSubject.asObservable();
    }
}