import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TipoDenunciaService } from 'src/app/dashboard/services/tipo-denuncia.service';
import { ReceptorObligatorio } from 'src/app/dashboard/models/receptorobligaorio';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Denuncia } from 'src/app/dashboard/models/denuncia';
import { Abogado } from 'src/app/dashboard/models/abogado';

@Component({
  selector: 'app-tipo-denuncia',
  templateUrl: './tipo-denuncia.component.html',
  styleUrls: ['./tipo-denuncia.component.css']
})
export class TipoDenunciaComponent implements OnInit {
  sociedad: string;
  denuncias: Denuncia[];
  IsHiddenForm: boolean =  false;
  idDenuncia: string;
  nombre: string;
  comentario: string;
  orden: string;
  validatingForm: FormGroup;
  checkAbogado: boolean;
  abogados: Abogado[];
  abogadoSelected: string;
  receptoreso: ReceptorObligatorio[];
  selectAdabogado: string;
  selectConflicto: string;
  modeSelect: string;


  constructor(private tipoDenunciaServiceService: TipoDenunciaService,private router: Router, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    this.sociedad = this.auth.getNameSociedad();
    this.tipoDenunciaServiceService.searchComplaintByUserSociety(this.auth.getSociedad(),language).subscribe((denuncia: Denuncia[])=>{
      this.denuncias = denuncia;
    });

    this.tipoDenunciaServiceService.searchLayerByUserSociety(this.auth.getSociedad()).subscribe((abogado: Abogado[])=>{
      this.abogados = abogado;
    });

    this.tipoDenunciaServiceService.searchReceptorObligatoryByUserSociety(this.auth.getIdUser(),this.auth.getSociedad()).subscribe((users: ReceptorObligatorio[])=>{
      this.receptoreso = users;
    });

    this.validatingForm = new FormGroup({
      signupFormModalName: new FormControl(''),
      signupFormModalOrden: new FormControl('', [Validators.min(1)]),
      signupFormModalId: new FormControl(''),
      contactFormModalMessage: new FormControl(''),
      abogado: new FormControl('', [
      ]),
    });
  }

  get signupFormModalName() {
    return this.validatingForm.get('signupFormModalName');
  }

  get signupFormModalOrden() {
    return this.validatingForm.get('signupFormModalOrden');
  }

  get signupFormModalId() {
    return this.validatingForm.get('signupFormModalId');
  }

  get contactFormModalMessage() {
    return this.validatingForm.get('contactFormModalMessage');
  }

  get abogado() {
    return this.validatingForm.get('abogado');
  }

  deleteComplaint(id_tp_denuncia: string){
    this.tipoDenunciaServiceService.searchComplaintBySocietyType(this.auth.getSociedad(),id_tp_denuncia).subscribe(
      res => {
        if(res == 200){
          this.tipoDenunciaServiceService.deleteComplaintByUser(this.auth.getSociedad(),id_tp_denuncia).subscribe(
            res => {
              if(res == 200){
                this.toastr.success(this.translate.instant('texto_232'), this.translate.instant('texto_235'));
              }else{
                this.toastr.error(this.translate.instant('texto_233'), this.translate.instant('texto_235'));
              }
              this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/dashboard/layout/tipo-denuncia']);
              });
          });
        }else{
          this.toastr.error(this.translate.instant('texto_234'), this.translate.instant('texto_235'));
        }

        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/dashboard/layout/tipo-denuncia']);
        });
    });




  }

  hideModal(){
    this.IsHiddenForm = false;
  }

  showComplaint(complaint:Denuncia){
    this.IsHiddenForm = true;
    this.idDenuncia = complaint.id_tp_denuncia;
    this.nombre = complaint.nombre;
    this.comentario = complaint.descripcion;
    this.orden = complaint.orden

    const toSelect = complaint.id_gestor;

    this.abogado.patchValue({
      selectAdabogado:toSelect
    });

    this.abogado.patchValue({
      selectConflicto:toSelect
    });
  }

  updateComplaint(id_tp_denuncia:string){
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    let nombreRelacion = this.signupFormModalName.value;
    if(this.signupFormModalName.value == ""){
      nombreRelacion = this.nombre;
    }

    let orden = this.signupFormModalOrden.value;
    if(this.signupFormModalOrden.value == "" || this.signupFormModalOrden.value == null){
      orden = this.orden;
    }

    console.log(orden);

    let tipo_receptor = this.abogado.value.tipo_receptor;
    if(tipo_receptor == "" || tipo_receptor == null){
      tipo_receptor = "0";
    }

    let gestor_denuncia = this.abogado.value.id;
    //console.log('id', gestor_denuncia);
    if(gestor_denuncia == "" || gestor_denuncia == null){
      gestor_denuncia = "";
    }
    //console.log(gestor_denuncia);
    let id_gestor_conflicto = this.selectConflicto;
    if(id_gestor_conflicto == "" || id_gestor_conflicto == null){
      id_gestor_conflicto = "";
    }

    this.tipoDenunciaServiceService.updateComplaintByUser(this.auth.getSociedad(), id_tp_denuncia, nombreRelacion, this.comentario,this.auth.getIdUser(),this.abogado.value.tipo_receptor,gestor_denuncia,language, id_gestor_conflicto, orden).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_236'), this.translate.instant('texto_238'));
        }else{
          this.toastr.error(this.translate.instant('texto_237'), this.translate.instant('texto_238'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/dashboard/layout/tipo-denuncia']);
        });
    });
  }

  selectabogado(event) {
    let selectedIdAbogado = event.value;
    //console.log("selectedIdAbogado: ", selectedIdAbogado.id);
    /*for(var i=0; i<this.abogados.length; i++){
      if(this.abogados[i].id == selectedIdAbogado){
        this.abogado = this.abogados[i].n_abogado;
        //console.log(this.textoF);
        break;
      }
    }*/
    this.selectAdabogado = event.value;
    //this.modeSelect = event.value;
    //this.selectAdabogado = selectedIdAbogado.id
    //this.modeSelect = selectedIdAbogado.id
    // console.log("this.selectAdabogado: ", this.selectAdabogado);
    // console.log("this.modeSelect: ", this.modeSelect);
  }


  selectabogadoconlficto(event) {
    this.selectConflicto = event.value;
    //console.log("this.selectConflicto", this.selectConflicto);
  }

}
