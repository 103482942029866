import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';
import { ApiService } from '../shared/services/api.service';
import { Sociedad } from '../model/sociedad';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sociedades',
  templateUrl: './sociedades.component.html',
  styleUrls: ['./sociedades.component.css']
})
export class SociedadesComponent implements OnInit {
  sociedad: string;
  sociedades: Sociedad[];
  validatingForm: FormGroup;
  constructor(private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService, private changeDetectorRefs: ChangeDetectorRef,private translate: TranslateService) { }
  IsHiddenForm: boolean =  false;

  id_sociedad: string;
  n_sociedad: string;
  web: string;
  comentario: string;

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    this.sociedad = this.auth.getNameSociedad();
    this.apiService.searchSociedades().subscribe((sociedades: Sociedad[])=>{
      this.sociedades = sociedades;
    });
    this.validatingForm = new FormGroup({
      signupFormModalName: new FormControl(''),
      signupFormModalWeb: new FormControl(''),
      signupFormModalId: new FormControl(''),
      contactFormModalMessage: new FormControl('', []),
    });
  }

  get signupFormModalName() {
    return this.validatingForm.get('signupFormModalName');
  }

  get signupFormModalWeb() {
    return this.validatingForm.get('signupFormModalWeb');
  }

  get signupFormModalId() {
    return this.validatingForm.get('signupFormModalId');
  }

  get contactFormModalMessage() {
    return this.validatingForm.get('contactFormModalMessage');
  }


  deleteSociedad(id_sociedad:string){
    this.apiService.countComplaintByIdSociedad(id_sociedad).subscribe(
      res => {
        if(res == 200){
          this.apiService.deleteSociedad(id_sociedad).subscribe(
            res => {
              if(res == 200){
                this.toastr.success(this.translate.instant('texto_276'), this.translate.instant('texto_279'));
              }else{
                this.toastr.error(this.translate.instant('texto_277'), this.translate.instant('texto_279'));
              }
              this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                this.router.navigate(['dashboard/layout/sociedades']);
            });
          });
        }else{
          this.toastr.error(this.translate.instant('texto_278'), this.translate.instant('texto_279'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['dashboard/layout/sociedades']);
        });
    });


  }

  showSociedad(sociedad:Sociedad){
    this.IsHiddenForm = true;
    this.id_sociedad = sociedad.id_sociedad;
    this.n_sociedad = sociedad.n_sociedad;
    this.web = sociedad.web;
    this.comentario = sociedad.comentario;
  }

  hideModal(){
    this.IsHiddenForm = false;
  }

  updateSociedad(id_sociedad:string){
    this.apiService.updateSociedad(id_sociedad,this.signupFormModalName.value, this.signupFormModalWeb.value, this.contactFormModalMessage.value).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_280'), this.translate.instant('texto_282'));
        }else{
          this.toastr.error(this.translate.instant('teato_281'), this.translate.instant('texto_282'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['dashboard/layout/sociedades']);
        });
    });
  }

}
