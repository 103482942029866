<button style="float:right;" class="btn btn-primary " type="button" mdbBtn rounded="true" data-toggle="modal" data-target="#basicExample"
        (click)="frame.show()" mdbWavesEffect>{{ 'texto_60' | translate }}
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document" style="width: 100% !important;max-width: 96%;">
    <!--Content-->
    <div class="modal-content">
        <div class="modal-body mx-3" style="padding-right: 1rem; padding-left: 1rem;">
            <div class="card-header card-header-info">
                <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_376' | translate }}</h5>
            </div>
            <button type="button" style="z-index:100;float: right;margin-top: -2.8rem;" class="btn btn-dark" data-dismiss="modal" (click) = "frame.hide()"aria-label="Cerrar modal">X</button> 
            <div class="md-form col-md-12">
                <label for="form-email">{{ 'texto_103' | translate }}</label><br>
                <mat-form-field>
                    <mat-select [(ngModel)]="selectedRole" [formControl]="selectRole">
                        <mat-option *ngFor="let rol of roles" [value]="rol.id_rol">
                        {{rol.n_rol}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div><br>
            <div class="md-form col-md-12">
                <label for="picker">{{ 'texto_107' | translate }}</label><br>
                <mat-form-field>
                    <input matInput [matDatepicker]="picker" [formControl]="fechaInicio"  format="YYYY-MM-DD"  >
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="md-form col-md-12">
                <label for="picker2">{{ 'texto_108' | translate }}</label><br>
                <mat-form-field>
                    <input matInput [matDatepicker]="picker2" [formControl]="fechaFin" format="YYYY-MM-DD"  >
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="text-center mt-3">
          <button (click)="addRole()"  mdbBtn  rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_60' | translate }}
          </button>
        </div>


    </div>
    <!--/.Content-->
  </div>
</div>