import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
 // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";
  constructor(private router: Router, private httpClient: HttpClient, private auth: AuthService) {}


  login(username:string, password:string): Observable<void> {
    return this.httpClient.post<void>(`${this.PHP_API_SERVER}/api/authenticateWithToken.php`, {
      email: username,
      password: password,
    });
  }
  loginSingleSignOn() {
    //return this.httpClient.post(`${this.PHP_API_SERVER}/api/saml_login.php`, {
    //return this.router.navigate([`${this.PHP_API_SERVER}/api/saml_login.php`],{
    //});
    window.location.href = `${this.PHP_API_SERVER}/api/saml_login.php`;
  }
}
