<!-- End Navbar -->
<section class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header card-header-primary">
            <h4 class="card-title">{{ 'texto_30' | translate }}</h4>
          </div>
          <div class="content">
            <form [formGroup]="validatingForm" style="margin-left: 25px; margin-right: 25px;">
              <div class="row" style="padding-top:20px">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_302' | translate }}</label>
                    <input [formControl]="dominio" type="text" class="form-control" [(ngModel)]="dominioF"
                      value="{{dominioF}}" readonly>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_381' | translate }}</label>
                    <input [formControl]="email" type="email" class="form-control" [(ngModel)]="emailF"
                      value="{{emailF}}">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_382' | translate }}</label>
                    <input [formControl]="aviso" type="text" class="form-control" [(ngModel)]="avisoF"
                      value="{{avisoF}}">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_383' | translate }}</label>
                    <select [formControl]="anonimo" class="form-control" [(ngModel)]="selectedTipo"
                      (change)="selectChangeHandler($event)">
                      <option value="1">{{ 'texto_136' | translate }}</option>
                      <option value="0">{{ 'texto_501' | translate }}</option>
                      <option value="2">{{ 'texto_502' | translate }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_513' | translate }}</label>
                    <select [formControl]="triaje" class="form-control" [(ngModel)]="selectedTriaje"
                      (change)="selectChangeHandler8($event)">
                      <option value="1">{{ 'texto_498' | translate }}</option>
                      <option value="0">{{ 'texto_499' | translate }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_303' | translate }}</label>
                    <select [formControl]="calusulas" class="form-control" [(ngModel)]="selectedClausulas"
                      (change)="selectChangeHandler2($event)">
                      <option value="1">Sí</option>
                      <option value="0">No</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_324' | translate }}</label>
                    <select [formControl]="conflicto" class="form-control" [(ngModel)]="selectedConflicto"
                      (change)="selectChangeHandler3($event)">
                      <option value="1">{{ 'texto_498' | translate }}</option>
                      <option value="0">{{ 'texto_499' | translate }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_413' | translate }}</label>
                    <input [formControl]="diasverdenuncia" type="text" class="form-control"
                      [(ngModel)]="diasverdenunciaF" value="{{diasverdenunciaF}}">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_328' | translate }}</label>
                    <input [formControl]="diasinves" type="text" class="form-control" [(ngModel)]="diasinvesF"
                      value="{{diasinvesF}}">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_329' | translate }}</label>
                    <input [formControl]="diasdecisor" type="text" class="form-control" [(ngModel)]="diasdecisorF"
                      value="{{diasdecisorF}}">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_330' | translate }}</label>
                    <input [formControl]="diascompliance" type="text" class="form-control" [(ngModel)]="diascomplianceF"
                      value="{{diascomplianceF}}">
                  </div>
                </div>
              </div>
              <div class="row">
                <!--CENTERS SELECTED-->
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_672' | translate }}</label>
                    <!--<label class="bmd-label-floating">Centros</label>-->
                    <select [formControl]="centros" class="form-control" [(ngModel)]="selectedCenters"
                      (change)="selectChangeHandlerCenters($event)">
                      <option value="1">{{ 'texto_498' | translate }}</option>
                      <option value="0">{{ 'texto_499' | translate }}</option>
                    </select>
                  </div>
                </div>
                <!--Autocomplete-->
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_711' | translate }}</label>
                    <!--<label class="bmd-label-floating">Centros</label>-->
                    <select [formControl]="autocomplete" class="form-control" [(ngModel)]="selectedinputAutocomplete"
                      (change)="selectChangeHandlerInputAutocomplete($event)">
                      <option value="1">{{ 'texto_498' | translate }}</option>
                      <option value="0">{{ 'texto_499' | translate }}</option>
                    </select>
                  </div>
                </div>


                <div class="col-md-4">
                  <div class="form-group">
                    <label class="bmd-label-floating ml-1">Activar Pop-up</label><br>
                    <!-- Dropdown for activating the pop-up -->
                    <select [formControl]="popUp" class="form-control custom-select" [(ngModel)]="selectedPopUp"
                      (change)="selectChangeHandler10($event)">
                      <!-- Option to activate the pop-up -->
                      <option value="1">SI</option>
                      <!-- Option to deactivate the pop-up -->
                      <option value="0">NO</option>
                    </select>
                  </div>
                </div>
                <!--FIN SELECTED-->
              </div>

              <!-- Términos y condiciones -->
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_335' | translate }}</label><br>
                    <select [formControl]="terminos" class="form-control" [(ngModel)]="selectedTerminos"
                      (change)="selectChangeHandler4($event)">
                      <option value="1">URL</option>
                      <option value="0">Documento</option>
                    </select>
                  </div>
                </div>
                <!--<div class="col-md-8" [hidden]="fichero">-->
                <div class="col-md-8" *ngIf="selectedTerminos == '1' ; else terminosElseBlock">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_341' | translate }}</label>
                    <input [formControl]="ruta" type="text" class="form-control" [(ngModel)]="rutaF" value="{{rutaF}}">
                  </div>
                </div>
                <ng-template #terminosElseBlock>
                  <!--<div class="col-md-8" [hidden]="!fichero">-->
                  <div class="col-md-8">
                    <div class="form-group">
                      <div class="col-md-12">
                        <label class="bmd-label-floating">{{ 'texto_117' | translate }}</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" [formControl]="doc_terminos" class="form-control" [(ngModel)]="doc_terminosF"
                          value="{{doc_terminosF}}" readonly>
                      </div>
                      <div class="col-md-4">
                        <span class="btn btn-primary btn-file">
                          <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control"
                            id="doc_terminos" (change)="onFileChange2($event,'doc_terminos')">
                        </span>
                        <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button"
                          (click)="openDoc(doc_terminosF)" title="Ver documento"><span
                            class="glyphicon glyphicon-eye-open"></span></button>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <!-- End Términos y condiciones -->

              <!-- Política de privacidad -->
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_751' | translate }}</label><br>
                    <select [formControl]="politica" class="form-control" [(ngModel)]="selectedPolitica"
                      (change)="selectChangeHandler11($event)">
                      <option value="1">URL</option>
                      <option value="0">Documento</option>
                    </select>
                  </div>
                </div>
                <!--<div class="col-md-8" [hidden]="fichero">-->
                <div class="col-md-8" *ngIf="selectedPolitica == '1' ; else politicasElseBlock">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_341' | translate }}</label>
                    <input [formControl]="rutapolitica" type="text" class="form-control" [(ngModel)]=" rutaPoliticaF" value="{{rutaPoliticaF}}">
                  </div>
                </div>
                <ng-template #politicasElseBlock>
                  <!--<div class="col-md-8" [hidden]="!fichero">-->
                  <div class="col-md-8">
                    <div class="form-group">
                      <div class="col-md-12">
                        <label class="bmd-label-floating">{{ 'texto_117' | translate }}</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" [formControl]="doc_politica" class="form-control" [(ngModel)]="doc_politicaF"
                          value="{{doc_politicaF}}" readonly>
                      </div>
                      <div class="col-md-4">
                        <span class="btn btn-primary btn-file">
                          <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control"
                            id="doc_politica" (change)="onFileChange6($event,'doc_politica')">
                        </span>
                        <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button"
                          (click)="openDoc(doc_politicaF)" title="Ver documento"><span
                            class="glyphicon glyphicon-eye-open"></span></button>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <!-- End Política de privacidad -->

              <!--El Boton1-->
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_466' | translate }}</label><br>
                    <select [formControl]="boton1" class="form-control" [(ngModel)]="selectedBoton1"
                      (change)="selectChangeHandler5($event)">
                      <option value="1">SI</option>
                      <option value="0">NO</option>
                    </select>
                  </div>
                </div>
                <!--<div class="col-md-8" [hidden]="fichero">-->
                <div class="col-md-8" *ngIf="selectedBoton1 == '0' ; else elseBlock2"></div>
                <ng-template #elseBlock2>
                  <!--<div class="col-md-8" [hidden]="!fichero">-->
                  <div class="col-md-8">
                    <div class="form-group">
                      <div class="col-md-12">
                        <label class="bmd-label-floating">{{ 'texto_117' | translate }}</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" [formControl]="doc_boton1" class="form-control" [(ngModel)]="doc_boton1F"
                          value="{{doc_boton1F}}" readonly>
                      </div>
                      <div class="col-md-4">
                        <span class="btn btn-primary btn-file">
                          <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control"
                            id="doc_boton1" (change)="onFileChange3($event,'doc_boton1')">
                        </span>
                        <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button"
                          (click)="openDoc(doc_boton1F)" title="Ver documento"><span
                            class="glyphicon glyphicon-eye-open"></span></button>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <!--El Boton2-->
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_486' | translate }}</label><br>
                    <select [formControl]="boton2" class="form-control" [(ngModel)]="selectedBoton2"
                      (change)="selectChangeHandler6($event)">
                      <option value="1">SI</option>
                      <option value="0">NO</option>
                    </select>
                  </div>
                </div>
                <!--<div class="col-md-8" [hidden]="fichero">-->
                <div class="col-md-8" *ngIf="selectedBoton2 == '0' ; else elseBlock22"></div>
                <ng-template #elseBlock22>
                  <!--<div class="col-md-8" [hidden]="!fichero">-->
                  <div class="col-md-8">
                    <div class="form-group">
                      <div class="col-md-12">
                        <label class="bmd-label-floating">{{ 'texto_117' | translate }}</label>
                      </div>
                      <div class="col-md-8">
                        <input type="text" [formControl]="doc_boton2" class="form-control" [(ngModel)]="doc_boton2F"
                          value="{{doc_boton2F}}" readonly>
                      </div>
                      <div class="col-md-4">
                        <span class="btn btn-primary btn-file">
                          <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control"
                            id="doc_boton2" (change)="onFileChange4($event,'doc_boton2')">
                        </span>
                        <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button"
                          (click)="openDoc(doc_boton2F)" title="Ver documento"><span
                            class="glyphicon glyphicon-eye-open"></span></button>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <!--El Boton -->
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <!-- Label for the dropdown menu -->
                    <label class="bmd-label-floating">{{ 'texto_488' | translate }}</label><br>
                    <!-- Dropdown to select Yes or No -->
                    <select [formControl]="boton3" class="form-control" [(ngModel)]="selectedBoton3"
                      (change)="selectChangeHandler7($event)">
                      <option value="1">SI</option>
                      <option value="0">NO</option>
                    </select>
                  </div>
                </div>

                <!--<div class="col-md-8" [hidden]="fichero">-->
                <!-- Conditionally render the following div based on the value of selectedBoton3 -->
                <div class="col-md-8" *ngIf="selectedBoton3 == '0' ; else elseBlock23"></div>
                <ng-template #elseBlock23>
                  <!--<div class="col-md-8" [hidden]="!fichero">-->
                  <div class="col-md-8">
                    <div class="form-group">
                      <div class="col-md-12">
                        <!-- Label for the input field -->
                        <label class="bmd-label-floating">{{ 'texto_117' | translate }}</label>
                      </div>
                      <div class="col-md-8">
                        <!-- Label for the input field -->
                        <input type="text" [formControl]="doc_boton3" class="form-control" [(ngModel)]="doc_boton3F"
                          value="{{doc_boton3F}}" readonly>
                      </div>
                      <div class="col-md-4">
                        <!-- Button to upload a file -->
                        <span class="btn btn-primary btn-file">
                          <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control"
                            id="doc_boton3" (change)="onFileChange5($event,'doc_boton3')">
                        </span>
                        <!-- Button to view the uploaded document -->
                        <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button"
                          (click)="openDoc(doc_boton3F)" title="Ver documento"><span
                            class="glyphicon glyphicon-eye-open"></span></button>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
              <!-- Button to save data -->
              <button (click)="guardarDatos()" type="submit" class="btn btn-primary pull-right">{{ 'texto_384' |
                translate }}</button>
              <div class="clearfix"></div>
            </form>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-header card-header-primary">
            <h4 class="card-title">{{ 'texto_118' | translate }}</h4>
          </div>
          <div class="content">
            <form [formGroup]="validatingFormMail" style="margin-left: 25px; margin-right: 25px;">
              <div class="row" style="padding-top:20px">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_220' | translate }}</label>
                    <input [formControl]="nremitente" type="text" class="form-control" [(ngModel)]="nremitenteF"
                      value="{{nremitenteF}}">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_222' | translate }}</label>
                    <input [formControl]="remitente" type="email" class="form-control" [(ngModel)]="remitenteF"
                      value="{{remitenteF}}">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_224' | translate }}</label>
                    <input [formControl]="srv_mail" type="text" class="form-control" [(ngModel)]="srv_mailF"
                      value="{{srv_mailF}}">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_385' | translate }}</label>
                    <input [formControl]="puerto" type="text" class="form-control" [(ngModel)]="puertoF"
                      value="{{puertoF}}">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_386' | translate }}</label>
                    <input [formControl]="usr_mail" type="text" class="form-control" [(ngModel)]="usr_mailF"
                      value="{{usr_mailF}}">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_387' | translate }}</label>
                    <input [formControl]="pass_mail" type="password" class="form-control" [(ngModel)]="pass_mailF"
                      value="{{pass_mailF}}">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <mat-checkbox [checked]="auth_mail" [(ngModel)]="auth_mailF" [formControl]="auth_mail"
                      class="example-margin">{{ 'texto_393' | translate }}</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <mat-checkbox [checked]="ssl" [(ngModel)]="sslF" [formControl]="ssl" class="example-margin">{{
                      'texto_394' | translate }}</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <app-prueba-correo></app-prueba-correo>
                  </div>
                </div>
              </div>
              <button (click)="guardarDatosCorreo()" type="submit" class="btn btn-primary pull-right">{{ 'texto_384' |
                translate }}</button>
              <div class="clearfix"></div>
            </form>
          </div>
        </div>

        <div class="card" [hidden]="!esSADM">
          <div class="card-header card-header-primary">
            <h4 class="card-title">{{ 'texto_573' | translate }}</h4>
          </div>
          <div class="content">
            <form [formGroup]="validatingFormMultisociedad" style="margin-left: 25px; margin-right: 25px;">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <mat-checkbox [checked]="multi" [(ngModel)]="multiF" [formControl]="multi" class="example-margin">{{
                      'texto_574' | translate }}</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <mat-checkbox [checked]="tieneSSO" [(ngModel)]="tieneSSOF" [formControl]="tieneSSO"
                      (change)="syncData()" class="example-margin">{{ 'texto_575' | translate }}</mat-checkbox>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <mat-checkbox [checked]="archivardenuncia" [(ngModel)]="archivardenunciaF"
                      [formControl]="archivardenuncia" class="example-margin">{{ 'Archivar denuncia' | translate
                      }}</mat-checkbox>
                  </div>
                </div>
              </div>
              <button (click)="guardarMultisociedad()" type="submit" class="btn btn-primary pull-right">{{ 'texto_384' |
                translate }}</button>
              <div class="clearfix"></div>
            </form>
          </div>
        </div>

      </div>
    </div>

    <!--! Colores  -->
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-primary">
            <h4 class="card-title">{{ 'texto_395' | translate }}</h4>
          </div>
          <div class="content">
            <form [formGroup]="validatingFormColores" style="margin-left: 25px; margin-right: 25px;">
              <!-- FILA 1 -->
              <div class="row" style="padding-top:20px">
                <div class="col-md-3">
                  <div class="form-group">
                    <!--<div id="cp" class="input-group colorpicker-component">-->
                    <label class="bmd-label-floating">{{ 'texto_414' | translate }}</label>
                    <input [formControl]="colorprincipal" id="colorprincipal" type="text" class="form-control"
                      [(ngModel)]="colorprincipalF" value="{{colorprincipalF}}">
                    <!--<span class="input-group-addon"><i></i></span>
                        </div>-->
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_415' | translate }}</label>
                    <input [formControl]="colorsecundario" id="colorsecundario" type="text" class="form-control"
                      [(ngModel)]="colorsecundarioF" value="{{colorsecundarioF}}">
                  </div>
                </div>
                <!-- Color bg título -->
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_753' | translate }}</label>
                    <input [formControl]="colortxtFondo" id="colortxtFondo" type="text" class="form-control"
                      [(ngModel)]="colortxtFondoF" value="{{colortxtFondoF}}">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_416' | translate }}</label>
                    <input [formControl]="colortxttitulo" id="colortxttitulo" type="text" class="form-control"
                      [(ngModel)]="colortxttituloF" value="{{colortxttituloF}}">
                  </div>
                </div>
              </div>
              <!-- FILA 2 -->
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_417' | translate }}</label>
                    <input [formControl]="colortxtbtn" id="colortxtbtn" type="text" class="form-control"
                      [(ngModel)]="colortxtbtnF" value="{{colortxtbtnF}}">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_418' | translate }}</label>
                    <input [formControl]="colorbgseccion1" id="colorbgseccion1" type="text" class="form-control"
                      [(ngModel)]="colorbgseccion1F" value="{{colorbgseccion1F}}">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_419' | translate }}</label>
                    <input [formControl]="colortxtseccion1" id="colortxtseccion1" type="text" class="form-control"
                      [(ngModel)]="colortxtseccion1F" value="{{colortxtseccion1F}}">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_420' | translate }}</label>
                    <input [formControl]="colorbgseccion2" id="colorbgseccion2" type="text" class="form-control"
                      [(ngModel)]="colorbgseccion2F" value="{{colorbgseccion2F}}">
                  </div>
                </div>
              </div>
              <!-- FILA 3 -->
              <div class="row" style="padding-top:20px">
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_421' | translate }}</label>
                    <input [formControl]="colortxtseccion2" id="colortxtseccion2" type="text" class="form-control"
                      [(ngModel)]="colortxtseccion2F" value="{{colortxtseccion2F}}">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_422' | translate }}</label>
                    <input [formControl]="colorbgseccion3" id="colorbgseccion3" type="text" class="form-control"
                      [(ngModel)]="colorbgseccion3F" value="{{colorbgseccion3F}}">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_423' | translate }}</label>
                    <input [formControl]="colortxtseccion3" id="colortxtseccion3" type="text" class="form-control"
                      [(ngModel)]="colortxtseccion3F" value="{{colortxtseccion3F}}">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_424' | translate }}</label>
                    <input [formControl]="colormenulateral" id="colormenulateral" type="text" class="form-control"
                      [(ngModel)]="colormenulateralF" value="{{colormenulateralF}}">
                  </div>
                </div>
              </div>
              <!-- FILA 4 -->
              <div class="row" style="padding-top:20px">
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_425' | translate }}</label>
                    <input [formControl]="colortxtcabeceras" id="colortxtcabeceras" type="text" class="form-control"
                      [(ngModel)]="colortxtcabecerasF" value="{{colortxtcabecerasF}}">
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_426' | translate }}</label>
                    <input [formControl]="colortxtbotones" id="colortxtbotones" type="text" class="form-control"
                      [(ngModel)]="colortxtbotonesF" value="{{colortxtbotonesF}}">
                  </div>
                </div>
              </div>
              <button (click)="guardarColores()" type="submit" class="btn btn-primary pull-right">{{ 'texto_384' |
                translate }}</button>
              <div class="clearfix"></div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-primary">
            <h4 class="card-title">{{ 'texto_396' | translate }}</h4>
          </div>
          <div class="content">
            <p
              style="display: block;margin-inline-start: 20px;font-family: 'Montserrat', sans-serif;font-size: 1rem !important;">
              {{ 'texto_289' | translate }}<a href="https://convertimage.es/" target="_blank"> Convertimage</a></p>
            <form [formGroup]="validatingFormImages" style="margin-left: 25px; margin-right: 25px;">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12"><label>{{ 'texto_294' | translate }}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-md-9">
                        <input type="text" [formControl]="logo" class="form-control" [(ngModel)]="logoF"
                          value="{{logoF}}" readonly>
                      </div>
                      <div class="col-md-3">
                        <span class="btn btn-primary btn-file">
                          <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control"
                            id="logo" (change)="onFileChange($event,'logo')">
                        </span>
                        <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button"
                          (click)="openImg(logoF)" title="Ver imagen"><span
                            class="glyphicon glyphicon-eye-open"></span></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12"><label>{{ 'texto_427' | translate }}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-md-9">
                        <input type="text" [formControl]="inicio1" class="form-control" [(ngModel)]="inicio1F"
                          value="{{inicio1F}}" readonly>
                      </div>
                      <div class="col-md-3">
                        <span class="btn btn-primary btn-file">
                          <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control"
                            id="inicio1" (change)="onFileChange($event,'inicio1')">
                        </span>
                        <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button"
                          (click)="openImg(inicio1F)" title="Ver imagen"><span
                            class="glyphicon glyphicon-eye-open"></span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12"><label>{{ 'texto_428' | translate }}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-md-9">
                        <input type="text" class="form-control" [formControl]="inicio2" [(ngModel)]="inicio2F"
                          value="{{inicio2F}}" readonly>
                      </div>
                      <div class="col-md-3">
                        <span class="btn btn-primary btn-file">
                          <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control"
                            id="inicio2" (change)="onFileChange($event,'inicio2')">
                        </span>
                        <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button"
                          (click)="openImg(inicio2F)" title="Ver imagen"><span
                            class="glyphicon glyphicon-eye-open"></span></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12"><label>{{ 'texto_429' | translate }}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-md-9">
                        <input type="text" class="form-control" [formControl]="inicio3" [(ngModel)]="inicio3F"
                          value="{{inicio3F}}" readonly>
                      </div>
                      <div class="col-md-3">
                        <span class="btn btn-primary btn-file">
                          <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control"
                            id="inicio3" (change)="onFileChange($event,'inicio3')">
                        </span>
                        <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button"
                          (click)="openImg(inicio3F)" title="Ver imagen"><span
                            class="glyphicon glyphicon-eye-open"></span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12"><label>{{ 'texto_430' | translate }}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-md-9">
                        <input type="text" class="form-control" [formControl]="fondo_denunciante"
                          [(ngModel)]="fondo_denuncianteF" value="{{fondo_denuncianteF}}" readonly>
                      </div>
                      <div class="col-md-3">
                        <span class="btn btn-primary btn-file">
                          <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control"
                            id="fondo_denunciante" (change)="onFileChange($event,'fondo_denunciante')">
                        </span>
                        <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button"
                          (click)="openImg(fondo_denuncianteF)" title="Ver Wizard"><span
                            class="glyphicon glyphicon-eye-open"></span></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12"><label>{{ 'texto_431' | translate }}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-md-9">
                        <input type="text" class="form-control" [formControl]="wizard1" [(ngModel)]="wizard1F"
                          value="{{wizard1F}}" readonly>
                      </div>
                      <div class="col-md-3">
                        <span class="btn btn-primary btn-file">
                          <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control"
                            id="wizard1" (change)="onFileChange($event,'wizard1')">
                        </span>
                        <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button"
                          (click)="openImg(wizard1F)" title="Ver imagen"><span
                            class="glyphicon glyphicon-eye-open"></span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12"><label>{{ 'texto_432' | translate }}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-md-9">
                        <input type="text" class="form-control" [formControl]="wizard2" [(ngModel)]="wizard2F"
                          value="{{wizard2F}}" readonly>
                      </div>
                      <div class="col-md-3">
                        <span class="btn btn-primary btn-file">
                          <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control"
                            id="wizard2" (change)="onFileChange($event,'wizard2')">
                        </span>
                        <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button"
                          (click)="openImg(wizard2F)" title="Ver Wizard"><span
                            class="glyphicon glyphicon-eye-open"></span></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12"><label>{{ 'texto_433' | translate }}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-md-9">
                        <input type="text" class="form-control" [(ngModel)]="wizard3F" [formControl]="wizard3"
                          value="{{wizard3F}}" readonly>
                      </div>
                      <div class="col-md-3">
                        <span class="btn btn-primary btn-file">
                          <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control"
                            id="wizard3" (change)="onFileChange($event,'wizard3')">
                        </span>
                        <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button"
                          (click)="openImg(wizard3F)" title="Ver imagen"><span
                            class="glyphicon glyphicon-eye-open"></span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12"><label>{{ 'texto_434' | translate }}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-md-9">
                        <input type="text" class="form-control" [formControl]="wizard4" [(ngModel)]="wizard4F"
                          value="{{wizard4F}}" readonly>
                      </div>
                      <div class="col-md-3">
                        <span class="btn btn-primary btn-file">
                          <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control"
                            id="wizard4" (change)="onFileChange($event,'wizard4')">
                        </span>
                        <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button"
                          (click)="openImg(wizard4F)" title="Ver Wizard"><span
                            class="glyphicon glyphicon-eye-open"></span></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12"><label>{{ 'texto_435' | translate }}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-md-9">
                        <input type="text" class="form-control" [(ngModel)]="wizard5F" [formControl]="wizard5"
                          value="{{wizard5F}}" readonly>
                      </div>
                      <div class="col-md-3">
                        <span class="btn btn-primary btn-file">
                          <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control"
                            id="wizard5" (change)="onFileChange($event,'wizard5')">
                        </span>
                        <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button"
                          (click)="openImg(wizard5F)" title="Ver imagen"><span
                            class="glyphicon glyphicon-eye-open"></span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12"><label>{{ 'texto_436' | translate }}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-md-9">
                        <input type="text" class="form-control" [(ngModel)]="wizard6F" [formControl]="wizard6"
                          value="{{wizard6F}}" readonly>
                      </div>
                      <div class="col-md-3">
                        <span class="btn btn-primary btn-file">
                          <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control"
                            id="wizard6" (change)="onFileChange($event,'wizard6')">
                        </span>
                        <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button"
                          (click)="openImg(wizard6F)" title="Ver Wizard"><span
                            class="glyphicon glyphicon-eye-open"></span></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12"><label>{{ 'texto_437' | translate }}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-md-9">
                        <input type="text" class="form-control" [(ngModel)]="wizard7F" [formControl]="wizard7"
                          value="{{wizard7F}}" readonly>
                      </div>
                      <div class="col-md-3">
                        <span class="btn btn-primary btn-file">
                          <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control"
                            id="wizard7" (change)="onFileChange($event,'wizard7')">
                        </span>
                        <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button"
                          (click)="openImg(wizard7F)" title="Ver imagen"><span
                            class="glyphicon glyphicon-eye-open"></span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12"><label>{{ 'texto_438' | translate }}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-md-9">
                        <input type="text" class="form-control" [(ngModel)]="logindenuncianteF"
                          [formControl]="logindenunciante" value="{{logindenuncianteF}}" readonly>
                      </div>
                      <div class="col-md-3">
                        <span class="btn btn-primary btn-file">
                          <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control"
                            id="logindenunciante" (change)="onFileChange($event,'logindenunciante')">
                        </span>
                        <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button"
                          (click)="openImg(logindenuncianteF)" title="Ver Wizard"><span
                            class="glyphicon glyphicon-eye-open"></span></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12"><label>{{ 'texto_439' | translate }}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-md-9">
                        <input type="text" class="form-control" [(ngModel)]="cabeceramailF" [formControl]="cabeceramail"
                          value="{{cabeceramailF}}" readonly>
                      </div>
                      <div class="col-md-3">
                        <span class="btn btn-primary btn-file">
                          <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control"
                            id="cabeceramail" (change)="onFileChange($event,'cabeceramail')">
                        </span>
                        <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button"
                          (click)="openImg(cabeceramailF)" title="Ver imagen"><span
                            class="glyphicon glyphicon-eye-open"></span></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--<button (click)="subirImagenes()" type = "submit"  class="btn btn-primary pull-right">{{ 'texto_384' | translate }}</button>-->
              <div class="clearfix"></div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-md-6">
        <div class="card">
          <div class="card-header card-header-primary">
            <h4 class="card-title">{{ 'texto_397' | translate }}</h4>
          </div>
          <div class="content">
            <form [formGroup]="validatingFormTextos" style="margin: 0 25px;">
              <div class="row" style="padding-top: 20px;">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_440' | translate }}</label>
                  </div>
                  <div class="form-group" style="display: flex; flex-direction: column;">
                    <mat-form-field>
                      <mat-label style="padding: 5px;" >{{ 'Buscar' | translate }}</mat-label>
                      <input matInput placeholder="Buscar" (keyup)="applyFilter($event.target.value); select.open()" />
                    </mat-form-field>
                    <mat-form-field>
                      <mat-label style="padding: 5px;">{{ 'texto_140' | translate }}</mat-label>
                      <mat-select #select [formControl]="id_texto" id="id_texto" [(ngModel)]="textos.id_texto" required
                        (selectionChange)="onChangeTexto($event)" panelClass="overflow-y-auto">
                        <mat-option *ngFor="let txt of filteredTextos" [value]="txt.id_texto">
                          {{txt.id_texto}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_441' | translate }}</label>
                    <textarea class="form-control" matInput [formControl]="texto_value" id="texto_value" [(ngModel)]="textoF" rows="5"></textarea>
                  </div>
                </div>
              </div>
              <button (click)="guardarTextos()" type="submit" class="btn btn-primary pull-right">{{ 'texto_384' | translate }}</button>
              <div class="clearfix"></div>
            </form>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-header card-header-primary">
            <h4 class="card-title">{{ 'texto_398' | translate }}</h4>
          </div>
          <div class="content">
            <form [formGroup]="validatingFormClausulas" style="margin-left: 25px; margin-right: 25px;">
              <div class="row" style="padding-top:20px">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_442' | translate }}</label>
                  </div>
                  <div class="form-group">
                    <mat-form-field>
                      <mat-label>{{ 'texto_140' | translate }}</mat-label>
                      <mat-select [formControl]="id_clausula" id="id_clausula" [(ngModel)]="clausulas.id_clausula"
                        required (selectionChange)="onChangeClausula($event)" (click)="onClickClausula($event)">
                        <mat-option matTooltip="{{txt.id_clausula}}" *ngFor="let txt of clausulas2"
                          [value]="txt.id_clausula">
                          {{txt.id_clausula}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="bmd-label-floating">{{ 'texto_443' | translate }}</label>
                    <textarea class="form-control" [formControl]="clausula_value" id="clausula_value"
                      [(ngModel)]="clausulaF" vcols="40" rows="5" value="{{clausulaF}}"></textarea>
                  </div>
                </div>
              </div>
              <button (click)="guardarClausulas()" type="submit" class="btn btn-primary pull-right">{{ 'texto_384' |
                translate }}</button>
              <div class="clearfix"></div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header card-header-primary">
            <h4 class="card-title">{{ 'texto_665' | translate }}</h4>
          </div>
          <div class="content">
            <table class="table" style=" width: 80%; margin-inline: auto;">
              <thead>
                <th>{{ 'texto_666' | translate }}</th>
                <th>{{ 'texto_667' | translate }}</th>
                <th>{{ 'texto_671' | translate }}</th>
                <!--<th>{{ 'comentario' | translate }}</th>-->
                <th>{{ 'texto_668' | translate }}</th>
                <th></th>
              </thead>
              <tbody>
                <tr *ngFor="let item of idiomas">
                  <td>{{ item.id_idioma }}</td>
                  <td>{{ item.n_idioma }}</td>
                  <td><img src="assets/img/idiomas/{{ item.bandera }}" style="width: 30px;"></td>
                  <!--<td>{{ item.comentario }}</td>-->
                  <td *ngIf="item.habilitado == '0'; else elseBlock"><i class="material-icons">close</i></td>
                  <ng-template #elseBlock>
                    <td><i class="material-icons">done</i></td>
                  </ng-template>
                  <td class="td-actions text-right">
                    <button (click)="editIdioma(item)" type="button" rel="tooltip" title="Edit Complaint" class=""
                      style="background: none;color:black;border:none;">
                      <i class="material-icons">edit</i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <br><br>
            <div [style.display]="IsHiddenForm ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1"
              role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
              <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document">
                <!--Content-->
                <div class="modal-content" style="z-index: 200;">
                  <div class="modal-body mx-3" style="padding-right: 1rem; padding-left: 1rem;">
                    <div class="card-header card-header-info">
                      <h5 style="margin-bottom: 0px;" class="card-title">{{ 'texto_669' | translate }}</h5>
                    </div>

                    <button type="button" style="z-index:100;float: right;margin-top: -2.8rem;" class="btn btn-dark"
                      data-dismiss="modal" (click)="hideModal()" aria-label="Cerrar modal">X</button>


                    <div class="md-form mb-3">
                      <input type="text" class="form-control" [formControl]="frmididioma" mdbInput mdbValidate
                        value={{id_idioma}} readonly>
                      <label for="orangeForm-email">{{ 'texto_666' | translate }}</label>
                    </div>

                    <div class="md-form mb-3">
                      <input type="text" class="form-control" [formControl]="frmnidioma" mdbInput mdbValidate
                        maxlength="255" value={{n_idioma}}>
                      <label for="orangeForm-name">{{ 'texto_667' | translate }}</label>
                      <mdb-error *ngIf="frmnidioma.invalid && (frmnidioma.dirty || frmnidioma.touched)">
                        {{ 'texto_61' | translate }}
                      </mdb-error>
                      <mdb-success *ngIf="frmnidioma.valid && (frmnidioma.dirty || frmnidioma.touched)">
                        {{ 'texto_62' | translate }}
                      </mdb-success>
                    </div>

                    <div class="md-form mb-3">
                      <div class="form-group">
                        <mat-checkbox [checked]="chkhabilitado" [(ngModel)]="Habilitadochk"
                          [formControl]="chkhabilitado" class="example-margin">{{ 'texto_668' | translate
                          }}</mat-checkbox>
                      </div>
                    </div>

                    <div class="md-form mb-3">
                      <div class="btn-file"><input type="file" class="form-control" id="icono_idioma"
                          (change)="onFileChangeII($event)"></div>
                      <div id="icono_idioma2"><img src="assets/img/idiomas/{{ bandera }}" style="width: 30px;"></div>
                    </div>

                    <div class="md-form mb-3">
                      <mdb-icon fas icon="pencil-alt" class="prefix grey-text"></mdb-icon>
                      <textarea maxlength="4000" placeholder="{{ 'texto_670' | translate }}" type="text" mdbInput
                        [formControl]="frmcomentario" class="md-textarea form-control" rows="5"
                        value={{comentario}}></textarea>
                    </div>

                  </div>
                  <div class="text-center mt-3">
                    <button (click)="updateIdioma(id_idioma,n_idioma,comentario,Habilitado)" mdbBtn rounded="true"
                      class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_59' | translate }}
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" [hidden]="!hasSSO">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-primary">
            <h4 class="card-title">{{ 'texto_535' | translate }}</h4>
          </div>
          <div class="content">
            <form [formGroup]="validatingFormSSO" style="margin-left: 25px; margin-right: 25px;">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12"><label>{{ 'texto_536' | translate }}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <mat-checkbox [checked]="saml_enabled" [(ngModel)]="saml_enabledF"
                            [formControl]="saml_enabled" (change)="syncData()" class="example-margin">{{ 'texto_537' |
                            translate }}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12"><label>{{ 'texto_538' | translate }}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="bmd-label-floating">{{ 'texto_539' | translate }}</label>
                          <input [formControl]="saml_idp_entity_id" type="TEXT" class="form-control"
                            [(ngModel)]="saml_idp_entity_idF" value="{{saml_idp_entity_idF}}">
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="bmd-label-floating">{{ 'texto_540' | translate }}</label>
                          <input [formControl]="saml_idp_sso_url" type="TEXT" class="form-control"
                            [(ngModel)]="saml_idp_sso_urlF" value="{{saml_idp_sso_urlF}}">
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="bmd-label-floating">{{ 'texto_541' | translate }}</label>
                          <input [formControl]="saml_idp_slo" type="TEXT" class="form-control"
                            [(ngModel)]="saml_idp_sloF" value="{{saml_idp_sloF}}">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="bmd-label-floating">{{ 'texto_542' | translate }}</label>
                          <textarea class="form-control" [formControl]="saml_idp_cert" id="saml_idp_cert"
                            [(ngModel)]="saml_idp_certF" vcols="40" rows="5" value="{{saml_idp_certF}}"></textarea>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="bmd-label-floating">{{ 'texto_543' | translate }}</label>
                          <textarea class="form-control" [formControl]="saml_idp_cert2" id="saml_idp_cert2"
                            [(ngModel)]="saml_idp_cert2F" vcols="40" rows="5" value="{{saml_idp_cert2F}}"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12"><label>{{ 'texto_544' | translate }}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="bmd-label-floating">{{ 'texto_545' | translate }}</label>
                          <input [formControl]="saml_mapping_cn" type="TEXT" class="form-control"
                            [(ngModel)]="saml_mapping_cnF" value="{{saml_mapping_cnF}}">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="bmd-label-floating">{{ 'texto_546' | translate }}</label>
                          <input [formControl]="saml_mapping_sn" type="TEXT" class="form-control"
                            [(ngModel)]="saml_mapping_snF" value="{{saml_mapping_snF}}">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="bmd-label-floating">{{ 'texto_547' | translate }}</label>
                          <input [formControl]="saml_mapping_email" type="TEXT" class="form-control"
                            [(ngModel)]="saml_mapping_emailF" value="{{saml_mapping_emailF}}">
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="bmd-label-floating">{{ 'texto_548' | translate }}</label>
                          <input [formControl]="saml_mapping_phone" type="TEXT" class="form-control"
                            [(ngModel)]="saml_mapping_phoneF" value="{{saml_mapping_phoneF}}">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="bmd-label-floating">{{ 'texto_549' | translate }}</label>
                          <input [formControl]="saml_mapping_national_id" type="TEXT" class="form-control"
                            [(ngModel)]="saml_mapping_national_idF" value="{{saml_mapping_national_idF}}">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12"><label>{{ 'texto_551' | translate }}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <mat-checkbox [checked]="saml_jit" [(ngModel)]="saml_jitF" [formControl]="saml_jit"
                            class="example-margin">{{ 'texto_552' | translate }}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <mat-checkbox [checked]="saml_slo" [(ngModel)]="saml_sloF" [formControl]="saml_slo"
                            class="example-margin">{{ 'texto_554' | translate }}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <mat-checkbox [checked]="saml_force_login" [(ngModel)]="saml_force_loginF"
                            [formControl]="saml_force_login" class="example-margin">{{ 'texto_555' | translate
                            }}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12"><label>{{ 'Sección Avanzada' | translate }}</label></div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <mat-checkbox [checked]="saml_debug" [(ngModel)]="saml_debugF" [formControl]="saml_debug"
                            class="example-margin">{{ 'Debug' | translate }}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="bmd-label-floating">{{ 'texto_556' | translate }}</label>
                          <textarea class="form-control" [formControl]="saml_sp_pk" id="saml_sp_pk"
                            [(ngModel)]="saml_sp_pkF" vcols="40" rows="5" value="{{saml_sp_pkF}}"></textarea>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="bmd-label-floating">{{ 'texto_557' | translate }}</label>
                          <input [formControl]="saml_sp_entity_id" type="TEXT" class="form-control"
                            [(ngModel)]="saml_sp_entity_idF" value="{{saml_sp_entity_idF}}">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="bmd-label-floating">{{ 'texto_558' | translate }}</label>
                          <textarea class="form-control" [formControl]="saml_sp_cert" id="saml_sp_cert"
                            [(ngModel)]="saml_sp_certF" vcols="40" rows="5" value="{{saml_sp_certF}}"></textarea>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="bmd-label-floating">{{ 'texto_559' | translate }}</label>
                          <select [formControl]="saml_sp_nameid_format" class="form-control"
                            [(ngModel)]="selectedsaml_sp_nameid_format" (change)="selectChangeHandler9($event)">
                            <option value="urn:oasis:names:tc:SAML:1.1:nameid-format:unspecified">{{ 'unspecified' |
                              translate }}</option>
                            <option value="urn:oasis:names:tc:SAML:1.1:nameid-format:emailAddress">{{ 'emailAddress' |
                              translate }}</option>
                            <option value="urn:oasis:names:tc:SAML:1.1:nameid-format:X509SubjectName">{{
                              'X509SubjectName' | translate }}</option>
                            <option value="urn:oasis:names:tc:SAML:2.0:nameid-format:entit">{{ 'entit' | translate }}
                            </option>
                            <option value="urn:oasis:names:tc:SAML:2.0:nameid-format:transient">{{ 'transient' |
                              translate }}</option>
                            <option value="urn:oasis:names:tc:SAML:2.0:nameid-format:persistent">{{ 'persistent' |
                              translate }}</option>
                            <option value="urn:oasis:names:tc:SAML:2.0:nameid-format:kerberos">{{ 'kerberos' | translate
                              }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button (click)="guardarSSO()" type="submit" class="btn btn-primary pull-right">{{ 'texto_384' | translate
                }}</button>
              <div class="clearfix"></div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
